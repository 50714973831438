import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom";

import HomeDeslogada from "../components/HomeDeslogada/HomeDeslogada";
import Login from "../components/Login/Login";
import Page404 from "../components/Page404/Page404";
import Cadastrar from "../components/CadastrarUsuario/Cadastrar";
import Faq from "../components/Faq/Faq";
import RecuperarSenha from "../components/RecuperarSenha/RecuperarSenha";
import GarantiaBravecto from "../components/GarantiaBravecto/GarantiaBravecto";
// import PromocaoMomentosBravecto from "../components/PromocaoMomentosBravecto/PromocaoMomentosBravecto";
import QuemSomos from "../components/QuemSomos/QuemSomos";
import ClubeDeVantagens from "../components/ClubeDeVantagens/ClubeDeVantagens";
import Beneficios from "../components/Beneficios/Beneficios";
import ScrollToTop from "../components/Utils/ScrollToTop";
import VerifyDevice from "../components/Utils/VerifyDevice";
import VerifyAuth from "../components/Utils/VerifyAuth";
import BeneficiosDetalhes from "../components/Beneficios/BeneficiosDetalhes/BeneficiosDetalhes";
import MeusPets from "../components/MeusPets/MeusPets";
import CadastrarPet from "../components/CadastrarPet/CadastrarPet";
import OndeComprar from "../components/OndeComprar/OndeComprar";
import EditarPet from "../components/EditarPet/EditarPet";
import CadastrarLembrete from "../components/CadastrarLembrete/CadastrarLembrete";
import MinhasCompras from "../components/MinhasCompras/MinhasCompras";
import DescontoEmBravecto from "../components/DescontoEmBravecto/DescontoEmBravecto";
import EditarMeuPerfil from "../components/EditarMeuPerfil/EditarMeuPerfil";
import Connect from "../store/config/connect";
import Dicas from "../components/Dicas/Dicas";
import DicasShow from "../components/Dicas/DicasShow/DicasShow";
import CadastrarNotaFiscal from "../components/CadastrarNotaFiscal/CadastrarNotaFiscal";
import MainRegulamento from "../components/MainRegulamento/MainRegulamento";
// import Vivara from "../components/CampanhaVivaraCadastrar/CadastrarVivara";
// import CampanhaVivaraLojas from "../components/CampanhaVivaraLojas/CampanhaVivaraLojas";
import PrimeiroAcessoInfluenciador from "../components/PrimeiroAcessoInfluenciador/PrimeiroAcessoInfluenciador";
import TermosUso from "../components/PrimeiroAcessoInfluenciador/TermosUso/TermosUso";
import NovaSenha from "../components/NovaSenha/NovaSenha";
// import BlackFriday2021 from "../components/BlackFriday2021/BlackFriday2021";
import Ivete from "../components/Ivete/Ivete";
import SurePetCare from "../components/SurePetCare/SurePetCare";

import "./router.css";
import RankingMomentosBravecto from "../components/PromocaoMomentosBravecto/RankingMomentosBravecto/RankingMomentosBravecto";
import BravectoExperience from "../components/BravectoExperience/bravectoExperience";

import MomentosBravecto10Anos from "../components/MomentosBravecto10Anos/MomentosBravecto10Anos";

const myRouter = (props) => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <VerifyAuth />
      <VerifyDevice />
      <Switch>
        <AnimationRoute />
      </Switch>
    </BrowserRouter>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const ls = window.localStorage;
      const lsu = ls && ls.getItem("bravecto-user");
      const user = lsu ? JSON.parse(lsu) : "";

      return user ? <Component {...props} /> : <Redirect to="/login" />;
    }}
  />
);

const DeslogadoRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const ls = window.localStorage;
      const lsu = ls && ls.getItem("bravecto-user");
      const user = lsu ? JSON.parse(lsu) : "";

      return user ? (
        <Redirect to="/clube-de-vantagens" />
      ) : (
        <Component {...props} />
      );
    }}
  />
);

function AnimationRoute() {
  let location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={500}>
        <Switch>
          <DeslogadoRoute path="/" exact component={HomeDeslogada} />
          <Route path="/login" exact component={Login} />
          <Route path="/cadastrar" exact component={Cadastrar} />
          <Route path="/duvidas" exact component={Faq} />
          <Route path="/esqueci-a-senha" exact component={RecuperarSenha} />
          <Route path="/nova-senha" exact component={NovaSenha} />
          <Route path="/quem-somos" exact component={QuemSomos} />
          <Route path="/ivete" exact component={Ivete} />
          <Route path="/surepetcare" exact component={SurePetCare} />
          {/* <Route path="/momentosbravecto" exact component={PromocaoMomentosBravecto} /> */}
          <Route path="/rankingmomentosbravecto10anos" exact component={RankingMomentosBravecto} />
          <Route path="/bravectoexperience" exact component={BravectoExperience} />
          <Route path="/momentosbravecto10anos" exact component={MomentosBravecto10Anos} />
          <PrivateRoute
            path="/clube-de-vantagens"
            exact
            component={ClubeDeVantagens}
          />
          <PrivateRoute
            path="/garantia-bravecto"
            exact
            component={GarantiaBravecto}
          />
          <PrivateRoute path="/meus-beneficios" exact component={Beneficios} />
          <PrivateRoute
            path="/beneficios/:partnerName/:star/:id"
            component={BeneficiosDetalhes}
          />
          <PrivateRoute path="/meus-pets" exact component={MeusPets} />
          <PrivateRoute path="/cadastrar-pet" exact component={CadastrarPet} />
          <Route path="/onde-comprar" exact component={OndeComprar} />
          <PrivateRoute path="/editar-pet/:id" exact component={EditarPet} />
          <PrivateRoute
            path="/cadastrar-lembrete/:petId"
            component={CadastrarLembrete}
          />
          <PrivateRoute
            path="/cadastrar-lembrete"
            component={CadastrarLembrete}
          />
          <PrivateRoute
            path="/cadastrar-nota"
            component={CadastrarNotaFiscal}
          />
          <PrivateRoute path="/minhas-compras" component={MinhasCompras} />
          <PrivateRoute
            path="/desconto-em-bravecto"
            component={DescontoEmBravecto}
          />
          <PrivateRoute path="/editar-meu-perfil" component={EditarMeuPerfil} />
          <Route path="/blog" exact component={Dicas} />
          <Route path="/blog/:slug" exact component={DicasShow} />
          {
            //<Route path='/dicas/:tipsId/:slug' component={DicasShow} />
          }
          <Route path="/regras/:type" component={MainRegulamento} />
          {
            //<Route path='/vivara' exact component={Vivara} />
            //<Route path='/vivara/lojas-participantes' exact component={CampanhaVivaraLojas} />
          }
          <Route
            path="/acesso-influenciador"
            exact
            component={PrimeiroAcessoInfluenciador}
          />
          <Route
            path="/acesso-influenciador/termos-de-uso"
            exact
            component={TermosUso}
          />
          <Route path="/404" component={Page404} />
          {/* <Route path="/blackfriday" exact component={BlackFriday2021} /> */}
          <Route path="*" component={Page404} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

const mapStateToProps = ({ auth }, props) => {
  return {
    ...auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(myRouter);
