import React from "react";

import "./Loader.css";

const LoaderForButton = (props) => {
  return (
    <div id="loader-ring">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoaderForButton;
