import React, { useState, useEffect } from "react";
import Connect from "../../store/config/connect";
import SelectCustom from "../Utils/Select/Select";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";
import MobileProducts from "./MobileProducts/MobileProducts";
import DesktopProducts from "./DesktopProducts/DesktopProducts";
import { getStars, postProductsFiltered } from "../../services/services";
import { disableScroll, enableScroll } from "../../utils/helpers/general";
import "./Beneficios.css";

const Beneficios = (props) => {
  document.title = "Clube Bravecto | Produtos";

  const [isChild] = useState(props.isChild);
  const [loadMoreProducts, setLoadMoreProducts] = useState(false);
  const [maxProducts, setMaxProducts] = useState(false);
  const [numberPage, setNumberPage] = useState(null);
  const [filterChanged, setFilterChanged] = useState(false);
  const [showNewItems, setShowNewItems] = useState(false);

  const [isMobile, setIsMobile] = useState(props.helper.isMobile);
  useEffect(() => {
    setIsMobile(props.helper.isMobile);
  }, [props.helper.isMobile]);

  const [products, setProducts] = useState([]);
  const [oldProducts, setOldProducts] = useState([]);

  const [rateSelected, setRateSelected] = useState(null);
  const [rateSelect, setRateSelect] = useState(null);

  const [whoSelected, setWhoSelected] = useState(null);
  const [whoSelect, setWhoSelect] = useState(null);

  const [categorySelected, setCategorySelected] = useState(null);
  const [categorySelect, setCategorySelect] = useState(null);

  function handleAllBenefits() {
    setRateSelected(null);
    setWhoSelected(null);
    setCategorySelected(null);
  }

  useEffect(() => {
    console.log(`Meus Beneficios`, props)
    getStars(props.user.token)
      .then((res) => {
        console.log('Resposta da API Stars', res);
        if (res && res.status === 200) {
          const resp = JSON.parse(res.request.response);
          setRateSelected({ label: resp.data.scoreNumber, value: resp.data.scoreNumber })
        }
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, [])

  useEffect(() => {
    setWhoSelect({
      placeholder: "Selecione para quem",
      defaultValue: {
        value: null,
        label: "Para você e para seu pet",
      },
      options: [
        {
          value: 1,
          label: "Para seu pet",
        },
        {
          value: 2,
          label: "Para você",
        },
        {
          value: null,
          label: "Para você e para seu pet",
        },
      ],
      onChange: function (value) {
        return setWhoSelected(value);
      },
    });

    setCategorySelect({
      placeholder: "Selecione categoria",
      options: [
        {
          value: "Entretenimento",
          label: "Entretenimento",
        },
        {
          value: "Moda e Beleza",
          label: "Moda e Beleza",
        },
        {
          value: "Comer e Beber",
          label: "Comer e Beber",
        },
        {
          value: "Eletro Eletrônico",
          label: "Eletro Eletrônico",
        },
        {
          value: "Decoração",
          label: "Decoração",
        },
        {
          value: "Presentes",
          label: "Presentes",
        },
        {
          value: "Viagens e Experiências",
          label: "Viagens e Experiências",
        },
        // {
        //   value: null,
        //   label: "Todos",
        // },
      ],
      onChange: function (value) {
        return setCategorySelected(value);
      },
    });

    // setRateSelected({
    //   value: props.user.score,
    //   label: props.user.score,
    // });
    setWhoSelected({
      value: null,
      label: "Para você e para seu pet",
    });
  }, [props.user]);

  useEffect(() => {
    let optionsScore = [
      // {
      //   value: null,
      //   label: "Todas",
      // },
      {
        value: 1,
        label: "1",
      },
      {
        value: 2,
        label: "2",
      },
      {
        value: 3,
        label: "3",
      },
      {
        value: 4,
        label: "4",
      },
      {
        value: 5,
        label: "5",
      },
    ];

    console.log('Estrela Meus Beneficios', rateSelected)
    console.log('Estrela Meus Beneficios2', rateSelect)
    setRateSelect({
      placeholder: "Selecione o número de estrelas",
      options: optionsScore,
      value: rateSelected,
      onChange: function (value) {
        console.log('OnChangeSelect', value)
        return setRateSelected(value);
      },
    });
  }, [props.user, rateSelected]);

  useEffect(() => {
    function handleInfiniteScroll() {
      const resize = isChild ? 0 : 1200;
      if (
        window.pageYOffset <
        document.getElementById("Beneficios").offsetHeight - resize
      )
        return;
      setLoadMoreProducts(true);
    }

    handleInfiniteScroll();

    window.addEventListener("scroll", handleInfiniteScroll);
    return () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
    };
  }, [isChild]);

  useEffect(() => {
    setFilterChanged(false);
    setNumberPage((n) => {
      return n + 1;
    });
  }, [loadMoreProducts]);

  /* eslint-disable */
  useEffect(() => {
    setFilterChanged(true);
    setNumberPage("change");
  }, [rateSelected, whoSelected, categorySelected]);

  useEffect(() => {
    function getProducts() {
      let params = {
        rate: rateSelected
          ? rateSelected.value
            ? rateSelected.value
            : props.user.score
          : props.user.score,
        ProductTypeId: whoSelected ? whoSelected.value : null,
        category:
          whoSelected && whoSelected.value === 1
            ? null
            : categorySelected
              ? categorySelected.value
              : null,
        page: numberPage,
        CustomerId: props.user.id,
      };
      postProductsFiltered(params)
        .then((res) => {
          if (res && res.status === 200) {
            let newProducts = [];
            if (products.length > 0 && !filterChanged) {
              newProducts = products;
            } else {
              setProducts([]);
            }
            //seto os produtos anteriores no oldProduts para a div não ficar diminuindo e aumentando
            //gerando loucura no scroll
            setOldProducts(newProducts);
            const productsResponse = JSON.parse(res.request.response);
            if (productsResponse.length > 0) {
              productsResponse.map((i) => {
                if (numberPage === 1) {
                  setOldProducts((oldProducts) => [...oldProducts, i]);
                }
                return setProducts((products) => [...products, i]);
              });
              setTimeout(() => {
                enableScroll();
              }, 1000);
              if (loadMoreProducts) {
                setLoadMoreProducts(false);
              }
              setShowNewItems(true);
            } else {
              setMaxProducts(true);
              setTimeout(() => {
                enableScroll();
              }, 1000);
              setShowNewItems(true);
            }
          }
        })
        .catch((e) => {
          console.log(e.response);
        });
    }

    if (numberPage === "change" || !numberPage) {
      setNumberPage(1);
      return;
    } else {
      disableScroll();
      setShowNewItems(false);
      getProducts();
    }
  }, [numberPage]);
  /* eslint-enable */

  return (
    <div id="Beneficios">
      {!isChild && <Header />}
      <div id="Beneficios__container">
        <div id="Beneficios__title">
          <span>Meus Benefícios</span>
        </div>
        {props.user.score !== 5 &&
          <div id="verTodos">
            <span onClick={handleAllBenefits}>Ver todos »</span>
          </div>
        }
        <div id="selects">
          {props.user.score !== 5 &&
            <div id="row">
              <div id="row__container">
                <span id="name__field">Quantidade de Estrelas</span>
                {rateSelect ? (
                  <SelectCustom {...rateSelect} />
                ) : (
                  <div id="preLoader">
                    {" "}
                    <Loader />{" "}
                  </div>
                )}
              </div>
            </div>
          }
          <div id="row">
            <div id="row__container">
              <span id="name__field">Para quem?</span>
              {whoSelect ? (
                <SelectCustom {...whoSelect} />
              ) : (
                <div id="preLoader">
                  {" "}
                  <Loader />{" "}
                </div>
              )}
            </div>
          </div>
          <div
            id="row"
            style={{
              display: whoSelected
                ? whoSelected.value !== 1
                  ? "flex"
                  : "none"
                : "none",
            }}
          >
            <div id="row__container">
              <span id="name__field">Selecione uma categoria?</span>
              {categorySelect ? (
                <SelectCustom {...categorySelect} />
              ) : (
                <div id="preLoader">
                  {" "}
                  <Loader />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobile && (
          <React.Fragment>
            <div id="Beneficios__title" className="less-margin">
              <div id="main-title">
                <span>Aproveite</span>
              </div>
            </div>
            <div id="resultado">
              <span id="resultado__desc">Resultado da sua busca:</span>
              <span id="resultado__count">{products.length} resultados</span>
            </div>
          </React.Fragment>
        )}
        {isMobile ? (
          showNewItems ? (
            <MobileProducts products={products} />
          ) : (
            <MobileProducts products={oldProducts} />
          )
        ) : showNewItems ? (
          <DesktopProducts products={products} />
        ) : (
          <DesktopProducts products={oldProducts} />
        )}
        {loadMoreProducts && !maxProducts && products.length > 0 && (
          <div id="preLoader">
            <Loader />
          </div>
        )}
      </div>
      {!isChild && <Footer />}
    </div>
  );
};

const mapStateToProps = ({ helper, auth }, props) => {
  return {
    ...props,
    ...auth,
    helper,
  };
};

export default Connect(mapStateToProps)(Beneficios);
