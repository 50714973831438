import React from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./QuemSomos.css";

const QuemSomos = (props) => {
  document.title = "Clube Bravecto | Quem Somos";

  return (
    <div id="QuemSomos">
      <Header />
      <div id="QuemSomos__container">
        <div id="QuemSomos__main-title">
          <span>Quem somos</span>
        </div>
        <div id="logo-msd">
          <span></span>
        </div>
        <div id="title">Quanto mais acumular, mais benefício</div>
        <div id="desc-content">
          <span>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </span>
        </div>
        <div id="QuemSomos__main-title">
          <span>Produtos</span>
        </div>
        <div id="product">
          <div id="product__container">
            <div id="product__logo">
              <span className="bravecto"></span>
            </div>
            <div id="product__desc">
              <span>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting
              </span>
            </div>
            <div id="product__btn">
              <a
                href="https://www.bravecto.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                title="Saiba mais"
              >
                <button type="button" id="saibaMais">
                  Saiba mais
                </button>
              </a>
            </div>
          </div>
          <div id="product__container">
            <div id="product__logo">
              <span className="scalibor"></span>
            </div>
            <div id="product__desc">
              <span>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting
              </span>
            </div>
            <div id="product__btn">
              <a
                href="http://www.scalibor.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                title="Saiba mais"
              >
                <button type="button" id="saibaMais">
                  Saiba mais
                </button>
              </a>
            </div>
          </div>

          <div id="product__container">
            <div id="product__logo">
              <span className="nobivac"></span>
            </div>
            <div id="product__desc">
              <span>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting
              </span>
            </div>
            <div id="product__btn">
              <a
                href="http://www.nobivac.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                title="Saiba mais"
              >
                <button type="button" id="saibaMais">
                  Saiba mais
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default QuemSomos;
