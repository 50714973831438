import React, { useEffect, useState } from "react";
import Select from "react-select";

import "./Select.css";

const SelectCustom = (props) => {
  const [inputProps, setInputProps] = useState(props);

  useEffect(() => {
    setInputProps(props);
  }, [props]);

  return (
    <div id="container__select">
      <Select {...inputProps} />
    </div>
  );
};

export default SelectCustom;
