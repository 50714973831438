import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

import Termos from "./Termos/Termos";
import PoliticaPrivacidade from "./PoliticaPrivacidade/PoliticaPrivacidade";
import Regulamento from "./Regulamento/Regulamento";
import Contato from "./Contato/Contato";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./MainRegulamento.css";

const MainRegulamento = (props) => {
  const [navRoute, setNavRoute] = useState("termos");

  useEffect(() => {
    if (props.match && props.match.params) {
      setNavRoute(props.match.params.type);
    }
  }, [props]);

  return (
    <div id="MainRegulamento">
      <div id="skip">
        <a href="#MainRegulamento__title">
          Pular para o conteúdo principal da página
        </a>
      </div>
      <Header />
      <div id="MainRegulamento__container">
        <div id="MainRegulamento__nav">
          {/*
                        <div className={navRoute === 'termos' ? 'activeRoute' : ''}>
                            <Link to="/regras/termos">TERMOS</Link>
                        </div>
                        <div className={navRoute === 'regulamento' ? 'activeRoute' : ''}>
                            <Link to="/regras/regulamento">REGULAMENTO</Link>
                        </div>
                        <div className={navRoute === 'politica-de-privacidade' ? 'activeRoute' : ''}>
                            <Link to="/regras/politica-de-privacidade">POLÍTICA DE PRIVACIDADE</Link>
                        </div>
                        <div className={navRoute === 'contato' ? 'activeRoute' : ''}>
                            <Link to="/regras/contato">CONTATO</Link>
                        </div>
                    */}
        </div>
        <h1 id="MainRegulamento__title">
          <span>
            {navRoute === "termos" && "Termos de uso do site"}
            {navRoute === "politica-de-privacidade" &&
              "Política de privacidade do site"}
            {navRoute === "regulamento" &&
              "Regulamento do Programa de Relacionamento do Clube de Vantagens Bravecto"}
            {navRoute === "contato" && "Contato"}
          </span>
        </h1>

        <div id="MainRegulamento__info">
          {navRoute === "termos" && <Termos />}
          {navRoute === "politica-de-privacidade" && <PoliticaPrivacidade />}
          {navRoute === "regulamento" && <Regulamento />}
          {navRoute === "contato" && <Contato />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainRegulamento;
