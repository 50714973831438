/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from "react";
import { MdCheck, MdArrowDropUp } from "react-icons/md";
import { Link } from "react-router-dom";
// import { Helmet } from "react-helmet";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import MetaTags from "../MetaTags/MetaTags";

import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";
import SelectCustom from "../Utils/Select/Select";

// import Loader from "../Loader/Loader";

import Connect from "../../store/config/connect";
import { LogInUser } from "../../store/auth";

import { emailSuggestions } from "../../utils/helpers/general";

import {
  // getStates,
  getCitiesByState,
  postRegisterUser,
  postLoginUser,
  getDocumentExists,
  getEmailExists,
  postRegisterPet,
  postRegisterUserComplete,
  postRegisterUserV2,
} from "../../services/services";

import "./Cadastrar.css";
import FormCadastrarPet from "../CadastrarPet/FormCadastrarPet";
import Modal from "../Modal/Modal";

const Cadastrar = (props) => {
  // const [selectedEstado, setSelectedEstado] = useState("");
  // const [selectEstado, setSelectEstado] = useState({});
  // const [statesLoaded, setStatesLoaded] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  // const [firstEntry, setFirstEntry] = useState(true);
  const [tooltipPassword, setTooltipPassword] = useState(false);
  const [modalCloseForm, setModalCloseForm] = useState(false);
  const [modalContent] = useState(
    `<span>Por favor, corrija ou complete <br /> os campos destacados.</span>`
  );
  const [title] = useState(
    `Preenchimento incorreto`
  );


  const [modalCloseSenha, setModalCloseSenha] = useState(false);
  const [titleModalSenha] = useState(
    `Senha`
  );
  const [modalContentSenha] = useState(
    `<div>
      <span><b>Sua senha deve ter 8 dígitos com pelo menos:</b></span>
      <p>1 letra maiúscula</p>
      <p>1 número</p>
      <p>1 caracter especial</p>
    </div>`
  );

  const [formStage, setFormStage] = useState(1);

  const [petsList, setPetsList] = useState([{}]);
  const [petsIndices, setPetsIndices] = useState([0]);

  function addNewPet() {
    dataLayer.push({
      event: "cadastro_click_adicionar_pet"
    });

    setPetsList([
      ...petsList,
      null,
    ])
    setPetsIndices([
      ...petsIndices,
      petsIndices.length,
    ])
  }

  function backStep1() {
    dataLayer.push({
      event: "cadastro_click_voltar_etapa_1"
    });
    setFormStage(1)
  }

  function backStep2() {
    dataLayer.push({
      event: "cadastro_click_voltar_etapa_2"
    });
    setFormStage(2)
  }

  function backStep3() {
    dataLayer.push({
      event: "cadastro_click_voltar_etapa_3"
    });
    setFormStage(3)
  }

  const updatePetIndex = useCallback(function (index, pet) {
    const newList = [...petsList]
    newList[index] = pet
    setPetsList(newList)
    // console.log(`CadastrarPet: newList updated`, newList)
  }, [petsList])

  const removePetIndex = useCallback(function (index) {
    const newList = [...petsList]
    newList[index] = undefined
    setPetsList(newList)
    const newIndices = [...petsIndices]
    newIndices[index] = undefined
    setPetsIndices(newIndices)
    console.log(`CadastrarPet: removePet newList updated`, newList, newIndices)
  }, [petsList, petsIndices])

  useEffect(() => {
    console.log(`CadastrarPet: petsList updated effect`, petsList)
  }, [petsList])


  /*
    useEffect(() => {
        getStates().then(res => {
            if (res.status === 200) {
                let statesOptions = [];
                const states = JSON.parse(res.request.response);
                states.map((i) => {
                    return statesOptions.push({
                        "label": i.name,
                        "value": i.name,
                        "uf": i.uf
                    });
                });
                setSelectEstado({
                    placeholder: "Selecione seu estado",
                    options: statesOptions,
                    isSearchable: false,
                    noOptionsMessage: function () {
                        return 'Procure novamente!';
                    },
                    onChange: function (value) {
                        setSelectedEstado(value);
                    },
                    onFocus: function () {
                        pushDataLayer('focus', 'email');
                    }
                });
                setStatesLoaded(true);
            }
        });
        return () => {
            setFirstEntry(false);
        }
    }, []);
    */

  function pushDataLayer(status, field) {
    window.dataLayer.push({
      gaEventCategory: "formulario-cadastro",
      gaEventAction: field,
      gaEventLabel: status,
      event: "gaSendEvent",
    });
  }


  const [invalidName, setInvalidName] = useState(false);
  const [inputNameValue, setInputNameValue] = useState("");
  const [inputName] = useState({
    type: "text",
    name: "name",
    value: inputNameValue,
    replaceValueOnChange: true,
    minLength: 3,
    onChange: function (value) {
      setInputNameValue(value);
      return value;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_nome_completo"
      });

      e.target.value.length < 3 ? setInvalidName(true) : setInvalidName(false);
    },
    onFocus: function () {
      pushDataLayer("focus", "name");
    },
  });

  function handleEmailSugg(e) {
    const sugg = e.target.value;
    let currEmail = inputEmailValue;
    currEmail = currEmail.split("@")[0] + sugg;
    setInputEmailValue(currEmail);
    setEmailListFiltered([]);
    setEmailMask(true);
  }

  const [emailExists, setEmailExists] = useState(false);
  const [emailMask, setEmailMask] = useState(true);
  const [emailList] = useState(emailSuggestions());
  const [emailListFiltered, setEmailListFiltered] = useState([]);
  const [inputEmailValue, setInputEmailValue] = useState("");
  const [inputEmail, setInputEmail] = useState();
  useEffect(() => {
    let emailProps = {
      type: "text",
      name: "email",
      onChange: function (email) {
        // eslint-disable-next-line
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(email).toLowerCase())
          ? setEmailMask(true)
          : setEmailMask(false);
        if (email.includes("@")) {
          let filtered = emailList.filter((i) => {
            return i.includes(email.split("@")[1]);
          });
          setEmailListFiltered(filtered);
        }
        setInputEmailValue(email);
        return email;
      },
      onBlur: function (e) {
        dataLayer.push({
          event: "cadastro_preencheu_email"
        });
        getEmailExists(e.target.value).then((res) => {
          if (res && res.status === 200) {
            setEmailExists(res.data);
            if (res.data) {
              setInputEmailValue(null);
            }
          } else {
            setEmailExists(false);
          }
        });
      },
      onFocus: function () {
        pushDataLayer("focus", "email");
      },
    };

    // if (firstEntry) {
    emailProps.value = inputEmailValue;
    // } else {
    //   emailProps.replaceValueOnChange = true;
    // }
    setInputEmail(emailProps);
  }, [inputEmailValue, emailList]);

  const [cpfExists, setCpfExists] = useState(false);
  const [inputCPFValue, setInputCPFValue] = useState("");
  const [inputCPF] = useState({
    type: "text",
    name: "document",
    placeholder: "xxx.xxx.xxx.xx",
    value: inputCPFValue,
    replaceValueOnChange: true,
    maxLength: 14,
    onChange: function (cpf) {
      let value = cpf
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      setInputCPFValue(value);
      return value;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_cpf"
      });
      getDocumentExists(e.target.value)
        .then((res) => {
          if (res && res.status === 200) {
            setCpfExists(res.data);
            if (res.data) {
              setInputCPFValue(null);
            }
          } else {
            setCpfExists(false);
          }
        })
        .catch((e) => {
          setCpfExists("invalid");
          setInputCPFValue(null);
        });
    },
    onFocus: function () {
      pushDataLayer("focus", "documentNumber");
    },
  });

  // const [yearBirthValid, setYearBirthValid] = useState(true);
  // const [inputYearBirthValue, setInputYearBirthValue] = useState("");
  // const [inputYearBirth] = useState({
  //   type: "number",
  //   name: "yearBirth",
  //   value: inputYearBirthValue,
  //   replaceValueOnChange: true,
  //   maxLength: 4,
  //   placeholder: "Use 4 dígitos. Ex: 1985",
  //   onChange: function (year) {
  //     let value = year.replace(/\D/g, "").replace(/(\d{4})(\d)/, "$1-$2");
  //     setInputYearBirthValue(value);
  //     return value;
  //   },
  //   onBlur: function (e) {
  //     let value = e.target.value;
  //     let diff = parseInt(new Date().getFullYear()) - value;
  //     let verification =
  //       (diff < 18 || diff > 100) && parseInt(value).length !== 4
  //         ? false
  //         : true;
  //     setYearBirthValid(verification);
  //   },
  //   onFocus: function () {
  //     pushDataLayer("focus", "yearBirth");
  //   },
  // });

  // const [inputGenderValue, setInputGenderValue] = useState("");

  // const [inputBirthdayValue, setInputBirthdayValue] = useState("");
  // const [inputBirthday] = useState({
  //     type: "text",
  //     name: "birthday",
  //     maxLength: 10,
  //     replaceValueOnChange: true,
  //     placeholder: "DD/MM/AAAA",
  //     onChange: function(birthday){
  //         birthday = birthday.length > 0 && birthday.slice(0,2) > 31 ? 31 : birthday;
  //         birthday = birthday.length > 3 && birthday.slice(3,5) > 12 ? birthday.slice(0,3)+12 : birthday;
  //         let v = birthday.toString().replace(/\D/g,"")
  //                     .replace(/(\d{2})(\d)/,"$1/$2")
  //                     .replace(/(\d{2})(\d)/,"$1/$2");
  //         setInputBirthdayValue(v);
  //         return v;
  //     }
  // });

  const [selectedCidade, setSelectedCidade] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(true);
  const [selectCidade, setSelectCidade] = useState({
    options: [],
    placeholder: "",
    isSearchable: true,
    noOptionsMessage: function () {
      return "Para habilitar as opções de Cidade, selecione um Estado!";
    },
    onFocus: function () {
      pushDataLayer("focus", "city");
    },
  });

  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [inputPhonevalue, setInputPhonevalue] = useState("");
  const [inputPhone] = useState({
    type: "text",
    name: "phone",
    maxLength: 15,
    replaceValueOnChange: true,
    value: inputPhonevalue,
    placeholder: "(11) xxxxx-xxxx",
    onChange: function (phone) {
      if (phone) {
        let value = phone
          .replace(/\D/g, "")
          .replace(/^(\d\d)(\d)/g, "($1) $2")
          .replace(/(\d{5})(\d)/, "$1-$2");
        if (value.length > 14) {
          setPhoneMask(true);
          setInputPhonevalue(value);
        } else {
          setPhoneMask(false);
        }
        return value;
      }
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_telefone"
      });
      let value = e.target.value;
      var verification;
      verification = !value.length ? false : true;
      setPhoneIsValid(verification);
    },
    onFocus: function () {
      pushDataLayer("focus", "phone");
    },
  });

  function phoneIsEmpty(e) {
    var phone = e.phoneNumber;
    return !phone.length ? false : true;
  }

  const [inputPetTypeValue, setInputPetTypeValue] = useState("");

  const [passwordMask, setPasswordMask] = useState(false);
  const [inputPasswordValue, setInputPasswordValue] = useState("");
  const [inputPassword] = useState({
    type: "password",
    name: "password",
    replaceValueOnChange: true,
    value: inputPasswordValue,
    onChange: function (pass) {
      const testAllMasks =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,}$/g;
      const testNumber = /(\d)/g;
      const testLowerCase = /([a-z])/g;
      const testUpperCase = /([A-Z])/g;
      const testSymbols = /([!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/g;

      const tests = {
        uppercase: testUpperCase.test(pass.toString()) ? true : false,
        lowercase: testLowerCase.test(pass.toString()) ? true : false,
        numbers: testNumber.test(pass.toString()) ? true : false,
        symbols: testSymbols.test(pass.toString()) ? true : false,
        length: pass.length >= 8 ? true : 8 - pass.length,
      };

      setPasswordMask(tests);

      const isTestPassing = testAllMasks.test(pass.toString());

      if (isTestPassing) {
        setPasswordMask(false);
      }

      setInputPasswordValue(pass);
      return pass;
    },
    onBlur: function () {
      dataLayer.push({
        event: "cadastro_preencheu_senha"
      });

      setTooltipPassword(false);
    },
    onFocus: function () {
      setTooltipPassword(true);
      pushDataLayer("focus", "password");
    },
  });

  const [inputConfirmPasswordValue, setInputConfirmPasswordValue] =
    useState("");
  const [inputConfirmPassword] = useState({
    type: "password",
    name: "confirmPassword",
    value: inputConfirmPasswordValue,
    replaceValueOnChange: true,
    onChange: function (confirmPass) {
      setInputConfirmPasswordValue(confirmPass);
      return confirmPass;
    },
    onBlur: function () {
      dataLayer.push({
        event: "cadastro_preencheu_confirmar_senha"
      });
    },
    onFocus: function () {
      pushDataLayer("focus", "confirmPassword");
    },
  });

  const [inputConfirmedPassword, setInputConfirmedPassword] = useState(true);
  useEffect(() => {
    setInputConfirmedPassword(inputPasswordValue === inputConfirmPasswordValue)
  }, [inputPasswordValue, inputConfirmPasswordValue])

  // const [inputPromotionalCodeValue, setInputPromotionalCodeValue] = useState("");
  // const [inputPromotionalCode] = useState({
  //     type: "text",
  //     name: "promotionalCode",
  // });

  const [inputIsBravectoValue, setInputIsBravectoValue] = useState("");

  const [selectedQuantoTempo, setSelectedQuantoTempo] = useState("");
  const [selectQuantoTempo] = useState({
    placeholder: "Selecione uma opção",
    isSearchable: false,
    onChange: function (value) {
      setSelectedQuantoTempo(value);
    },
    options: [
      {
        label: "1 semana",
        value: "-7",
      },
      {
        label: "15 dias",
        value: "-15",
      },
      {
        label: "1 mês",
        value: "-30",
      },
      {
        label: "1 mês e meio",
        value: "-45",
      },
      {
        label: "2 meses",
        value: "-60",
      },
      {
        label: "mais de 2 meses",
        value: "-70",
      },
    ],
    onFocus: function () {
      if ("function" === typeof pushDataLayer) {
        pushDataLayer("focus", "timeQuatity");
      }
    },
  });

  const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(null);
  const [acceptNews, setAcceptNews] = useState(false);

  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [hasRequestErrors, setHasRequestErrors] = useState(false);

  useEffect(() => {
    console.log(hasFormErrors, emailMask);
  }, [hasFormErrors, emailMask]);

  const [phoneMask, setPhoneMask] = useState(null);

  function capitalizeFirstLetter(str) {
    return str.replace(/(^\w|\s\w)(\S*)/g, (fullWord, firstLetter, rest) =>
      fullWord.length > 2
        ? `${firstLetter.toUpperCase()}${rest.toLowerCase()}`
        : fullWord.toLowerCase()
    );
  }

  function goToStep4() {
    const petsValid = formValidationPets();
    if (petsValid) {
      dataLayer.push({
        event: "cadastro_click_salvar_e_avancar"
      });

      setFormStage(4);
    }
  }


  function formValidationPets() {
    const petsValid = []
    let errorFound
    for (const pet of petsList) {
      if (!pet) continue;
      const petValid = pet.formValidation()
      if (petValid) {
        petsValid.push(petValid)
      } else {
        errorFound = pet
        break
      }
    }
    console.log(`CadastrarPet: formValidation`, errorFound, petsValid)
    return null == errorFound
      ? petsValid
      : false
  }

  function formValidation(step) {
    let formErrors = {};

    // console.log("aaa", inputIsBravectoValue);
    const fieldsPets = step >= 3 ? formValidationPets() : true;
    const fields = {
      name: inputNameValue ? capitalizeFirstLetter(inputNameValue) : false,
      email: emailMask ? inputEmailValue : false,
      document: inputCPFValue ? inputCPFValue : false,
      state: step < 2 ? true : selectedEstado ? selectedEstado : false,
      city: step < 2 ? true : selectedCidade ? selectedCidade.value : false,
      cep: step < 2 ? true : inputCepValue ? inputCepValue : false,
      street: step < 2 ? true : inputEnderecoValue ? inputEnderecoValue : false,
      number: step < 2 ? true : inputNumeroValue ? inputNumeroValue : false, 
      // yearBirth: "", //inputYearBirthValue ? inputYearBirthValue : "",
      // gender: "N/A", //inputGenderValue,
      // state: "", //selectedEstado ? selectedEstado.value : "",
      // city: "", //selectedCidade ? selectedCidade.value : "",
      phoneNumber: phoneMask ? inputPhonevalue : false,
      // petType: inputPetTypeValue ? inputPetTypeValue : 0,
      // isBravecto: inputIsBravectoValue ? inputIsBravectoValue : false,
      // DaysFromLatestBravectoHandling:
      //   inputIsBravectoValue === 1 ? selectedQuantoTempo.value : true,
      password: !passwordMask
        ? inputPasswordValue === inputConfirmPasswordValue
          ? inputPasswordValue
          : false
        : false,
      confirmPassword: !passwordMask
        ? inputConfirmPasswordValue === inputPasswordValue
          ? inputConfirmPasswordValue
          : false
        : false,
      termOfUse: acceptTermsOfUse,
      // isEmail: acceptNews,
      pets: fieldsPets,
    };

    Object.keys(fields).forEach((i) => {
      if (
        !fields[i] &&
        (step === 4 ||
          (i !== "password" &&
            i !== "confirmPassword" &&
            i !== "termOfUse")
        )
        // i !== 'petType'
        // i !== 'DaysFromLatestBravectoHandling'
      ) {
        formErrors[i] = true;
      }
    });

    if (!phoneIsEmpty(fields)) {
      setPhoneIsValid(false);
    }

    if (formErrors.termOfUse) {
      setAcceptTermsOfUse(false);
    }

    if (Object.keys(formErrors).length > 0) {
      setHasFormErrors(formErrors);
      setModalCloseForm(true);
      setTooltipPassword(!passwordMask);
      window.scrollTo(0, 0);
      setSubmitting(false);
      return false;
    } else {
      fields.isBravecto =
        fields.isBravecto === "0" || !fields.isBravecto ? false : true;

      fields.DaysFromLatestBravectoHandling =
        fields.isBravecto === true ? selectedQuantoTempo.value : null;

      fields.role = 1;

      return fields;
    }
  }

  const [userId, setUserId] = useState(null);

  function handleSubmit(e) {
    e.preventDefault();
    setHasFormErrors(false);
    setHasRequestErrors(false);
    setSubmitting(true)
    // pushDataLayer("submit", "button");

    if (formStage === 1) {
      const userFields = formValidation(1);
      if (userFields !== false) {
        postRegisterUserV2(userFields)
        .then((res) => {
          if (res && res.status === 200) {
            dataLayer.push({
              event: "cadastro_click_avancar_etapa_2"
            });
            setFormStage(2);
            setSubmitting(false);
            setUserId(res.data.data.id);
          }
        });
      }
    } else if (formStage === 2) {
      const addressFields = formValidation(2);
        if (addressFields !== false) {
          dataLayer.push({
            event: "cadastro_click_avancar_etapa_3"
          });
          setFormStage(3);
          setSubmitting(false);
        return
      }
    }
    // console.log('userFields', userFields)
  }

  function handleSubmit3(e) {
    dataLayer.push({
      event: "cadastro_click_finalizar_cadastro"
    });
    e.preventDefault();
    setHasFormErrors(false);
    setSubmitting(true);
    setHasRequestErrors(false);

    const fields = formValidation(3);

    function formatFields(fields) {
      return {
        ...fields,
        address: {
          State: fields.state,
          City: fields.city,
          Cep: fields.cep,
          Street: fields.street,
          Number: fields.number,
          complement: inputComplementoValue || null,
        },
        state: undefined,
        city: undefined,
        cep: undefined,
        street: undefined,
        number: undefined,
      };
    }

    if (fields) {
      // console.log(`FIELDS SUBMIT`, fields);
      fields.id = userId;
      postRegisterUserComplete(formatFields(fields))
        .then((res) => {
          if (res && res.status === 200) {
            // const userResponse = JSON.parse(res.request.response);
            // const data = userResponse.data;

            let bodyFormData = new FormData();
            bodyFormData.set("username", inputCPFValue);
            bodyFormData.set("password", inputPasswordValue);
            bodyFormData.set("firstLogin", true);
            bodyFormData.set("grant_type", "password");
            pushDataLayer("submit", "button");

            console.log("Login", bodyFormData);

            postLoginUser(bodyFormData)
              .then((res) => {
                if (res && res.status === 200) {
                  const loginResponse = JSON.parse(res.request.response),
                    userToken = loginResponse.token,
                    user = loginResponse.user;
                  user.token = userToken;
                  props.dispatch(LogInUser(user));
                  props.history.push("/clube-de-vantagens");
                }
              })
              .catch((e) => {
                console.log(e.response);
                setSubmitting(false);
              });
          }
        })
        .catch((e) => {
          let error = "";
          const errorData = e.response?.data?.errors;
          if (errorData?.length > 0) {
            [].concat(errorData).map((i) => {
              return (error += i.message + " | ");
            });
          }
          setHasRequestErrors(error);
          setSubmitting(false);
        });
    }
  }

  function titleRegister(state) {
    if (state === 1) {
      return (<span>Cadastre seus dados</span>)
    }
    if (state === 2) {
      return (<span>Cadastre seu endereço</span>)
    }
    if (state === 3) {
      return (<span>Cadastre seus pets</span>)
    }
    if (state === 4) {
      return (<h1>Agora falta pouco!<br /><span>Cadastre sua senha</span></h1>)
    }
  }

  const [invalidCep, setInvalidCep] = useState(false);
  const [inputCepValue, setInputCepValue] = useState("");
  const [inputCep] = useState({
    type: "text",
    name: "cep",
    value: inputCepValue,
    replaceValueOnChange: true,
    minLength: 8,
    maxLength: 8,
    onChange: function (value) {
      setInputCepValue(value);
      return value;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_cep"
      });

      e.target.value.length < 3 ? setInvalidCep(true) : setInvalidCep(false);
    },
    onFocus: function () {
      pushDataLayer("focus", "cep");
    },
  });

  // const [invalidCidade, setInvalidCidade] = useState(false);
  // const [inputCidadeValue, setInputCidadeValue] = useState("");
  // const [inputCidade] = useState({
  //   type: "text",
  //   name: "cidade",
  //   value: inputCidadeValue,
  //   replaceValueOnChange: true,
  //   minLength: 3,
  //   maxLength: 100,
  //   onChange: function (value) {
  //     setInputCidadeValue(value);
  //     return value;
  //   },
  //   onBlur: function (e) {
  //     dataLayer.push({
  //       event: "cadastro_preencheu_cidade"
  //     });

  //     e.target.value.length < 3 ? setInvalidCidade(true) : setInvalidCidade(false);
  //   },
  //   onFocus: function () {
  //     pushDataLayer("focus", "cidade");
  //   },
  // });

  const [invalidNumero, setInvalidNumero] = useState(false);
  const [inputNumeroValue, setInputNumeroValue] = useState("");
  const [inputNumero] = useState({
    type: "text",
    name: "numero",
    value: inputNumeroValue,
    replaceValueOnChange: true,
    minLength: 1,
    maxLength: 10,
    onChange: function (value) {
      setInputNumeroValue(value);
      return value;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_numero"
      });

      e.target.value.length < 1 ? setInvalidNumero(true) : setInvalidNumero(false);
    },
    onFocus: function () {
      pushDataLayer("focus", "numero");
    },
  });

  const [invalidEndereco, setInvalidEndereco] = useState(false);
  const [inputEnderecoValue, setInputEnderecoValue] = useState("");
  const [inputEndereco] = useState({
    type: "text",
    name: "endereco",
    value: inputEnderecoValue,
    replaceValueOnChange: true,
    minLength: 3,
    maxLength: 100,
    onChange: function (value) {
      setInputEnderecoValue(value);
      return value;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_endereco"
      });

      e.target.value.length < 3 ? setInvalidEndereco(true) : setInvalidEndereco(false);
    },
    onFocus: function () {
      pushDataLayer("focus", "endereco");
    },
  });

  const [inputComplementoValue, setInputComplementoValue] = useState("");
  const [inputComplemento] = useState({
    type: "text",
    name: "complemento",
    value: inputComplementoValue,
    replaceValueOnChange: true,
    maxLength: 50,
    onChange: function (value) {
      setInputComplementoValue(value);
      return value;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_complemento"
      });
    },
    onFocus: function () {
      pushDataLayer("focus", "complemento");
    },
  });

  const [selectedEstado, setSelectedEstado] = useState("");
  const [selectEstado] = useState({
    placeholder: "",
    isSearchable: false,
    onChange: function (value) {
      setSelectedEstado(value.value);
    },
    options: [
      { label: "Acre", value: "AC" },
      { label: "Alagoas", value: "AL" },
      { label: "Amapá", value: "AP" },
      { label: "Amazonas", value: "AM" },
      { label: "Bahia", value: "BA" },
      { label: "Ceará", value: "CE" },
      { label: "Distrito Federal", value: "DF" },
      { label: "Espírito Santo", value: "ES" },
      { label: "Goiás", value: "GO" },
      { label: "Maranhão", value: "MA" },
      { label: "Mato Grosso", value: "MT" },
      { label: "Mato Grosso do Sul", value: "MS" },
      { label: "Minas Gerais", value: "MG" },
      { label: "Pará", value: "PA" },
      { label: "Paraíba", value: "PB" },
      { label: "Paraná", value: "PR" },
      { label: "Pernambuco", value: "PE" },
      { label: "Piauí", value: "PI" },
      { label: "Rio de Janeiro", value: "RJ" },
      { label: "Rio Grande do Norte", value: "RN" },
      { label: "Rio Grande do Sul", value: "RS" },
      { label: "Rondônia", value: "RO" },
      { label: "Roraima", value: "RR" },
      { label: "Santa Catarina", value: "SC" },
      { label: "São Paulo", value: "SP" },
      { label: "Sergipe", value: "SE" },
      { label: "Tocantins", value: "TO" },
    ],
    onFocus: function () {
      if ("function" === typeof pushDataLayer) {
        pushDataLayer("focus", "select_estado");
      }
    },
  });

  useEffect(() => {
    getCitiesByState(selectedEstado).then((res) => {
      setCityLoaded(false);
      if (res && res.status === 200) {
        let citiesOptions = [];
        const cities = JSON.parse(res.request.response);
        cities.map((i) => {
          return citiesOptions.push({
            label: i.name,
            value: i.name,
          });
        });
        setSelectCidade({
          placeholder: "",
          options: citiesOptions,
          isSearchable: true,
          onChange: function (value) {
            setSelectedCidade(value);
          },
        });
        setCityLoaded(true);
      }
    });
  }, [selectedEstado]);


  return (
    <>
      <div id="ModalError-CadastrarPet">
        <Modal
          title={title}
          modalClose={modalCloseForm}
          setModalClose={setModalCloseForm}
          modalContent={modalContent}
        />
        <Modal
          title={titleModalSenha}
          modalClose={modalCloseSenha}
          setModalClose={setModalCloseSenha}
          modalContent={modalContentSenha}
        />
      </div>
      <div id="Cadastrar">
        <div id="skip">
          <a href="#cadastrar__container">
            Pular para o conteúdo principal da página
          </a>
        </div>
        <h1 className="sr-only">
          Faça seu Cadastro | Clube de Vantagens Bravecto
        </h1>
        <MetaTags
          title="Faça seu Cadastro | Clube de Vantagens Bravecto"
          description="Faça seu Cadastro no Clube de Vantagens Bravecto e tenha acesso a Todos os Benefícios. Confira!"
        />
        <Header />
        <div id="cadastrar__container">
          <div id="title">
            <div id="main-title">
              {titleRegister(formStage)}
            </div>
          </div>
          <div id="bols__container">
            <div className={formStage === 1 ? `bols_active` : ''} id="bols">{formStage === 1 ? `1` : ''}</div>
            <div className={formStage === 2 ? `bols_active` : ''} id="bols">{formStage === 2 ? `2` : ''}</div>
            <div className={formStage === 3 ? `bols_active` : ''} id="bols">{formStage === 3 ? `3` : ''}</div>
            <div className={formStage === 4 ? `bols_active` : ''} id="bols">{formStage === 4 ? `4` : ''}</div>
          </div>
          <form onSubmit={handleSubmit} style={{ display: formStage === 1 ? '' : 'none', margin: 0}}>
            <div id="row">
              <div id="content">
                <span>Nome completo*</span>
              </div>
              <Input {...inputName} />
              <div
                id="error__field"
                style={{
                  display: invalidName || hasFormErrors.name ? "flex" : "none",
                }}
              >
                <span>
                  {(invalidName || hasFormErrors.name) && "Nome inválido"}
                </span>
              </div>
            </div>

            <div id="row">
              <div id="content">
                <span>E-mail*</span>
              </div>
              <Input {...inputEmail} />
              <div
                id="error__field"
                style={{ display: emailExists ? "flex" : "none" }}
              >
                <span>Email já existente</span>
              </div>
              <div
                id="error__field"
                style={{
                  display: hasFormErrors.email || !emailMask ? "flex" : "none",
                }}
              >
                <span>E-mail não válido</span>
              </div>
              {emailListFiltered.length > 0 && !emailMask && (
                <div id="emailSugg">
                  <div id="emailSugg__container">
                    {emailListFiltered.map((i) => {
                      return (
                        <input
                          readOnly
                          type="text"
                          key={i}
                          defaultValue={`@${i}`}
                          onClick={handleEmailSugg}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div id="row">
              <div id="content">
                <span>CPF*</span>
              </div>
              <Input {...inputCPF} />
              <div
                id="error__field"
                style={{
                  display: hasFormErrors.document || cpfExists ? "flex" : "none",
                }}
              >
                <span>
                  {cpfExists === "invalid" || hasFormErrors.document
                    ? "CPF não válido"
                    : "CPF já existente"}
                </span>
              </div>
            </div>

            {/*
          <div id="row">
              <div id="content">
                  <span>
                      Ano de Nascimento:
                  </span>
              </div>
              <Input {...inputYearBirth}/>
              <div id="error__field" style={{ display: hasFormErrors.yearBirth || !yearBirthValid ? 'flex' : 'none' }}>
                  <span>{ hasFormErrors.yearBirth || !yearBirthValid ? 'Ano de nascimento inválido' : ''}</span>
              </div>
          </div>
          */}

            {/*
          <div id="row">
              <div id="content">
                  <span>
                      Sexo:
                  </span>
              </div>
              <div id="container__input--radio">

                  <div id="input--radio__content" className="two-radios">
                      <input id="input--1" type="radio" name="gender" value="M" onChange={(e) => {
                          setInputGenderValue(e.target.value)
                      }} />
                      <label htmlFor="input--1">Masculino</label>
                  </div>

                  <div id="input--radio__content" className="two-radios">
                      <input id="input--2" type="radio" name="gender" value="F" onChange={(e) => {
                          setInputGenderValue(e.target.value)
                      }} />
                      <label htmlFor="input--2">Feminino</label>
                  </div>

                  <div id="input--radio__content" className="two-radios">
                      <input id="input--3" type="radio" name="gender" value="N/A" onChange={(e) => {
                          setInputGenderValue(e.target.value)
                      }} />
                      <label htmlFor="input--3">Prefiro não informar</label>
                  </div>
              </div>
              <div id="error__field" style={{display: hasFormErrors.gender ? 'flex' : 'none'}}>
                  <span>Favor selecionar uma opção</span> 
              </div>
          </div>
          */}
            {/* <div id="row">
              <div id="content">
                  <span>
                      Nascimento:
                  </span>
              </div>
              <Input {...inputBirthday} />
              <div id="error__field" style={{display: hasFormErrors.birthday ? 'flex' : 'none'}}>
                  <span>Data inválida</span>
              </div>
          </div> 
          */}
            {/*
          <div id="row">
              <div id="content">
                  <span>
                      Estado:
                  </span>
              </div>
              {
                  statesLoaded
                      ? <SelectCustom {...selectEstado} />
                      : <div id="preLoader"><Loader /></div>
              }
              {
              /*
                  <div id="error__field" style={{display: hasFormErrors.state ? 'flex' : 'none'}}>
                      <span>Selecione seu estado</span>
                  </div>
              </div>
          */}
            {/*
          <div id="row">
              <div id="content">
                  <span>
                      Cidade:
                  </span>
              </div>
              {
                  cityLoaded
                      ? <SelectCustom {...selectCidade} />
                      : <div></div>
              }
              {
                  /*
                  <div id="error__field" style={{display: hasFormErrors.city ? 'flex' : 'none'}}>
                      <span>Selecione sua cidade</span>
                  </div>
          </div>
          */}
            <div id="row">
              <div id="content">
                <span>Telefone</span>
              </div>
              <Input {...inputPhone} />
              <div
                id="error__field"
                style={{
                  display:
                    hasFormErrors.phone || phoneMask === false || !phoneIsValid
                      ? "flex"
                      : "none",
                }}
              >
                <span>Telefone não válido</span>
              </div>
            </div>
            <span id="required">*Campos de preenchimento obrigatório</span>

            {/* 
          <div id="row">
            <div id="content">
              <span>Eu tenho:</span>
            </div>
            <div id="container__input--radio">
              <div id="input--radio__content">
                <input
                  id="input--euTenho1"
                  type="radio"
                  name="euTenho"
                  value="1"
                  onChange={(e) => {
                    setInputPetTypeValue(e.target.value);
                  }}
                />
                <label htmlFor="input--euTenho1">Cão</label>
              </div>
              <div id="input--radio__content">
                <input
                  id="input--euTenho2"
                  type="radio"
                  name="euTenho"
                  value="2"
                  onChange={(e) => {
                    setInputPetTypeValue(e.target.value);
                  }}
                />
                <label htmlFor="input--euTenho2">Gato</label>
              </div>
              <div id="input--radio__content">
                <input
                  id="input--euTenho3"
                  type="radio"
                  name="euTenho"
                  value="3"
                  onChange={(e) => {
                    setInputPetTypeValue(e.target.value);
                  }}
                />
                <label htmlFor="input--euTenho3">Cão e Gato</label>
              </div>
            </div>
            <div
              id="error__field"
              style={{ display: hasFormErrors.petType ? "flex" : "none" }}
            >
              <span>Favor selecionar uma opção</span>
            </div>
          </div> */}


            {/*
            <div id="row">
                <div id="content">
                    <span>
                        Possui um código promocional:
                    </span>
                </div>
                <Input {...inputPromotionalCode} />
                <div id="error__field" style={{display: hasFormErrors.promotionalCode ? 'flex' : 'none'}}>
                    <span>Código inválido</span>
                </div>
            </div>
          */}

            {/* <div id="row">
            <div id="content">
              <span>Já usou Bravecto antes:</span>
            </div>
            <div id="container__input--radio">
              <div id="input--radio__content">
                <input
                  id="input--usouBravecto1"
                  type="radio"
                  name="usouBravecto"
                  value="1"
                  onChange={(e) => {
                    setInputIsBravectoValue(parseInt(e.target.value, 10));
                  }}
                />
                <label htmlFor="input--usouBravecto1">Sim</label>
              </div>
              <div id="input--radio__content">
                <input
                  id="input--usouBravecto2"
                  type="radio"
                  name="usouBravecto"
                  value="0"
                  onChange={(e) => {
                    setInputIsBravectoValue(parseInt(e.target.value, 10));
                  }}
                />
                <label htmlFor="input--usouBravecto2">Não</label>
              </div>
            </div>

            <div
              id="error__field"
              style={{ display: hasFormErrors.isBravecto ? "flex" : "none" }}
            >
              <span>Favor selecionar uma opção</span>
            </div>
          </div> */}
            {/* {parseInt(inputIsBravectoValue) === 1 && (
            <div id="row">
              <div id="content">
                <span>Há quanto tempo aproximadamente?</span>
              </div>
              <SelectCustom {...selectQuantoTempo} />
              <div
                id="error__field"
                style={{
                  display: hasFormErrors.DaysFromLatestBravectoHandling
                    ? "flex"
                    : "none",
                }}
              >
                <span>Selecione uma opção</span>
              </div>
            </div>
          )} */}


            <div
              id="error__main"
              style={{ display: hasRequestErrors ? "flex" : "none" }}
            >
              <div>
                <span id="title">ERRO NO CADASTRO</span>
              </div>
              <div>
                <span id="info">{hasRequestErrors}</span>
              </div>
            </div>
          </form>
          <div id="row" style={{ display: formStage === 1 ? '' : 'none' }}>
            <button type="button" disabled={isSubmitting} id="next" onClick={(e) => handleSubmit(e)}>
              {isSubmitting ? <LoaderForButton /> : <span>Avançar</span>}
            </button>
          </div>

          <form style={{ display: formStage === 2 ? '' : 'none' }}>
          <div id="row">
            <div id="content">
              <span>CEP*</span>
            </div>
            <Input {...inputCep} />
            <div
              id="error__field"
              style={{
                display: invalidCep || hasFormErrors.cep ? "flex" : "none",
              }}
            >
              <span>CEP inválido</span>
            </div>
          </div>


          <div id="double-col">
            <div id="row">
              <div id="content">
                <span>Estado*</span>
              </div>
              <SelectCustom {...selectEstado} />
              <div
                id="error__field"
                style={{
                  display: hasFormErrors.state ? "flex" : "none",
                }}
              >
                <span>Selecione seu estado</span>
              </div>
            </div>


            <div id="row">
                <div id="content">
                    <span>
                        Cidade*
                    </span>
                </div>
                <SelectCustom {...selectCidade} />
                {/* {
                    selectedEstado
                        ? <SelectCustom {...selectCidade} />
                        : <></>
                } */}
                <div id="error__field" style={{display: hasFormErrors.city ? 'flex' : 'none'}}>
                    <span>Selecione sua cidade</span>
                </div>
            </div>
          </div>


          {/* <div id="row">
            <div id="content">
              <span>Cidade*</span>
            </div>
            <Input {...inputCidade} />
            <div
              id="error__field"
              style={{
                display: invalidCidade || hasFormErrors.city ? "flex" : "none",
              }}
            >
              <span>Digite sua cidade</span>
            </div>
          </div> */}

          <div id="row">
            <div id="content">
              <span>Endereço*</span>
            </div>
            <Input {...inputEndereco} />
            <div
              id="error__field"
              style={{
                display: invalidEndereco || hasFormErrors.street ? "flex" : "none",
              }}
            >
              <span>Endereço inválido</span>
            </div>
          </div>


          <div id="double-col">
            <div id="row">
              <div id="content">
                <span>Número*</span>
              </div>
              <Input {...inputNumero} />
              <div
                id="error__field"
                style={{
                  display: invalidNumero || hasFormErrors.number ? "flex" : "none",
                }}
              >
                <span>Número inválido</span>
              </div>
            </div>

            <div id="row">
              <div id="content">
                <span>Complemento</span>
              </div>
              <Input {...inputComplemento} />
            </div>
          </div>

          <span id="required">*Campos de preenchimento obrigatório</span>

          <div
            id="error__main"
            style={{ display: hasRequestErrors ? "flex" : "none" }}
          >
            <div>
              <span id="title">ERRO NO CADASTRO</span>
            </div>
            <div>
              <span id="info">{hasRequestErrors}</span>
            </div>
          </div>
          </form>
          <div style={{ display: formStage === 2 ? '' : 'none' , margin: 0}} id="btn-submit">
              <button id="btn-voltar" onClick={(e) => {
                e.preventDefault();
                backStep1();
              }}></button>
              <button
                type="submit"
                id="next"
                disabled={isSubmitting}
                onClick={(e) => handleSubmit(e)}
              >
                {isSubmitting ? <LoaderForButton /> : <span>Avançar</span>}
              </button>
            </div>

          <div id="CadastrarPet-User" style={{ display: formStage === 3 ? '' : 'none' }}>
            {petsIndices.filter(n => null != n).map((pi) => <FormCadastrarPet updatePet={updatePetIndex} removePet={removePetIndex} petIndex={pi} petCount={petsIndices.filter(n => null != n).length} key={`pet${pi}`} />)}
            <div id="CadastrarPet">
              <div id="cadastrarPet__container">
                <div id="btn-add-pet">
                  <button type="button" id="button-add-pet" onClick={() => addNewPet()}>Adicionar outro pet</button>
                </div>
                <div id="btn-submit">
                  <button id="btn-voltar" onClick={() => backStep2()}></button>
                  <button
                    type="submit"
                    id="btn-cadastrarPet"
                    disabled={isSubmitting}
                    onClick={() => goToStep4()}
                  >
                    {isSubmitting ? <LoaderForButton /> : <span>Salvar e avançar</span>}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: formStage === 4 ? '' : 'none' }}>
            <form>
              <div id="row">
                <div id="content">
                  <span>Escolha uma senha</span>
                  <span id="senha" onClick={() => setModalCloseSenha(true)}></span>
                </div>
                <Input {...inputPassword} />
                <div
                  id="error__field"
                  style={{ display: passwordMask ? "flex" : "none" }}
                >
                  <span>
                    ainda faltam {!passwordMask.uppercase && `letras maiúsculas /`}{" "}
                    {!passwordMask.lowercase && `letras minúsculas / `}{" "}
                    {!passwordMask.numbers && `números / `}{" "}
                    {!passwordMask.symbols && `símbolos / `}{" "}
                    {passwordMask.length !== true &&
                      `${passwordMask.length} caracteres`}
                  </span>
                </div>
                {/* <div
                  id="arrowUpTooltip"
                  style={{ display: tooltipPassword ? "flex" : "none" }}
                >
                  <MdArrowDropUp />
                </div>
                <div
                  id="tooltipPassword"
                  style={{ display: tooltipPassword ? "flex" : "none" }}
                >
                  <div id="tooltipContent">
                    <span>
                      A senha deve conter no mínimo:
                      <br />8 caracteres, sendo: <br /> - ao menos 1 letra maiúscula{" "}
                      <br /> - ao menos 1 letra minúscula <br /> - ao menos 1 número{" "}
                      <br /> - ao menos 1 símbolo (ex @ & ? $)
                    </span>
                  </div>
                </div> */}
              </div>
              <div id="row">
                <div id="content">
                  <span>Confirmar senha</span>
                </div>
                <Input {...inputConfirmPassword} />
                <div
                  id="error__field"
                  style={{
                    display: !inputConfirmedPassword && inputConfirmPasswordValue.length !== 0 ? "flex" : "none",
                  }}
                >
                  <span>Senhas não conferem</span>
                </div>
              </div>
            </form>
            <div id="row">
              <div id="container__input--manual">
                <div id="content__input--manual" className={!acceptTermsOfUse
                  ? 'input--terms input--terms-show' : 'input--terms'}>
                  <div
                    className={
                      acceptTermsOfUse === true
                        ? "input--manual input--manual__checked"
                        : acceptTermsOfUse === false
                          ? "input--manual input--manual__unchecked errorInputAcceptTerms"
                          : "input--manual input--manual__unchecked"
                    }
                    onClick={() => {
                      dataLayer.push({
                        event: "cadastro_check_termos"
                      });
                      setAcceptTermsOfUse(!acceptTermsOfUse);
                    }}
                  >
                    {acceptTermsOfUse ? <MdCheck /> : ""}
                  </div>
                  <span
                    className={
                      acceptTermsOfUse === false ? "errorAcceptTerms" : ""
                    }
                  >
                    Aceitos os Termos de Uso e a Política de Privacidade,<br />
                    incluindo o uso de cookies e o envio de conteúdos, novidades<br />
                    e promoções exclusivas, por email, sms e/ou whatsapp.
                  </span>
                </div>
                {/* <div id="content__input--manual" className={acceptTermsOfUse ? 'input--news input--news-show' : 'input--news'}>
                  <div
                    className={
                      acceptNews
                        ? "input--manual__checked"
                        : "input--manual__unchecked"
                    }
                    onClick={() => {
                      setAcceptNews(!acceptNews);
                    }}
                  >
                    {acceptNews ? <MdCheck /> : ""}
                  </div>
                  <span>Desejo receber novidades</span>
                </div> */}
              </div>
            </div>
            <div id="CadastrarPet-User">
              <div id="CadastrarPet">
                <div id="cadastrarPet__container">
                  <div id="btn-submit">
                    <button id="btn-voltar" onClick={() => backStep3()}></button>
                    <button
                      type="submit"
                      id="btn-cadastrarPet"
                      className="submit-cadastro"
                      disabled={isSubmitting}
                      onClick={(ev) => handleSubmit3(ev)}
                    >
                      {isSubmitting ? <LoaderForButton /> : <span>Finalizar Cadastro</span>}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    </>
  );
};

export default Connect()(Cadastrar);
