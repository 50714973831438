import "./setupTests.js";

import React from "react";
// import ReactDOM from "react-dom";

import { render } from "react-snapshot";

import App from "./components/App";

import Provider from "./store/config/provider";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./index.css";

// ReactDOM.render(
render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
