import React, { useEffect } from "react";

import Router from "../router/router";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css";

const App = (props) => {
  useEffect(() => {
    var now = new Date();
    var initial = new Date(
      "Wed Oct 11 2020 12:00:00 GMT-0300 (Brasilia Standard Time)"
    );
    var final = new Date(
      "Wed Oct 11 2020 23:59:59 GMT-0300 (Brasilia Standard Time)"
    );
    if (now > initial && now < final) {
      window.location.assign(
        "https://www.petlove.com.br/colecao/bravecto-com-ofertas-exclusivas?utm_source=The%20Voice&utm_medium=Redirecionamento&utm_campaign=Bravecto_15"
      );
    }
  }, []);
  return (
    <React.Fragment>
      <Router />
    </React.Fragment>
  );
};

export default App;
