import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Loader from "../Loader/Loader";

import SelectCustom from "../Utils/Select/Select";
import { slugify } from "../../utils/helpers/general";
import Input from "../Utils/Input/Input";

import {
  // getTips,
  getTipsCategories,
  getTipsFiltered,
} from "../../services/services";

import "./Dicas.css";
import "./DicasItems.css";

// import { normalizeUnits } from "moment";

const Dicas = () => {
  document.title = "Clube Bravecto | Dicas";
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const [categories, setCategories] = useState(null);
  const [categorySelected, setCategorySelected] = useState({
    value: query.get("category"),
  });
  const [inputPetTypeValue, setInputPetTypeValue] = useState(3);
  const [nameSearch, setNameSearch] = useState(query.get("name"));

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  var url = window.location.href;
// const api = "https://clubebravectoapi.shiftinc.com.br/api/v1/";
  const api = //url.includes('localhost') ||
     url.includes("clubebravecto") && !url.includes("shiftinc")
       ? "https://api.clubebravecto.com.br/api/v1/"
       : "https://clubebravectoapi.shiftinc.com.br/api/v1/";

  useEffect(() => {
    const arrCategories = [];
    let selectedCategory = null;
  
    getTipsCategories()
      .then((res) => {
        if (res && res.status === 200) {
          const queryCategory = query.get("category");
          const c = JSON.parse(res.request.response);
          for (let i = 0; i < c.length; i++) {
            if (queryCategory === c[i].slug) {
              selectedCategory = { value: c[i].slug, label: c[i].name };
            }
            arrCategories.push({ value: c[i].slug, label: c[i].name });
          }
        }
      })
      .then(() => {
        setCategories({
          isDisabled: false,
          onChange: function (value) {
            setCategorySelected(value);
          },
          options: arrCategories,
          placeholder: "Todas",
          defaultValue: selectedCategory,
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tipsLen, setTipsLen] = useState(0);
  const [tips, setTips] = useState([]);
  // const [oldTips, setOldTips] = useState([]);

  const loadingTips = useRef(true);
  const getAllTips = useCallback((page, petType, category, name, clear) => {
    if (clear) {
      setTips([]);
      // setOldTips([]);
      lastScrollTop.current = 0;
    }

    loadingTips.current = true;
    getTipsFiltered(page, petType, category, name).then((res) => {
      if (res && res.status === 200) {
        const data = JSON.parse(res.request.response);
        const tipsResponse = data && data.tips;
        const tipsLen = data && data.total;
        // Total Lenght of tips
        setTipsLen(tipsLen);

        if (tipsResponse.length > 0) {
          // tipsResponse.forEach((i) => {
          //   // if (pageNumber === 1) {
          //   //   setOldTips((oldTips) => [...oldTips, i]);
          //   // }
          //   setTips((tips) => [...tips, i]);
          // });
            setTips((tips) => [...tips, ...tipsResponse]);
        } else {
          setTips([]);
          // setOldTips([]);
          lastScrollTop.current = 0;
        }
        loadingTips.current = false;
        forceUpdate();
      }
      if (res && res.status !== 200) {
        // if (!tips) {
          // setTipsLen(0);
        // }

        loadingTips.current = false;
        forceUpdate();
      }
    });
  }, [forceUpdate])

  const handleFilters = useCallback((action) => {
    const pet = petType.current < 3 ? petType.current : null;
    const c = category.current;
    const n = name.current;
    const categ = c && c.label !== "Todas" ? c && c.value : null;
    pageNumber.current = !action ? pageNumber.current + 1 : 1;

    getAllTips(pageNumber.current, pet, categ, n, action);
  }, [getAllTips])

  const handlePages = useCallback(() => {
    handleFilters(false);
  }, [handleFilters])

  const category = useRef(null);
  const name = useRef(null);
  const petType = useRef(null);
  useEffect(() => {
    petType.current = parseInt(inputPetTypeValue);
    category.current = categorySelected;
    name.current = nameSearch;

    handleFilters(true);
  }, [inputPetTypeValue, categorySelected, nameSearch, handleFilters]);

  const triggerScroll = useCallback(() => {
    if (lastScrollTop.current < window.scrollY) {
      lastScrollTop.current = window.scrollY;
      const { clientHeight, scrollHeight, scrollTop } =
        document.documentElement;
      if (
        scrollTop + clientHeight >= scrollHeight - 250 &&
        !loadingTips.current
      ) {
        loadingTips.current = true;
        forceUpdate();
        handlePages();
      }
    }
  }, [forceUpdate, handlePages]);

  const lastScrollTop = useRef(window.scrollY);
  const pageNumber = useRef(1);
  useEffect(() => {
    window.addEventListener("scroll", triggerScroll);
    return () => {
      window.removeEventListener("scroll", triggerScroll);
    };
  }, [triggerScroll]);

  const [inputName] = useState({
    type: "text",
    name: "nameSearch",
    maxLength: 90,
    placeholder: "Autor",
    replaceValueOnChange: true,
    defaultValue: nameSearch,
    hidden: true,
    onChange: function (text) {
      setNameSearch(text);
    },
  });

  return (
    <div id="Dicas">
      <Header />
      <div id="container">
        <div id="title">
          <div id="main-title">
            <span>Blog</span>
          </div>
        </div>
        <div className="dicas__filter">
          <span className="dicas__input--title">Espécie</span>
          <div className="dicas__radio">
            <div className="dicas__radio--item">
              <div
                className={`dicas__radio--input-container${
                  parseInt(inputPetTypeValue) === 1
                    ? " dicas__radio--input-container-selected"
                    : ""
                }`}
              >
                <input
                  className="dicas__radio--input"
                  id="inputSpecies--1"
                  type="radio"
                  name="species"
                  value="1"
                  onChange={(e) => {
                    setInputPetTypeValue(e.target.value);
                  }}
                />
              </div>
              <label className="dicas__radio--label" htmlFor="inputSpecies--1">
                Cão
              </label>
            </div>
            <div className="dicas__radio--item">
              <div
                className={`dicas__radio--input-container${
                  parseInt(inputPetTypeValue) === 2
                    ? " dicas__radio--input-container-selected"
                    : ""
                }`}
              >
                <input
                  className="dicas__radio--input"
                  id="inputSpecies--2"
                  type="radio"
                  name="species"
                  value="2"
                  onChange={(e) => {
                    setInputPetTypeValue(e.target.value);
                  }}
                />
              </div>
              <label className="dicas__radio--label" htmlFor="inputSpecies--2">
                Gato
              </label>
            </div>
            <div className="dicas__radio--item">
              <div
                className={`dicas__radio--input-container${
                  parseInt(inputPetTypeValue) === 3
                    ? " dicas__radio--input-container-selected"
                    : ""
                }`}
              >
                <input
                  className="dicas__radio--input"
                  id="inputSpecies--3"
                  type="radio"
                  name="species"
                  value="3"
                  onChange={(e) => {
                    setInputPetTypeValue(e.target.value);
                  }}
                />
              </div>
              <label className="dicas__radio--label" htmlFor="inputSpecies--3">
                Cão e Gato
              </label>
            </div>
          </div>
          <span className="dicas__input--title dicas__input--title-special">
            Selecione uma categoria
          </span>
          {categories ? <SelectCustom {...categories} /> : ""}
          <Input {...inputName} />
        </div>
        <span className="tipslength">
          <span>
            {loadingTips.current
              ? `Carregando conteúdo...`
              : parseInt(tipsLen) === 0 || tips.length === 0
              ? `Nenhuma dica encontrada`
              : parseInt(tipsLen) === 1
              ? `${parseInt(tipsLen)} dica encontrada`
              : `Mostrando ${tips.length} de ${parseInt(
                  tipsLen
                )} conteúdos encontrados`}
          </span>
        </span>
        <div className="dicas__container">
          {tips.map((i) => {
            return (
              <Link
                to={`/blog/${(i.slug)}`}
                className="dicas__item"
                key={i.id}
              >
                <figure className="dicas__item--figure">
                  <img
                    className="dicas__item--img"
                    alt="título"
                    src={`${api}image/getImage/${i.id}/TipThumbnail`}
                  />
                </figure>
                <div className="dicas__item--title">
                  <figure className="dicas__item--logoFigure">
                    <img
                      className="dicas__item--img"
                      alt="título"
                      src={`${api}image/getImage/${i.id}/TipLogo`}
                    />
                  </figure>
                  <span className="dicas__item--titleText">{`${i.title}`}</span>
                </div>
                <span className="dicas__item--text">
                  <span
                    dangerouslySetInnerHTML={{ __html: i.descriptionPreview }}
                  ></span>
                </span>
                <span className="dicas__item--link">Leia mais &raquo;</span>
              </Link>
            );
          })}
          {loadingTips.current && (
            <div id="preLoader">
              <Loader />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dicas;
