import React, { useState, useEffect } from "react";
import { MdArrowDropUp } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { postRecoveryPassword } from "../../services/services";

import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";

import "./NovaSenha.css";

const NovaSenha = (props) => {
  document.title = "Clube Bravecto | Nova Senha";

  const history = useHistory();

  const [successRetrieve, setSuccesRetrieve] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [hasFormErrors, setHasFormErrors] = useState(false);

  const [tooltipPassword, setTooltipPassword] = useState(false);

  const [passwordMask, setPasswordMask] = useState(false);

  const [inputPasswordValue, setInputPasswordValue] = useState("");
  const [inputPassword] = useState({
    type: "password",
    name: "password",
    replaceValueOnChange: true,
    onChange: function (pass) {
      const testAllMasks =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/g;
      const testNumber = /(\d)/g;
      const testLowerCase = /([a-z])/g;
      const testUpperCase = /([A-Z])/g;
      const testSymbols = /([@$!%*?&#])/g;
      setPasswordMask({
        uppercase: testUpperCase.test(pass.toString()) ? true : false,
        lowercase: testLowerCase.test(pass.toString()) ? true : false,
        numbers: testNumber.test(pass.toString()) ? true : false,
        symbols: testSymbols.test(pass.toString()) ? true : false,
        length: pass.length > 8 ? true : 8 - pass.length,
      });

      if (testAllMasks.test(pass.toString())) {
        setPasswordMask(false);
      }

      setInputPasswordValue(pass);
      return pass;
    },
    onBlur: function () {
      setTooltipPassword(false);
    },
    onFocus: function () {
      setTooltipPassword(true);
    },
  });

  const [inputConfirmPasswordValue, setInputConfirmPasswordValue] =
    useState("");
  const [inputConfirmPassword] = useState({
    type: "password",
    name: "confirmPassword",
    replaceValueOnChange: true,
    onChange: function (confirmPass) {
      setInputConfirmPasswordValue(confirmPass);
      return confirmPass;
    },
  });

  const [isSubmitting, setSubmitting] = useState(false);

  function formValidation() {
    let formErrors = {},
      search = window.location.search,
      params = new URLSearchParams(search),
      code = params.get("code");

    const fields = {
      password: !passwordMask
        ? inputPasswordValue === inputConfirmPasswordValue
          ? inputPasswordValue
          : false
        : false,
      confirmPassword: !passwordMask
        ? inputConfirmPasswordValue === inputPasswordValue
          ? inputConfirmPasswordValue
          : false
        : false,
      code: code,
    };

    Object.keys(fields).map((i) => {
      if (!fields[i]) {
        formErrors[i] = true;
      }
      return formErrors[i];
    });

    if (Object.keys(formErrors).length > 0) {
      setHasFormErrors(formErrors);
      setSubmitting(false);
      return false;
    } else {
      return fields;
    }
  }

  const [hasRequestErrors, setHasRequestErrors] = useState(false);
  const [succesSubmit, setSuccessSubmit] = useState(false);
  function handleSubmit(e) {
    setSubmitting(true);
    setHasFormErrors(false);
    e.preventDefault();
    const payload = formValidation();

    postRecoveryPassword(payload, props.token)
      .then((res) => {
        if (res && res.status === 200) {
          setSuccessSubmit(true);
          setSubmitting(false);
          setSuccesRetrieve(true);
        }
      })
      .catch((e) => {
        setSuccessSubmit(false);
        setSubmitting(false);
        setHasRequestErrors(
          e.response.data.errors &&
            e.response.data.errors.map((i) => {
              return i.message + "  ";
            })
        );
      });
  }

  function goToLogin() {
    return history.push(`/login`);
  }

  return (
    <div id="NovaSenha">
      <div id="__container">
        <div id="sou-cliente">
          {successRetrieve ? (
            <div id="__container">
              <div id="title">
                <div id="main-title">
                  <span>Nova senha</span>
                </div>
                <div id="info">
                  <span>Nova senha criada com sucesso.</span>
                </div>
                <div id="button-redirect">
                  <button onClick={goToLogin}>Ir para o Login</button>
                </div>
              </div>
            </div>
          ) : (
            <div id="__container">
              <div id="title">
                <div id="main-title">
                  <span>Nova senha</span>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div id="inputs">
                  <div id="row">
                    <div id="content">
                      <span>Nova senha:</span>
                    </div>
                    <Input {...inputPassword} />
                    <div
                      id="error__field"
                      style={{ display: passwordMask ? "flex" : "none" }}
                    >
                      <span>
                        ainda faltam{" "}
                        {!passwordMask.uppercase && `letras maiúsculas /`}{" "}
                        {!passwordMask.lowercase && `letras minúsculas / `}{" "}
                        {!passwordMask.numbers && `números / `}{" "}
                        {!passwordMask.symbols && `símbolos / `}{" "}
                        {passwordMask.length !== true &&
                          `${passwordMask.length} caracteres`}
                      </span>
                    </div>
                    <div
                      id="arrowUpTooltip"
                      style={{ display: tooltipPassword ? "flex" : "none" }}
                    >
                      <MdArrowDropUp />
                    </div>
                    <div
                      id="tooltipPassword"
                      style={{ display: tooltipPassword ? "flex" : "none" }}
                    >
                      <div id="tooltipContent">
                        <span>
                          A senha deve conter no mínimo:
                          <br />8 caracteres, sendo: <br /> - ao menos 1 letra
                          maiúscula <br /> - ao menos 1 letra minúscula <br /> -
                          ao menos 1 número <br /> - ao menos 1 símbolo (ex @ &
                          ? $)
                        </span>
                      </div>
                    </div>
                  </div>
                  <div id="row">
                    <div id="content">
                      <span>Confirmar senha:</span>
                    </div>
                    <Input {...inputConfirmPassword} />
                    <div
                      id="error__field"
                      style={{
                        display: hasFormErrors.confirmPassword
                          ? "flex"
                          : "none",
                      }}
                    >
                      <span>Senhas não conferem</span>
                    </div>
                  </div>
                  {hasRequestErrors && (
                    <div id="changePassword__requestError">
                      <span>{hasRequestErrors}</span>
                    </div>
                  )}
                </div>
                <div id="erroMensagem">
                  {errorMsg && <span>Usuário não encontrado.</span>}
                </div>
                <div id="confirmar">
                  <button type="submit" id="btnConfirmar">
                    {isSubmitting ? <LoaderForButton /> : "Confirmar"}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NovaSenha;
