import React, { useState, useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

import { NewPet, EditedPet, NewReminder } from "../../store/helper";
import Connect from "../../store/config/connect";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Pets from "./Pets";

import "./MeusPets.css";
import ModalReminderPet from "../CadastrarPet/ModalReminderCadastroPet/ModalReminderPet";

const MeusPets = (props) => {
  document.title = "Clube Bravecto | Meus Pets";

  const [hasNewPet, setHasNewPet] = useState(false);
  const [hasEditedPet, setHasEditedPet] = useState(false);
  const [hasNewReminder, setHasNewReminder] = useState(false);
  const [complementMsg, setComplementMsg] = useState(false);
  const [modalReminder, setModalReminder] = useState(false);


  useEffect(() => {
    if (props.newPet) {
      setHasNewPet(props.newPet);
      props.dispatch(NewPet(false));
      setComplementMsg("cadastrado");
      // if (!localStorage.firstNewPet) {
      //   setModalReminder(true);
      //   localStorage.firstNewPet = true;
      // }
    }
  }, [props, props.newPet]);

  useEffect(() => {
    if (window.location.search.includes(`promo`)) {
      setModalReminder(true);
    }
  }, [])

  useEffect(() => {
    if (props.editedPet) {
      setHasEditedPet(props.editedPet);
      props.dispatch(EditedPet(false));
      setComplementMsg("editado");
    }
  }, [props, props.editedPet]);

  useEffect(() => {
    if (props.newReminder) {
      setHasNewReminder(props.newReminder);
      props.dispatch(NewReminder(false));
    }
  }, [props, props.newReminder]);

  return (
    <div id="MeusPets" className={props.isChild && "childMargin"}>
      <ModalReminderPet
        modalClose={modalReminder}
        setModalClose={setModalReminder}
      />
      {!props.isChild && [
        <div id="skip">
          <a href="#MeusPets__container">
            Pular para o conteúdo principal da página
          </a>
        </div>,
        <h1 className="sr-only">Meus Pets - Clube de Vantagens Bravecto</h1>,
        <Header />,
      ]}
      <div id="MeusPets__container">
        {!props.isChild && (
          <div id="MeusPets-title-container">
            <div id="MeusPets--title">
              <span>Meus Pets</span>
              <div id="new-pet">
                <Link id="to-editar-perfil" to="editar-meu-perfil">
                  Meu perfil
                </Link>
                <Link to="cadastrar-pet">
                  <button type="button">Adicionar pet</button>
                </Link>
              </div>
            </div>
          </div>
        )}
        {/* <div id="MeusPets--info">
                    <span>
                        O clube de vantagens foi criado para quem sempre confiou na Bravecto para proteger o seu melhor amigo.
                    </span>
                </div> */}
        {/* <ModalReminderPet
          modalClose={modalReminder}
          setModalClose={setModalReminder}
        /> */}
        {(hasNewPet || hasEditedPet) && (
          <div id="newRegisteredPet">
            <div id="newRegisteredPet__container">
              <FaRegCheckCircle />
              <div id="newRegisteredPet__text">
                <span id="newRegisteredPet__text--little">
                  Pet {complementMsg} <br /> <b>com sucesso</b>
                </span>
              </div>
            </div>
          </div>
        )}
        {hasNewReminder && (
          <div id="newRegisteredPet">
            <div id="newRegisteredPet__container">
              <FaRegCheckCircle />
              <div id="newRegisteredPet__text">
                <span id="newRegisteredPet__text--little">
                  Lembrete cadastrado <br /> <b>com sucesso</b>
                </span>
              </div>
            </div>
          </div>
        )}
        {props.isMobile ? <Pets /> : <Pets isChild={props.isChild} />}
        {/* {!props.isChild && (
          
        )} */}
      </div>
      {!props.isChild && <Footer />}
    </div>
  );
};

const mapStateToProps = ({ helper }, props) => {
  return {
    ...props,
    ...helper,
  };
};

export default Connect(mapStateToProps)(MeusPets);
