import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./bravectoExperience.css";
import { getStatesBravectoExperience, getStoresBravectoExperience } from "../../services/services";
import CardStore from "./cardStore.js";
import SelectCustom from "../Utils/Select/Select";
// import imgVideo from "../../images/BravectoExperience/video.webp";
import bannerDesk from "../../images/BravectoExperience/BannerLpBravectoExperience.webp";
import bannerMob from "../../images/BravectoExperience/BannerLpBravectoExperienceMob.webp";
import bannerRodapeDesk from "../../images/BravectoExperience/BannerRodapeDesk.webp";
import bannerRodapeMob from "../../images/BravectoExperience/BannerRodapeMob.webp";
import Loader from "../Loader/Loader";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

const BravectoExperience = () => {
  document.title = "Clube Bravecto | Bravecto Experience";
  registerLocale('pt', pt)

  const [filtersMobile, setFiltersMobile] = useState(false)
  const [dataStores, setDataStores] = useState([])

  // retorno do serviço getStates armazenado aqui.
  const [dataStates, setDataStates] = useState([])

  // Select Estado.
  const [states, setStates] = useState([])
  const [stateSelected, setStateSelected] = useState('')
  const [optionsState, setOptionState] = useState([])
  useEffect(() => {
    if (optionsState) {
      setStates({
        value: stateSelected,
        placeholder: "Estado",
        options: optionsState,
        isSearchable: false,
        onChange: function (value) {
          setCitySelected('')
          setNeighborhoodSelected('')
          return setStateSelected(value);
        },
      });
    }
  }, [optionsState, stateSelected]);

  // Select Cidade.
  const [city, setCity] = useState([])
  const [citySelected, setCitySelected] = useState('')
  const [optionsCity, setOptionCity] = useState([])
  useEffect(() => {
    if (optionsCity) {
      setCity({
        value: citySelected,
        placeholder: "Cidade",
        options: optionsCity,
        isSearchable: false,
        noOptionsMessage: function () {
          return "Escolha um estado para desbloquear este campo!";
        },
        onChange: function (value) {
          setNeighborhoodSelected('')
          return setCitySelected(value);
        },
      });
    }
  }, [optionsCity, citySelected]);

  // Select Bairro.
  const [neighborhood, setNeighborhood] = useState([])
  const [neighborhoodSelected, setNeighborhoodSelected] = useState('')
  const [optionsNeighborhood, setOptionNeighborhood] = useState([])
  useEffect(() => {
    if (optionsNeighborhood) {
      setNeighborhood({
        value: neighborhoodSelected,
        placeholder: "Bairro",
        options: optionsNeighborhood,
        isSearchable: false,
        noOptionsMessage: function () {
          return "Escolha uma cidade para desbloquear este campo!";
        },
        onChange: function (value) {
          return setNeighborhoodSelected(value);
        },
      });
    }
  }, [optionsNeighborhood, neighborhoodSelected]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [startDateFormatted, setStartDateFormatted] = useState('')
  const [endDateFormatted, setEndDateFormatted] = useState('')

  function handleStartDateFormatted() {
    const sd = startDate
    if (sd) {
      const StartDate = new Date(sd);
      const StartDataFormatada = format(StartDate, 'dd/MM/yyyy');
      setStartDateFormatted(StartDataFormatada)
    } else {
      setStartDateFormatted('')
    }
  }

  function handleEndDateFormatted() {
    const ed = endDate
    if (ed) {
      const EndDate = new Date(ed);
      const EndDataFormatada = format(EndDate, 'dd/MM/yyyy');
      setEndDateFormatted(EndDataFormatada)
    } else {
      setEndDateFormatted('')
    }
  }

  useEffect(() => handleStartDateFormatted(), [startDate, startDateFormatted])
  useEffect(() => handleEndDateFormatted(), [endDate, endDateFormatted])

  // Select Data.
  // const [date, setDate] = useState([])
  // const [dateSelected, setDateSelected] = useState('')
  // const [optionsDate, setOptionDate] = useState([])
  // useEffect(() => {
  //   if (optionsDate) {
  //     setDate({
  //       value: dateSelected,
  //       placeholder: "Data",
  //       options: optionsDate,
  //       isSearchable: false,
  //       onChange: function (value) {
  //         return setDateSelected(value);
  //       },
  //     });
  //   }
  // }, [optionsDate, dateSelected]);

  // fetch getStates.
  useEffect(() => {
    async function getStates() {
      const { data: { states } } = await getStatesBravectoExperience();
      setDataStates(states)
    }
    getStates()
  }, [])

  // Renderizando as options do select Estado
  useEffect(() => {
    const state = dataStates.map((s) => {
      return {
        label: s.state,
        value: s.state
      }
    })
    const optionsState = [{ value: '', label: 'Selecione' }, ...state ?? []]
    setOptionState(optionsState)
  }, [dataStates])

  // Renderizando as options do select Cidade a partir do estado selecionado.
  useEffect(() => {
    const selectedStateData = dataStates?.find(state => state.state === stateSelected?.label);
    if (selectedStateData) {
      const cities = selectedStateData?.cities?.map((c) => {
        return {
          label: c.city,
          value: c.city
        }
      })

      const optionsCity = [{ value: '', label: 'Selecione' }, ...cities ?? []]
      setOptionCity(optionsCity)
    }
  }, [stateSelected, dataStates])

  // Renderizando as options do select Bairro a partir da cidade selecionada.
  useEffect(() => {
    const selectedStateData = dataStates?.find(state => state.state === stateSelected?.label);
    if (selectedStateData) {
      const selectedCityData = selectedStateData?.cities?.find(city => city.city === citySelected.label);
      const neighborhoods = selectedCityData?.neighborhoods?.map((n) => {
        return {
          label: n,
          value: n,
        }
      })
      const optionsNeighborhood = [{ value: '', label: 'Selecione' }, ...neighborhoods ?? []]
      setOptionNeighborhood(optionsNeighborhood)
    }
  }, [citySelected, dataStates, stateSelected])

  // Renderizando as Datas
  // useEffect(() => {
  //   if (dataStores) {
  //     const datesSet = new Set();
  //     dataStores.forEach((s) => {
  //       const formattedDate = s.start_date.substring(8, 10) + '/' + s.start_date.substring(5, 7) + '/' + s.start_date.substring(0, 4);
  //       datesSet.add(formattedDate);
  //     });

  //     const datesArray = Array.from(datesSet).map((formattedDate) => {
  //       return {
  //         value: formattedDate,
  //         label: formattedDate
  //       };
  //     });
  //     const optionsDate = [{ value: '', label: 'Selecione' }, ...datesArray]
  //     setOptionDate(optionsDate);
  //   }
  // }, [dataStores]);

  // btn carregar mais lojas
  const [page, setPage] = useState(1);
  function handleShowMoreClick() {
    if (dataStores.length !== totalStores)
      setPage(page + 1);
  }

  // Renderizando as lojas
  const [storesLoading, setStoresLoading] = useState(false)
  const [totalStores, setTotalStores] = useState()
  const [prevState, setPrevState] = useState({
    stateSelected,
    citySelected,
    neighborhoodSelected,
    endDateFormatted,
    page
  })
  const [firstLoad, setFirstLoad] = useState(true)
  useEffect(() => {
    async function getStores() {
      const { value: stateValue } = stateSelected
      const { value: city } = citySelected
      const { value: neighborhood } = neighborhoodSelected
      const filtersEqual = prevState.stateSelected === stateSelected &&
        prevState.citySelected === citySelected &&
        prevState.neighborhoodSelected === neighborhoodSelected &&
        prevState.endDateFormatted === endDateFormatted
      if (filtersEqual && prevState.page === page && !firstLoad)
        return
      const nextPage = filtersEqual ? page : 1
      setFirstLoad(false)
      setStoresLoading(true)
      const { data } = await getStoresBravectoExperience(stateValue, city, neighborhood, startDateFormatted, endDateFormatted, nextPage)
      setStoresLoading(false)
      const stores = data?.stores
      const totalStores = data?.totalStoreCount
      if (nextPage > 1) {
        setDataStores([...dataStores, ...stores])
      } else {
        setDataStores(stores)
      }
      setPage(nextPage)
      setPrevState({
        stateSelected,
        citySelected,
        neighborhoodSelected,
        endDateFormatted,
        page: nextPage
      })
      setTotalStores(totalStores)
    }
    getStores();
  }, [stateSelected, citySelected, neighborhoodSelected, endDateFormatted, page, prevState, firstLoad])

  return (
    <div id="BravectoExperience">
      <Header />
      <div className="bg-lp first-bg">
        <img src={bannerDesk} alt="banner-bravecto-experience" className="banner-desk" />
        <img src={bannerMob} alt="banner-bravecto-experience" className="banner-mob" />
      </div>

      <section className="info-container">
        <div className="info-sub-container">
          <div className="video">
            {/* <img src={imgVideo} alt="img-video" /> */}
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ccLV2Llamr4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
          <div className="info-content">
            <h1>INFORMAÇÃO E DIVERSÃO <span>PARA TODA FAMÍLIA</span></h1>
            <p>Conheça uma nova dimensão de aprendizado sobre os parasitas que oferecem risco à saúde do seu filho de 4 patas. Divirta-se em uma experiência de realidade virtual, enquanto aprende como Bravecto® atua no controle de infestações de pulgas.</p>
          </div>
        </div>
      </section>

      <section className="lojas">
        <div className="lojas-sub">
          <div className="title">
            <h1>PARTICIPE DESSA EXPERIÊNCIA</h1>
            <p>Confira a loja mais próxima de você, coloque o óculos de realidade virtual e viva seu #MomentoBravectoExperience.</p>
          </div>
          <div className="filtros-container">
            <div className="todos">
              <h2>Todos</h2>
              <p>{`(Total ${totalStores})`}</p>
            </div>
            <div className="filtros">
              <span className="filter-desk">Filtrar por</span>
              <div className="selects filter-desk">
                <SelectCustom {...states} disabled={storesLoading} />
                <SelectCustom {...city} disabled={storesLoading} />
                <SelectCustom {...neighborhood} disabled={storesLoading} />
                {/* <SelectCustom {...date} disabled={storesLoading} /> */}
                <div className="input-date">
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Data"
                    locale="pt"
                  />
                </div>
              </div>
              <div className="filter-mob"
                style={{
                  width: filtersMobile ? "144px" : "134px",
                  borderRadius: filtersMobile ? "20px" : "50px",
                  textAlign: filtersMobile ? "left" : "center",
                  padding: filtersMobile && "0.5rem 1.8rem 2rem"
                }}
              >
                <span onClick={() => setFiltersMobile(!filtersMobile)}>Filtrar por</span>
                {filtersMobile &&
                  <div className="inputs-mob">
                    <SelectCustom {...states} disabled={storesLoading} />
                    <SelectCustom {...city} disabled={storesLoading} />
                    <SelectCustom {...neighborhood} disabled={storesLoading} />
                    {/* <SelectCustom {...date} disabled={storesLoading} /> */}
                    <div className="input-date input-date-mobile">
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        isClearable={true}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Data"
                        locale="pt"
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="card-container">
        <ul className="card-sub-container">
          {storesLoading ? <Loader /> :
            !dataStores.length && <span>Nenhum resultado encontrado</span>
          }
          {dataStores?.map((store) => {
            return (
              <li key={store.id}>
                <CardStore
                  nome={store.name}
                  startDate={store.start_date.substring(8, 10) + '/' + store.start_date.substring(5, 7)}
                  finishDate={store.finish_date.substring(8, 10) + '/' + store.finish_date.substring(5, 7) + '/' + store.finish_date.substring(0, 4)}
                  startHour={store.start_hour.substring(0, 2) + "h"}
                  finishHour={store.finish_hour.substring(0, 2) + "h"}
                  street={store.address}
                  neighborhood={store.neighborhood}
                  city={store.city}
                  state={store.state}
                  cep={store.cep}
                  maps={store.url_google_maps}
                  waze={store.url_waze}
                />
              </li>
            )
          })}
        </ul>
        {dataStores.length > 0 && dataStores.length !== totalStores &&
          <button type="button" onClick={() => handleShowMoreClick()}>
            Carregar mais resultados
          </button>
        }
      </section>

      <div className="bg-lp">
        <img src={bannerRodapeDesk} alt="banner-bravecto-experience" className="banner-desk" />
        <img src={bannerRodapeMob} alt="banner-bravecto-experience" className="banner-mob" />
      </div>
      <Footer />
    </div >
  );
};

export default BravectoExperience;
