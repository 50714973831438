import axios from "axios";

var url = window.location.href;
// const api = "https://clubebravectoapi.shiftinc.com.br/api/v1/";
const api = //url.includes('localhost') ||
  url.includes("clubebravecto") && !url.includes("shiftinc")
    ? "https://api.clubebravecto.com.br/api/v1/"
    : "https://clubebravectoapi.shiftinc.com.br/api/v1/";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        window.localStorage.removeItem("bravecto-user");
      }
    }
    return Promise.reject(error);
  }
);

export const getPartnersLogo = () => {
  return axios.get(`${api}partner/getLogo`);
};

export const getBanners = () => {
  return axios.get(`${api}banner`);
};

export const getStates = () => {
  return axios.get(`${api}customer/state-list`);
};

export const getCitiesByState = (uf) => {
  return axios({
    method: "post",
    url: `${api}customer/city-list/${uf}`,
    data: {
      uf: uf,
    },
  });
};

export const getDocumentExists = (cpf) => {
  return axios({
    method: "get",
    url: `${api}customer/document-exists/${cpf}`,
  });
};

export const getEmailExists = (email) => {
  return axios({
    method: "get",
    url: `${api}customer/email-exists/${email}`,
  });
};

export const postRegisterUser = (params) => {
  return axios({
    method: "post",
    url: `${api}customer`,
    data: params,
  });
};

export const postRegisterUserV2 = (params) => {
  return axios({
    method: "post",
    url: `${api}customer/createV2`,
    data: params,
  });
};

export const postRegisterUserComplete = (params) => {
  return axios({
    method: "post",
    url: `${api}customer/complete`,
    data: params,
  });
};

export const postRecoveryPassword = (params) => {
  return axios({
    method: "post",
    url: `${api}recoveryPassword`,
    data: params,
  });
};

export const postRegisterVivaraUser = (params) => {
  return axios({
    method: "post",
    url: `${api}customer/vivara`,
    data: params,
  });
};

export const postCompleteRegisterInfluencer = (params) => {
  return axios({
    method: "post",
    url: `${api}influencer/complete-register-influencer`,
    data: params,
  });
};

export const getCustomer = (userId, token) => {
  return axios({
    method: "GET",
    url: `${api}customer/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPetKg = (petId) => {
  return axios({
    method: "GET",
    url: `${api}pet/pet-kg/${petId}`,
  });
};

export const putEditCustomer = (params, token) => {
  return axios({
    method: "PUT",
    url: `${api}customer`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: params,
  });
};

export const putUnregisterCustomer = (userId, token) => {
  return axios({
    method: "PUT",
    url: `${api}customer/unregister/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postForgotPassword = (params) => {
  return axios({
    method: "POST",
    url: `${api}forgotpassword`,
    data: params,
  });
};

export const postLoginUser = (params) => {
  return axios({
    method: "post",
    url: `${api}account`,
    config: {
      headers: { "Content-Type": "multipart/form-data" },
    },
    data: params,
  });
};

export const unclockCodeMatch = (params) => {
  return axios({
    method: "PUT",
    url: `${api}customer/unlockUser/` + params,
    config: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
};

export const postFirstAccessInfluencer = (params) => {
  return axios({
    method: "post",
    url: `${api}influencer/registerPassword`,
    data: params,
  });
};

export const postProductsFiltered = (params) => {
  return axios({
    method: "POST",
    url: `${api}product/products-filtered`,
    data: params,
  });
};

export const getProductById = (id) => {
  return axios({
    method: "GET",
    url: `${api}product/details/${id}`,
  });
};

export const getAllPetsByCustomer = (customerId) => {
  return axios({
    method: "GET",
    url: `${api}pet/customers-pet/${customerId}`,
  });
};

export const getPetBreeds = (petType) => {
  return axios({
    method: "GET",
    url: `${api}pet/pet-breeds/${petType}`,
  });
};

export const postStoresByType = (type) => {
  return axios({
    method: "GET",
    url: `${api}store/${type}`,
  });
};

export const postRegisterPet = (params, token) => {
  return axios({
    method: "POST",
    url: `${api}pet`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: params,
  });
};

export const postChangePassword = (params, token) => {
  return axios({
    method: "PUT",
    url: `${api}customer/change-password`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: params,
  });
};

export const putEditPet = (params, token) => {
  return axios({
    method: "PUT",
    url: `${api}pet`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: params,
  });
};

export const getPet = (petId, token) => {
  return axios({
    method: "GET",
    url: `${api}pet/${petId}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putDeletePet = (petId, token) => {
  return axios({
    method: "PUT",
    url: `${api}pet/pet-deactivation/${petId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBravectoProducts = () => {
  return axios({
    method: "GET",
    url: `${api}coupon/bravecto-product`,
  });
};

export const getTips = () => {
  return axios({
    method: "GET",
    url: `${api}tip`,
  });
};

export const getTipByTitle = (title) => {
  return axios({
    method: "GET",
    url: `${api}tip/byTittle/${title}`,
  });
};

export const getTipsFiltered = (page, petType, categoryId, name) => {
  return axios({
    method: "GET",
    url: `${api}tip/filtered/${page}/${petType}/${categoryId}/${name}`,
  });
};

export const getTipsCategories = () => {
  return axios({
    method: "GET",
    url: `${api}tip/categories`,
  });
};

export const getTipById = (tipId) => {
  return axios({
    method: "GET",
    url: `${api}tip/${tipId}`,
  });
};

export const getSuggestions = (tipId) => {
  return axios({
    method: "GET",
    url: `${api}tip/suggestions/${tipId}`,
  });
};

export const postStoreById = (params, token) => {
  return axios({
    method: "POST",
    url: `${api}store/store-cnpj`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: params,
  });
};

export const postRegisterReminder = (params, token) => {
  return axios({
    method: "POST",
    url: `${api}pet/register-reminder`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: params,
  });
};

export const getUserCoupons = (userId, token) => {
  return axios({
    method: "GET",
    url: `${api}coupon/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postRegisterCoupon = (params, token) => {
  return axios({
    method: "POST",
    url: `${api}coupon`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: params,
  });
};

export const getStoreStates = () => {
  return axios.get(`${api}store/stateslist-store`);
};

export const getStoreCitiesByState = (stateId) => {
  return axios.get(`${api}store/citieslist-store/${stateId}`);
};

export const getStoreNeighborhoods = (cityId) => {
  return axios.get(`${api}store/neighborhoodslist-store/${cityId}`);
};

export const storesByState = (stateId) => {
  return axios.get(`${api}store/storebystate/${stateId}`);
};

export const storesByCity = (cityId) => {
  return axios.get(`${api}store/storebycity/${cityId}`);
};

export const storesByNeighborhood = (neighborhoodId) => {
  return axios.get(`${api}store/storebyneighborhood/${neighborhoodId}`);
};

export const getRanking = () => {
  return axios.get(`${api}rankingAddressEvent/getRanking?filter=`);
};

export const getDownloadRankingLink = () => {
  return `${api}rankingAddressEvent/exportRankingFile`;
};

export const getDownloadRankingGroupByCepLink = () => {
  return `${api}rankingAddressEvent/exportRankingGroupCep`;
};

export const searchRanking = (name) => {
  return axios.get(`${api}store/ranking?search=${name}`);
};

export const getStars = (token) => {
  return axios({
    method: "GET",
    url: `${api}score/user/info`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getA11yLinksHtml = () => {
  return axios({
    method: "GET",
    url: `/a11y-links.html`,
  });
};

export const getStatesBravectoExperience = () => {
  return axios({
    method: "GET",
    url: `${api}clubevrstore/locations/getall`,
  });
};

export const getStoresBravectoExperience = (
  state,
  city,
  neighborhood,
  dateStart,
  dateFinish,
  page
) => {
  const params = new URLSearchParams(
    Object.fromEntries(
      Object.entries({
        state,
        city,
        neighborhood,
        dateStart,
        dateFinish,
        page,
      }).filter(([k, v]) => Boolean(v))
    )
  );
  return axios({
    method: "GET",
    url: `${api}clubevrstore/getall?${params}`,
  });
};

export const putChangeEmail = (params) => {
  return axios({
    method: "PUT",
    url: `${api}customer/change-email`,
    data: params,
  });
};
