import React, { useState, useEffect } from "react";

import Connect from "../../store/config/connect";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Mobile from "./Layout/Mobile";
import Desktop from "./Layout/Desktop";
import Loader from "../Loader/Loader";

import ComprarOnline from "./ComprarOnline/ComprarOnline";
import ComprarFisica from "./ComprarFisica/ComprarFisica";

import "./DescontoEmBravecto.css";

const DescontoEmBravecto = (props) => {
  document.title = "Clube Bravecto | Desconto em Bravecto";

  const [isMobile, setIsMobile] = useState(props.isMobile);
  useEffect(() => {
    setIsMobile(props.isMobile);
  }, [props.isMobile]);

  const [user, setUser] = useState({});
  useEffect(() => {
    setUser(props.user);
  }, [props]);

  const [bravectoDiscount, setBravectoDiscount] = useState(false);
  useEffect(() => {
    if (user.hasOwnProperty("discounts")) {
      setBravectoDiscount(props.user.discounts);
    }
  }, [user, props.user]);

  const [comprarOnlineStatus, setComprarOnlineStatus] = useState(false);
  const [hideOptions, setHideOptions] = useState(false);
  function handleHideOptions(arg) {
    return setHideOptions(arg);
  }

  function handleComprarOnlineStatus(arg) {
    return setComprarOnlineStatus(arg);
  }

  return (
    <div id="DescontoEmBravecto">
      <Header />
      <div id="DescontoEmBravecto__container">
        {Object.keys(bravectoDiscount).length > 0 ? (
          isMobile ? (
            <Mobile bravectoDiscount={bravectoDiscount} />
          ) : (
            <Desktop bravectoDiscount={bravectoDiscount} />
          )
        ) : (
          <Loader />
        )}
        {!hideOptions ? (
          <div id="DescontoEmBravecto__options">
            <div id="option">
              <figure id="optionIconFigure">
                <img
                  alt="Comprar online"
                  id="optionIconImage"
                  src={require("../../images/onde-comprar-shop-online.svg")}
                />
              </figure>
              <button
                id="optionButton"
                onClick={() => {
                  handleHideOptions(true);
                  handleComprarOnlineStatus(true);
                }}
              >
                Comprar online
              </button>
            </div>
            <div id="option">
              <figure id="optionIconFigure">
                <img
                  alt="Comprar em loja física"
                  id="optionIconImage"
                  src={require("../../images/onde-comprar-shop.svg")}
                />
              </figure>
              <button
                id="optionButton"
                onClick={() => {
                  handleHideOptions(true);
                  handleComprarOnlineStatus(false);
                }}
              >
                Comprar em
                <br /> loja física
              </button>
            </div>
          </div>
        ) : comprarOnlineStatus ? (
          <ComprarOnline handleHideOptions={handleHideOptions} />
        ) : (
          <ComprarFisica handleHideOptions={handleHideOptions} />
        )}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth, helper }) => {
  return {
    ...auth,
    ...helper,
  };
};

export default Connect(mapStateToProps)(DescontoEmBravecto);
