import React from "react";
import IconClaw from "../../images/SurePetCare/icons/icon-claw.png";
import IconClock from "../../images/SurePetCare/icons/icon-clock.png";
import IconDogRunning from "../../images/SurePetCare/icons/icon-dog-running.png";
import IconGraphics from "../../images/SurePetCare/icons/icon-graphics.png";
import IconInsights2 from "../../images/SurePetCare/icons/icon-insights-2.png";
import IconInsights from "../../images/SurePetCare/icons/icon-insights.png";
import IconLock from "../../images/SurePetCare/icons/icon-lock.png";
import IconPortion from "../../images/SurePetCare/icons/icon-portion.png";
import IconRing from "../../images/SurePetCare/icons/icon-ring.png";
import IconSleeping from "../../images/SurePetCare/icons/icon-sleeping.png";
import IconUpdate from "../../images/SurePetCare/icons/icon-update.png";
import IconWarning from "../../images/SurePetCare/icons/icon-warning.png";
import PetsFeeding from "../../images/SurePetCare/pets/pets-feeding.png";
import PetsUsingDoor from "../../images/SurePetCare/pets/pets-using-door.png";
import PetsWithAnimo from "../../images/SurePetCare/pets/pets-with-animo.png";
import Animo from "../../images/SurePetCare/products/animo.png";
import Feeder from "../../images/SurePetCare/products/feeder.png";
import SureFlap from "../../images/SurePetCare/products/sure-flap.png";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./SurePetCare.scss";

const SurePetCare = () => {
  document.title = "Clube Bravecto | Sure Pet Care";

  const link = "https://www.cobasi.com.br/pesquisa?hotsite=sure-petcare";

  return (
    <div id="SurePetCare">
      <Header hideLoginButton />

      <section className="SurePetCare--container">
        <h1>Petnologia</h1>

        <h2>
          A <strong>Petnologia Sure Petcare</strong> é uma série de produtos
          inovadores que ajudam seus pets a falarem com você.
        </h2>

        <div className="subtitle">
          <img src={IconClaw} alt="Imagem de um pata no centro de um circulo" />
          <p>
            Abaixo, conheça a nossa linha e como eles conectam ainda mais você
            aos seus pets.
          </p>
        </div>

        <article className="product">
          <h3>
            Sure Feed<span>&reg;</span>
          </h3>

          <p className="break-on-strong">
            <strong>Alimentação personalizada</strong> para seu pet viver uma{" "}
            <strong>vida mais saudável.</strong>
          </p>

          <div className="product-image">
            <img
              src={Feeder}
              alt="Alimentador de animais da linha Sure Pet Care"
            />

            <span>
              Sure Petcare: Petnologia <br />
              que fala com você.
            </span>
          </div>

          <a href={link} target="_blank" rel="noopener noreferrer">
            Compre aqui
          </a>
        </article>

        <article className="product-details">
          <h4>Comedouro inteligente para uma alimentação personalizada.</h4>

          <p>
            A <strong>linha Sure Feed</strong> mantém os alimentos mais frescos
            por mais tempo, além de controlar a alimentação dos pets. O{" "}
            <strong> Microchip Pet Feeder</strong> funciona através de um
            microchip conectado com o pet, e quando ele se aproximar do
            comedouro, faz com que a tampa abra e libere o acesso ao alimento.
            Pet Feeder é ideal para casas com vários animais de estimação para
            prevenir comer em excesso e controlar os alimentos diferenciados dos
            demais. Além de saber com que frequência e quanto seu pet come.
          </p>

          <dl>
            <dt>
              <img
                src={IconPortion}
                alt="Pacote de ração com uma pata no centro."
              />
              <div>
                <h5>Porção Precisa</h5>
                <p>
                  Defina o tipo de alimento e quantidade de cada porção usando o
                  aplicativo Sure Petcare além de pesar com precisão os
                  alimentos usando os guias de LED
                </p>
              </div>
            </dt>

            <dt>
              <img
                src={IconInsights}
                alt="Grafico em zigue-zague com circulos em suas pontas."
              />
              <div>
                <h5>Insights</h5>
                <p>
                  Monitore quanto, com que frequência e quando seu pet come.
                  Observe as mudanças ao longo do tempo, que pode indicar
                  alterações na saúde do seu pet
                </p>
              </div>
            </dt>

            <dt>
              <img
                src={IconUpdate}
                alt="Silhueta de um gato em frente a um alimentador."
              />
              <div>
                <h5>Atualizações de alimentação</h5>
                <p>
                  Veriﬁque quando seu pet foi alimentado pela última vez e
                  quanto é deixado na tigela deles
                </p>
              </div>
            </dt>
          </dl>

          <img
            src={PetsFeeding}
            alt="Cachorros e gato comendo no alimentador Sure Feed."
          />

          <a href={link} target="_blank" rel="noopener noreferrer">
            Compre aqui
          </a>
        </article>

        <article className="product">
          <h3>
            Animo<span>&reg;</span>
          </h3>

          <p>
            O Animo é um dispositivo que você coloca na coleira, projetado para
            ser um <strong>complemento dos cuidados diários do seu cão.</strong>
          </p>

          <div className="product-image">
            <img
              src={Animo}
              alt="Dispositivo Animo da linha Sure Pet Care, redondo e branco com detalhes em verde-azulado, um circulo na frente do dispositívo cinza e uma pata no topo do dispositivo verde-azulado claro."
            />

            <span>
              Sure Petcare: Petnologia <br />
              que fala com você.
            </span>
          </div>

          <a href={link} target="_blank" rel="noopener noreferrer">
            Compre aqui
          </a>
        </article>

        <article className="product-details no-title">
          <p>
            Ele aprende e interpreta os padrões de comportamento únicos do seu
            cão,{" "}
            <strong>
              permitindo que você identifique mudanças significativas,
            </strong>{" "}
            que podem ser uma indicação de doença ou algum incomodo. As
            necessidades de um cão mudam com a idade, então, graças a um
            algoritmo adaptativo,{" "}
            <strong>
              o Animo se ajusta às necessidades do seu cão conforme ele
              envelhece.
            </strong>{" "}
            Quando ocorrerem mudanças significativas no comportamento do seu
            cão, como aumento de latidos, coçar ou sacudir, o Animo enviará um
            alerta, permitindo que você investigue as possíveis causas assim que
            o problema ocorrer. À noite, é provável que você não saiba se seu
            cão tem um sono agitado ou não, porque você também dorme.{" "}
            <strong>
              O Animo monitora a qualidade do sono do seu cão criando pontuação
              de sono noturno para ajudá-lo a detectar mudanças nos padrões de
              sono dele.
            </strong>
          </p>

          <dl>
            <dt>
              <img src={IconDogRunning} alt="Silhueta cachorro correndo." />
              <div>
                <p>
                  Defina uma meta mínima de queima de calorias ou meta de
                  atividades
                </p>
              </div>
            </dt>

            <dt>
              <img src={IconWarning} alt="Sinal de alerta." />
              <div>
                <p>
                  Saiba o quanto seu cão late, coça ou sacode mostrando uma
                  mensagem quando isso for diferente da média diária
                </p>
              </div>
            </dt>

            <dt>
              <img
                src={IconInsights2}
                alt="Grafico em zigue-zague com circulos em suas pontas."
              />
              <div>
                <p>
                  Os dados são de leitura fácil através de gráficos para melhor
                  compreender o comportamento do seu cão
                </p>
              </div>
            </dt>

            <dt>
              <img src={IconSleeping} alt="Vários 'z's indicando dormir." />
              <div>
                <p>
                  Aviso quando houver uma mudança no padrão de sono, isso pode
                  ser um sinal de desconforto ou estresse
                </p>
              </div>
            </dt>
          </dl>

          <img
            src={PetsWithAnimo}
            alt="Cachorro e gato usando o dispositívo Animo na coleira."
          />

          <a href={link} target="_blank" rel="noopener noreferrer">
            Compre aqui
          </a>
        </article>

        <article className="product">
          <h3>
            Sure Flap<span>&reg;</span>
          </h3>

          <p>
            De ao seu gato <strong>acesso exclusivo</strong> à sua casa e tenha
            controle de <strong>quem</strong> pode acessá-la.
          </p>

          <div className="product-image">
            <img
              src={SureFlap}
              alt="Dispositivo Sure Flap da linha Sure Pet Care, quadrado e branco com cantos aredondados com uma pequena tela em cima com setas indicando a direção pra dentro e para fora."
            />

            <span>
              Sure Petcare: Petnologia <br />
              que fala com você.
            </span>
          </div>

          <a href={link} target="_blank" rel="noopener noreferrer">
            Compre aqui
          </a>
        </article>

        <article className="product-details no-description">
          <h4>Porta eletrônica e inteligente</h4>

          <dl>
            <dt>
              <img src={IconRing} alt="Silhueta de um Sino." />
              <div>
                <h5>Tranquilidade</h5>
                <p>Receba notificações quando o seu pet entra ou sai de casa</p>
              </div>
            </dt>

            <dt>
              <img src={IconLock} alt="Silhueta de um cadeado." />
              <div>
                <h5>Bloquear e desbloquear</h5>
                <p>
                  Controle remotamente a porta do seu pet a qualquer hora, em
                  qualquer lugar pelo aplicativo Sure Petcare
                </p>
              </div>
            </dt>

            <dt>
              <img
                src={IconGraphics}
                alt="Três retângulos na vertical de diferentes tamanhos indicando serem graficos."
              />
              <div>
                <h5>Insights</h5>
                <p>
                  Monitore as atividades e avisos do seu pet como mudanças de
                  comportamento
                </p>
              </div>
            </dt>

            <dt>
              <img src={IconClock} alt="Silhueta de um relógio." />
              <div>
                <h5>Crie um toque de recolher</h5>
                <p>
                  deﬁna um horário para quando seu pet tenha permissão para sair
                </p>
              </div>
            </dt>
          </dl>

          <img
            src={PetsUsingDoor}
            alt="Cachorro e gato usando o porta Sure Flap."
          />

          <a href={link} target="_blank" rel="noopener noreferrer">
            Compre aqui
          </a>
        </article>
      </section>

      <Footer />
    </div>
  );
};

export default SurePetCare;
