import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { NewReminder } from "../../store/helper";
import Connect from "../../store/config/connect";

import {
  getAllPetsByCustomer,
  getPet,
  postRegisterReminder,
} from "../../services/services";

import SelectCustom from "../Utils/Select/Select";
import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";

import Loader from "../Loader/Loader";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import bravectoLogo from '../../images/bravecto-logo.svg';
import calendarLembrete from '../../images/calendar-lembrete.svg';

import "./CadastrarLembrete.css";

const CadastrarLembrete = (props) => {
  document.title = "Clube Bravecto | Cadastrar Lembrete";

  const [petsLoaded, setPetsLoaded] = useState(false);
  const [lastApplicationLoading, setLastApplicationLoading] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null);
  const [selectPets, setSelectPets] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentLastApplication, setCurrentLastApplication] = useState(null);
  const [inputLastApplicationValue, setInputLastApplicationValue] =
    useState(null);

  const [lastAppError, setLasAppError] = useState(false);
  const [lastSubmitError, setLastSubmitError] = useState(false);
  const [inputLastApplication] = useState({
    type: "text",
    name: "birthday",
    maxLength: 10,
    replaceValueOnChange: true,
    placeholder: "dd/mm/aaaa",
    onChange: function (lastApp) {
      if (lastApp.length === 10) {
        let appDate = moment(lastApp, "DD/MM/YYYY");
        let nextDate = appDate.isValid()
          ? moment(appDate)
            .add(12 * 7, "days")
            .format("DD/MM/YYYY")
          : null;
        setCurrentLastApplication(nextDate);
        setLasAppError(!nextDate);
      } else {
        setLasAppError(false);
      }
      lastApp = lastApp.length > 0 && lastApp.slice(0, 2) > 31 ? 31 : lastApp;
      lastApp =
        lastApp.length > 3 && lastApp.slice(3, 5) > 12
          ? lastApp.slice(0, 3) + 12
          : lastApp;
      let v = lastApp
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2");
      setInputLastApplicationValue(v);
      return v;
    },
    onBlur: function (e) {
      const lastApp = e.target.value.split("/").reverse().join("-"),
        today = moment();
      if (moment(lastApp).isAfter(today, "days")) {
        setLasAppError(true);
        setInputLastApplicationValue(false);
      } else {
        setLasAppError(false);
      }
    },
  });

  useEffect(() => {
    if (selectedPet) {
      setLastApplicationLoading(true);
      getPet(selectedPet.value, props.user.token)
        .then((res) => {
          if (res && res.status === 200) {
            const petResponse = JSON.parse(res.request.response);
            let remDate = moment(petResponse.reminderDate);
            if (!remDate.isValid()) {
              remDate = moment(petResponse.reminderDate, "MMM D YYYY h:mmA");
            }
            setCurrentLastApplication(
              petResponse.reminderDate
                ? remDate.format("DD/MM/YYYY")
                : "Não há nenhuma data cadastrada para este pet!"
            );
            setLastApplicationLoading(false);
          }
        })
        .catch((e) => {
          setCurrentLastApplication(
            "Ocorreu um erro ao carregar a última aplicação do pet, selecione outro pet novamente."
          );
          setLastApplicationLoading(false);
        });
    }
  }, [selectedPet, props.user.token]);

  const [pets, setPets] = useState([]);
  useEffect(() => {
    if (props.user.id) {
      let petToEdit =
        props.match.params.petId === "0" ? null : props.match.params.petId;
      getAllPetsByCustomer(props.user.id)
        .then((res) => {
          if (res && res.status === 200) {
            let petsOptions = [];
            let petsResponse = JSON.parse(res.request.response);
            petsResponse.map((i) => {
              petToEdit =
                petToEdit && petToEdit === i.id
                  ? { label: i.name, value: i.id }
                  : petToEdit;
              return petsOptions.push({
                label: i.name,
                value: i.id,
              });
            });
            setSelectedPet(petToEdit);
            setPets(petsOptions);
            setPetsLoaded(true);
          } else {
            setPetsLoaded(false);
            setPets([]);
          }
        })
        .catch((e) => {
          setPets([]);
          console.log(e.response);
        });
    }
  }, [props]);

  useEffect(() => {
    const petSelectOptions = {
      placeholder: "Selecione o pet",
      options: pets,
      isSearchable: false,
      value: null,
      noOptionsMessage: function () {
        return "Procure novamente!";
      },
      onChange: function (value) {
        setSelectedPet(value);
      },
    };

    if (pets.length === 1) {
      setSelectedPet(pets[0])
    }
    pets.forEach((item) => {
      if (item.value === props.match.params.petId) {
        setSelectedPet(item);
      }
    })
    if (pets && selectedPet) {
      petSelectOptions.value = selectedPet;
    }

    setPetsLoaded(false);
    setSelectPets(petSelectOptions);
    setPetsLoaded(true);
  }, [pets, selectedPet]);

  const [selectedPetError, setSelectedPetError] = useState(null);
  function handleSubmit(e) {
    e.preventDefault();
    if (inputLastApplicationValue && selectedPet) {
      setIsSubmitting(true);
      setLastSubmitError(false);
      let body = {
        id: selectedPet.value,
        name: selectedPet.label,
        reminderDate: inputLastApplicationValue.split("/").reverse().join("-"),
        customerId: props.user.id,
      };

      postRegisterReminder(body, props.user.token)
        .then((res) => {
          if (res && res.status === 200) {
            props.dispatch(NewReminder(true));
            props.history.push("/meus-pets");
          } else {
            console.log(res);
            setLastSubmitError(true);
          }
        })
        .catch((e) => {
          console.log(e);
          setLastSubmitError(true);
        });
    } else {
      setSelectedPetError(selectedPet ? false : true);
      setLasAppError(inputLastApplicationValue ? false : true);
    }
  }

  return (
    <div id="CadastrarLembrete">
      <div id="skip">
        <a href="#CadastrarLembrete__container">
          Pular para o conteúdo principal da página
        </a>
      </div>
      <h1 className="sr-only">
        Cadastrar Lembrete - Clube de Vantagens Bravecto
      </h1>
      <Header />
      {pets && pets.length > 0 ? (
        <div id="CadastrarLembrete__container">
          <div id="CadastrarLembrete--title">
            <span>Lembrete</span>
          </div>
          <div id="form-lembrete">
            <form onSubmit={handleSubmit}>
              <div id="CadastrarLembrete--desc">
                <img src={calendarLembrete} alt="icon-calendar" />
                <span>
                  Cadastre a <b>última data de aplicação de<br />Bravecto</b> em seu pet e conte conosco para<br />
                  lembrar qual a data da <b>próxima dosagem.</b>
                </span>
              </div>
              <hr />
              <div id="row">
                <div id="content">
                  <span>Selecione seu Pet:</span>
                </div>
                {petsLoaded ? (
                  <SelectCustom {...selectPets} />
                ) : (
                  <div id="CadastrarLembrete--preLoader">
                    <Loader />
                  </div>
                )}
                <div
                  id="error__field"
                  style={{ display: selectedPetError ? "flex" : "none" }}
                >
                  <span>Selecione um pet</span>
                </div>
              </div>
              <div id="row">
                <div id="content">
                  <span>Última aplicação de: <img src={bravectoLogo} alt="logo-bravecto" width="92px" /></span>
                </div>
                <Input {...inputLastApplication} />
                <div
                  id="error__field"
                  style={{ display: lastAppError ? "flex" : "none" }}
                >
                  <span>Data inválida</span>
                </div>
              </div>
              {!lastAppError && (
                <div id="row">
                  <div id="content">
                    <span id="lastApplicationn">A próxima dosagem será:</span>
                  </div>
                  <div id={currentLastApplication === null ? `lastApplicationn` : `lastApplication`}>
                    {lastApplicationLoading && (
                      <div id="CadastrarLembrete--preLoader">
                        <Loader />
                      </div>
                    )}
                    {currentLastApplication && (
                      <React.Fragment>
                        <span>{currentLastApplication}</span>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
              <div id="row">
                <div
                  id="error__field"
                  style={{ display: lastSubmitError ? "flex" : "none" }}
                >
                  <span>
                    Desculpe, houve um erro ao cadastrar o lembrete.
                    <br />
                    Por favor tente novamente mais tarde.
                  </span>
                </div>
              </div>
            </form>
            <div id="btn-submit">
              <Link to="/meus-pets" id="btn-voltar"></Link>
              <button type="submit" id="btn-salvar" onClick={(ev) => handleSubmit(ev)}
                disabled={isSubmitting}>
                {isSubmitting ? <LoaderForButton /> : <span>Salvar</span>}
              </button>
            </div>
          </div >
        </div >
      ) : (
        <div id="CadastrarLembrete__container">
          <div id="CadastrarLembrete--title">
            <span>Cadastrar Lembrete</span>
          </div>
          <div id="where-is-my-pets">
            <div id="no-pets">
              <span>Nenhum Pet cadastrado</span>
            </div>
            <div id="register-your-pet">
              <span>
                Para cadastrar um lembrete de Bravecto, você precisa primeiro{" "}
                <br /> cadastrar um pet.
              </span>
            </div>
            <div id="new-pet">
              <Link to="cadastrar-pet">
                <button type="button">Cadastrar novo pet</button>
              </Link>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div >
  );
};

const mapStateToProps = ({ auth, helper }, props) => {
  return {
    ...auth,
    ...helper,
    ...props,
  };
};

export default Connect(mapStateToProps)(CadastrarLembrete);
