import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { MdCheck, MdArrowDropUp } from "react-icons/md";
// import { Link } from "react-router-dom";
// import { MdClose } from "react-icons/md";

import Connect from "../../../store/config/connect";
// import { LogInUser } from "../../../store/auth";

import { postCompleteRegisterInfluencer } from "../../../services/services";

import LoaderForButton from "../../Utils/LoaderForButtons/LoaderForButtons";

import "./TermosUso.css";

const TermosUso = (props) => {
  document.title = "Clube Bravecto | Termos de Uso";

  const [isSubmitting, setSubmitting] = useState(false);
  const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(null);
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [hasRequestErrors, setHasRequestErrors] = useState(false);

  const history = useHistory();

  function formValidation() {
    let formErrors = {};

    var url_string = window.location.href;
    var url = new URL(url_string);
    var hashCodeArray = url.searchParams.get("code");
    var hashCode = hashCodeArray.split(" ");
    hashCode = hashCode[0];

    const fields = {
      termOfUse: acceptTermsOfUse,
      hashCode: hashCode,
    };

    if (formErrors.termOfUse) {
      setAcceptTermsOfUse(false);
    }

    if (Object.keys(formErrors).length > 0) {
      setHasFormErrors(formErrors);
      window.scrollTo(0, 0);
      setSubmitting(false);
      return false;
    } else {
      return fields;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setHasFormErrors(false);
    setHasRequestErrors(false);
    setSubmitting(true);

    const fields = formValidation();

    if (fields && fields.termOfUse) {
      postCompleteRegisterInfluencer(fields)
        .then((res) => {
          if (res && res.status === 200) {
            props.history.push("/clube-de-vantagens");
          }
        })
        .catch((e) => {
          console.log(e.response);
          setSubmitting(false);
          history.push("/");
        });
    } else {
      //setHasRequestErrors(error);
      setSubmitting(false);
    }
  }

  return (
    <div id="container__termosUso">
      <div id="header__container"></div>

      <div id="Termos">
        <div id="Termos__container">
          <h3>1. Bem vindo ao SITE.</h3>

          <p>
            Este SITE é mantido pela empresa <strong>MSD SAÚDE ANIMAL</strong>,
            pessoa jurídica de direito privado, inscrita no CNPJ sob o n° CNPJ
            07.954.091/0001-43 proprietária da marca Bravecto®, doravante
            denominada apenas como <strong>MSD SAÚDE ANIMAL</strong> ou
            MANTENEDORA deste site.
          </p>

          <p>
            O SITE será disponível a todas as pessoas físicas, com idade igual
            ou superior a 18 (dezoito) anos, interessadas em acessar o conteúdo
            deste SITE.
          </p>

          <p>
            Para tornar a comunicação mais clara nestes TERMOS, algumas
            referências foram padronizadas, e sempre que houver referência neste
            documento em caixa alta dos itens a seguir, o significado será este:
          </p>

          <p>
            SITE – corresponde ao website desenvolvido a disponibilização de
            conteúdo para a marca Bravecto® e MSD SAÚDE ANIMAL acessível pela
            internet, onde serão disponibilizados SERVIÇOS, informações e
            facilidades oferecidas ao USUÁRIO;
          </p>

          <p>
            MSD SAÚDE ANIMAL - corresponde à empresa MSD Saúde Animal, promotora
            e mantenedora da SITE e proprietária das marcas aqui dispostas;
          </p>

          <p>
            USUÁRIO – pessoa que utiliza os serviços disponibilizados no SITE;
          </p>

          <p>
            TERMOS – Termos de Uso dos Serviços do SITE previstos neste
            documento;
          </p>

          <p>
            SERVIÇOS – ferramentas, softwares, facilidades, e recursos
            disponibilizados no SITE.
          </p>

          <h3>2. Serviços.</h3>

          <p>
            O USUÁRIO terá direito a usufruir dos SERVIÇOS disponibilizados
            desde que aceite expressamente o conteúdo destes TERMOS, dentro do
            SITE.
          </p>

          <p>
            O USUÁRIO não poderá usar os SERVIÇOS nem aceitar os TERMOS se for
            uma pessoa com restrições, quanto ao recebimento de serviços,
            impostas pelas leis do Brasil ou de outros países, incluindo o país
            onde o USUÁRIO é residente ou a partir do qual usa os serviços.
          </p>

          <p>
            Antes de continuar, é aconselhável que o USUÁRIO imprima ou guarde
            uma cópia local destes TERMOS.
          </p>

          <h3>3. Propriedade e Conteúdo</h3>

          <p>
            As imagens, marcas, textos e quaisquer outros itens gráficos ou não
            deste SITE, o que chamamos aqui de CONTEÚDO, pertencem única e
            exclusivamente à MSD SAÚDE ANIMAL, que detém, direta ou
            indiretamente, todos os direitos autorais e direitos reservados
            necessários sobre os mesmos.
          </p>

          <p>
            O USUÁRIO não poderá (nem poderá permitir a mais ninguém) copiar,
            modificar, criar uma obra derivada de realizar engenharia inversa,
            decompilar ou, de qualquer outro modo, tentar extrair o código-fonte
            dos softwares ou de qualquer parte que componha os SERVIÇOS ou
            qualquer conteúdo contido no SITE.
          </p>

          <p>
            É estritamente proibido o uso do CONTEÚDO, exceto como previsto
            nestes Termos de Uso, sem a permissão por escrito de seu
            proprietário. Ainda, a MSD SAÚDE ANIMAL, tomará medidas enérgicas
            para garantir seus direitos de propriedade intelectual na extensão
            máxima permitida pela legislação aplicável, inclusive por meio de
            processo criminal.
          </p>

          <p>
            Este SITE está disponível apenas para utilização com acesso ao
            CONTEÚDO do SITE e também para envio ou agregação dos CONTEÚDOS por
            parte dos USUÁRIOS, motivo pelo qual a MSD SAÚDE ANIMAL deixa
            expressamente proibido as seguintes ações por parte do usuário:
          </p>

          <div className="letter-styled">
            <ul>
              <li>
                a) Declarar ou representar falsamente sua afiliação a qualquer
                pessoa ou entidade associada a este SITE; ou declarar ou fazer
                crer que a MSD SAÚDE ANIMAL endossa as declarações feitas por
                você;
              </li>

              <li>
                b) Usar o SITE para publicidade ou qualquer outra solicitação
                comercial, sem a expressa autorização da MSD SAÚDE ANIMAL;
              </li>

              <li>
                c) Usar o SITE para publicar ou transmitir vírus, worm, Cavalo
                de Tróia, easter egg, time bomb, spyware ou qualquer outro
                código, arquivo ou programa de computador que seja prejudicial
                ou invasivo ou cuja intenção seja danificar ou tomar controle da
                operação, ou monitorar o uso de qualquer hardware, software ou
                equipamento;
              </li>

              <li>
                d) Usar o SITE para violar os direitos legais de terceiros,
                sejam eles quais forem, para obter ou coletar informações de
                identificação pessoal dos usuários do SITE;
              </li>

              <li>
                e) Interferir ou interromper na operação do SITE ou dos
                servidores, ou de violar quaisquer requisitos, procedimentos,
                políticas ou regulamentações de quaisquer redes que sirvam para
                dar suporte ao SITE ou que sirvam como forma de divulgação
                deste;
              </li>

              <li>
                f) Modificar, adaptar, converter, reverter a engenharia,
                descompilar ou desmontar qualquer parte do SITE.
              </li>
            </ul>
          </div>

          <p>
            Os conteúdos e informações disponibilizadas pelos usuários através
            deste SITE seguirão às normas dispostas aqui, na Política de
            Privacidade deste SITE, assim como quaisquer normas e leis vigentes
            pertinentes no Brasil.
          </p>

          <p>
            Nenhuma responsabilidade, de nenhuma espécie, seja ela de
            acessibilidade, veracidade, legalidade do conteúdo ou outra
            qualquer, poderá ser imputada a MSD SAÚDE ANIMAL, sempre que o
            USUÁRIO, por meio do SITE, adentrar em sites ou links de
            propriedade, administração e/ou controle de terceiros, tais como
            patrocinadores, parceiros, prestadores de serviços em geral
            relacionados aos eventos informados pelos USUÁRIOS.
          </p>

          <h3>4. Cessões, licenças e autorizações de direitos do USUÁRIO</h3>

          <p>
            O USUÁRIO cede para MSD SAÚDE ANIMAL toda a informações e dados
            enviadas ao site, bem como, constante no seu cadastro. O USUÁRIO
            garante que possui os direitos, poderes e autoridade necessários
            para outorgar as licenças, cessões e autorizações previstas nestes
            TERMOS.
          </p>

          <p>
            No momento de seu cadastro, o USUÁRIO poderá autorizar o recebimento
            de conteúdo a ser enviado pela MSD SAÚDE ANIMAL via e-mail e/ou SMS,
            o qual poderá ser suspenso pelo USUÁRIO a qualquer momento, mediante
            os canais disponibilizados no próprio conteúdo enviado, bem como,
            durante sua navegação pelo Site.
          </p>

          <p>
            Será enviado um e-mail informativo da criação do cadastro para o
            Usuário.
          </p>

          <p>
            Caso o Usuário deseje, poderá o mesmo cancelar sua conta e cadastro
            a qualquer momento, por intermédio do Site, o qual disparará um
            e-mail informativo ao Usuário confirmando o requerimento e
            cancelamento de sua conta e cadastro.
          </p>

          <h3>5. Exclusão de garantias e responsabilidade</h3>

          <p>
            Nada nestes TERMOS excluirá, ou limitará, o direito da MSD SAÚDE
            ANIMAL ser ressarcida por perdas ou danos causados pelo USUÁRIO,
            inclusive contra terceiros.
          </p>

          <p>
            O USUÁRIO entende, e concorda expressamente, que o uso dos SERVIÇOS
            se dá por sua conta e risco, e que os SERVIÇOS são fornecidos “na
            forma em que se encontram” e “de acordo com a disponibilidade”.
          </p>

          <h3>6. Término da relação do USUÁRIO com MSD SAÚDE ANIMAL</h3>

          <p>
            Os TERMOS continuarão a ser aplicáveis até serem encerrados pelo
            USUÁRIO ou por MSD SAÚDE ANIMAL, conforme estabelecido a seguir,
            dentre outras situações.
          </p>

          <p>
            Se o USUÁRIO quiser encerrar a relação existente com MSD SAÚDE
            ANIMAL em virtude destes TERMOS, pode fazê-lo desvinculando-se do
            SITE.
          </p>

          <p>
            A MSD SAÚDE ANIMAL pode, a qualquer momento, encerrar a relação
            existente com o USUÁRIO decorrente destes TERMOS nas seguintes
            hipóteses:
          </p>

          <p>
            (A) o USUÁRIO tiver violado qualquer cláusula dos TERMOS (ou tiver
            agido de forma que claramente mostre ou evidencie que não pretende
            ou não pode cumprir as cláusulas dos Termos);
          </p>

          <p>
            (B) tal for exigido a MSD SAÚDE ANIMAL por lei ou decisão judicial
            (por exemplo, quando o fornecimento dos SERVIÇOS ao USUÁRIO for ou
            se tornar ilegal);
          </p>

          <p>
            (C) por iniciativa da MSD SAÚDE ANIMAL em encerrar o SITE, ou os
            SERVIÇOS oferecidos, total ou parcialmente, sem necessidade de
            notificação ou justificação, a qualquer tempo para o USUÁRIO;
          </p>

          <p>
            Quando estes TERMOS forem encerrados, todos os direitos, obrigações
            e responsabilidades de que o USUÁRIO tenha assumido e acumulado
            enquanto a vigência dos TERMOS, ou que expressamente deva continuar
            em vigor indefinidamente não serão afetados por essa resolução, e
            continuarão a ser aplicáveis indefinidamente.
          </p>

          <p>
            No caso de término da oferta dos SERVIÇOS, ou do SITE, quer total ou
            parcialmente, por iniciativa da MSD SAÚDE ANIMAL, esta não estará
            obrigada a manter, fornecer, devolver ou ressarcir, qualquer espécie
            de conteúdo, dados ou informações repassadas pelo USUÁRIO.
          </p>

          <p>
            O presente Acordo também poderá ser rescindido por outras maneiras,
            expressas ao longo dos presentes TERMOS.
          </p>

          <p>
            Alguns SERVIÇOS oferecidos podem, a qualquer momento, não mais ser
            disponibilizados, quer sejam os mesmos ofertados diretamente pela
            MSD SAÚDE ANIMAL ou por seus parceiros, sem necessária comunicação
            ao USUÁRIO ou justificação.
          </p>

          <h3>7. Exclusão de garantias e responsabilidade</h3>

          <p>
            Nada nestes TERMOS excluirá, ou limitará, o direito da MSD SAÚDE
            ANIMAL ser ressarcida por perdas ou danos causados pelo USUÁRIO,
            inclusive contra terceiros.
          </p>

          <p>
            O USUÁRIO entende, e concorda expressamente, que o uso dos SERVIÇOS
            se dá por sua conta e risco, e que os SERVIÇOS são fornecidos “na
            forma em que se encontram” e “de acordo com a disponibilidade”.
          </p>

          <p>
            MSD SAÚDE ANIMAL não garante que, nem tem responsabilidade sobre:
          </p>

          <p>(a) o uso dos SERVIÇOS atenderá as expectativas do USUÁRIO;</p>

          <p>
            (b) o uso dos SERVIÇOS será ininterrupto, pontual, seguro e isento
            de erros;
          </p>

          <p>
            c) qualquer informação obtida pelo USUÁRIO em decorrência do seu uso
            dos serviços será precisa ou confiável;
          </p>

          <p>
            (d) os defeitos no funcionamento ou na funcionalidade de qualquer
            software fornecido ao USUÁRIO como parte dos SERVIÇOS serão
            corrigidos;
          </p>

          <p>
            (e) quaisquer danos diretos, indiretos, incidentais, especiais,
            conseqüenciais ou exemplares, incorridos pelo USUÁRIO, causados e
            sob qualquer teoria de responsabilidade. Isso inclui, mas não se
            limita, a quaisquer perdas e danos, lucros diretos ou indiretos ou
            ainda cessantes;
          </p>

          <p>
            (f) qualquer perda ou dano em que o USUÁRIO possa incorrer,
            inclusive, mas não se limitando a, perda ou danos em decorrência de:
          </p>

          <p>
            I - qualquer expectativa do USUÁRIO quanto à integridade, precisão
            ou existência de qualquer publicidade, ou em resultado de qualquer
            relação ou transação realizada com qualquer parceiro, anunciante ou
            patrocinador cuja publicidade apareça no SITE;
          </p>

          <p>
            II - quaisquer alterações que MSD SAÚDE ANIMAL possa fazer aos
            SERVIÇOS;
          </p>

          <p>
            III - a eliminação, corrupção ou incapacidade de armazenamento de
            qualquer conteúdo ou outros dados de comunicações mantidos ou
            transmitidos por ou através da utilização dos SERVIÇOS por parte do
            USUÁRIO;
          </p>

          <p>
            IV - o não fornecimento à MSD SAÚDE ANIMAL, por parte do USUÁRIO, de
            informações corretas relativas à conta;
          </p>

          <p>
            V - a não manutenção, por parte do USUÁRIO, da segurança e da
            confidencialidade dos detalhes da sua conta.
          </p>

          <p>
            (g) impossibilidade de acesso ao SITE, ou falha de funcionamento e
            comunicação com o mesmo decorrente de:
          </p>

          <p>
            I - quaisquer defeitos ou inadequação dos equipamentos utilizados
            para acessar ao SITE, incluindo, mas não se limitando, aos
            softwares, hardwares, sistemas de processamento e quaisquer conexões
            de rede;
          </p>

          <p>
            II - inabilidade do USUÁRIO para operação dos equipamentos e
            tecnologias necessárias;
          </p>

          <p>
            III - falhas na Rede Mundial de Computadores (Internet) e
            Provedores;
          </p>

          <p>
            IV - falhas nos sistemas, servidores, softwares e/ou hardwares que
            mantém o SITE acessível;
          </p>

          <p>
            V - quaisquer prejuízos causados por gravação realizada pelos
            USUÁRIOS (download) para os seus equipamentos próprios, de quaisquer
            arquivos eletrônicos existentes ou disponibilizados no SITE, quer
            pela MSD SAÚDE ANIMAL, quer por terceiros;
          </p>

          <p>
            VI - quaisquer prejuízos advindos ao USUÁRIO pela utilização
            indevida e/ou dolosa por terceiros, dos vídeos, fotos, textos, dados
            pessoais e cadastrais fornecidos por aquela, que forem veiculados,
            ou não, no SITE;
          </p>

          <p>
            VII - quaisquer prejuízos decorrentes da utilização indevida dos
            dados do USUÁRIO por terceiros;
          </p>

          <p>
            VIII - quer civil ou criminalmente por qualquer material, vídeo,
            imagem ou objeto visual, auditivo ou textual, ou em qualquer outro
            formato, existente ou que venha a existir, inserido no site por
            USUÁRIO, que são de inteira responsabilidade de quem os remeteu para
            o SITE;
          </p>

          <p>
            IX - por atos de má-fé de terceiros que invadam o programa do SITE,
            tais como hackers, que acessem os dados cadastrais e pessoais
            fornecidos pelo USUÁRIO e se utilizem ilicitamente dos mesmos para
            quaisquer fins. MSD SAÚDE ANIMAL declara ter os cuidados razoáveis
            para evitar a invasão do sistema, mas não se responsabiliza e não
            pode se responsabilizar, pela inviolabilidade do mesmo;
          </p>

          <p>
            X - pela perda de dados e/ou informações eventualmente enviadas pelo
            USUÁRIO no banco de dados do SITE, seja pela exclusão de sua
            participação, seja por falha de sistema. MSD SAÚDE ANIMAL não se
            obriga, ainda, a manter e/ou realizar qualquer espécie de 'back-up'
            do banco de dados do SITE, incluindo materiais e dados fornecidos
            pelo USUÁRIO;
          </p>

          <p>
            XI - pela inadimplência do USUÁRIO às normas da lei ou do presente
            instrumento, que gere prejuízos ou danos a terceiros;
          </p>

          <p>
            XII - o endereço de acesso do SITE é o divulgado nestes TERMOS,
            sendo assim, MSD SAÚDE ANIMAL não se responsabilizará por quaisquer
            acessos à outros domínios, mesmo que tais se apresentem com as
            mesmas características desse sítio e/ou aleguem ser o original;
          </p>

          <p>
            A MSD SAÚDE ANIMAL não é responsável pelos equipamentos necessários
            para acessar os recursos disponíveis no SITE, quer sejam físicos ou
            não, sendo estes as expensas do USUÁRIO.
          </p>

          <p>
            Qualquer material baixado ou obtido de outra forma por meio dos
            SERVIÇOS é obtido a próprio critério e risco do USUÁRIO, sendo ele o
            único responsável por qualquer dano ao sistema do seu computador ou
            qualquer outro aparelho, ou pela perda de dados que aconteça como
            resultado do download de qualquer material.
          </p>

          <p>
            A MSD SAÚDE ANIMAL não tem qualquer responsabilidade quanto a
            conselho, informação ou indicação inserida por terceiros, obtido
            pelo USUÁRIO junto ao SITE, devendo o USUÁRIO fazer todas as
            verificações necessárias para a utilização segura das informações
            que obtiver, consultando profissionais qualificados, assim como
            avaliação de sua particular condição.
          </p>

          <h3>8. Legislação e jurisdição aplicável</h3>

          <p>
            Os SERVIÇOS e o SITE se encontram na web e podem ser acessados de
            qualquer local, e independente de onde o USUÁRIO se encontre. Os
            TERMOS serão regidos pelo ordenamento jurídico do Brasil, sendo
            elegida a Comarca da capital do estado de São Paulo como única
            jurisdição competente para julgar qualquer controvérsia oriunda
            destes TERMOS, por mais privilegiado que outro venha a ser. Não
            obstante o referido, o USUÁRIO concorda que a MSD SAÚDE ANIMAL, e
            qualquer empresa integrante de seu grupo econômico, poderá ainda
            apresentar medidas judiciais (ou equivalente) em qualquer
            jurisdição, quando os presentes TERMOS forem violados em outros
            países.
          </p>

          <p>
            Em caso de conflito de leis estaduais ou municipais, para a
            interpretação de qualquer dúvida ou litígio, deverá sempre
            prevalecer a legislação do Estado e Capital de São Paulo.{" "}
          </p>

          <p>
            Aplicam-se, subsidiariamente a esses TERMOS, além das normas
            dispostas pelo Código Civil e legislação em geral, também as das
            Leis 9.609 e 9.610 de 19 de fevereiro de 1.998 (Lei de Direito do
            Autor e Software), além da Lei 12.965/14, conhecida como o{" "}
            <em>
              Marco Civil da Internet, e de forma antecipada com caráter
              precário, no que couber, os princípios elencados na Lei Geral de
              Proteção de Dados Pessoais (LGPDP).
            </em>
          </p>

          <h3>9. Mudanças em nossos termos de uso</h3>

          <p>
            Queremos que você entenda que este documento, assim a Política de
            Privacidade são documentos importantes para que você tenha plena
            satisfação ao usá-lo.
          </p>

          <p>
            Assim, a MSD SAÚDE ANIMAL reservando-se o direito de alterar ou
            remover estes termos de uso a seu critério e sem prévio aviso,{" "}
            <strong>recomenda</strong> que você visite esta área periodicamente
            para se manter informado sobre quaisquer alterações, que serão
            publicadas aqui.
          </p>

          <h3>10. Considerações finais</h3>

          <p>
            Quaisquer dúvidas, divergências ou situações não previstas neste
            documento ser-lhe-ão aplicadas subsidiariamente para fins de sanar a
            questão: Política de Privacidade deste SITE, Termos de Uso e
            deliberação da MSD SAÚDE ANIMAL, além de outras normas pertinentes
            (legais, infra e/ou supralegais).{" "}
          </p>

          <h3>11. Fale conosco</h3>

          <p>
            Se você quiser contatar a MSD SAÚDE ANIMAL para fazer comentários ou
            esclarecer dúvidas sobre este documento, por favor, entre em contato
            conosco através do e-mail neste{" "}
            <a href="mailto:clubebravecto@merck.com">link</a>.
          </p>

          <h3>12. Lembre-se</h3>

          <p>
            Este SITE está vinculado a MSD SAÚDE ANIMAL, portanto, para estar
            aqui você deve ter idade igual ou superior a 18 (dezoito) anos.{" "}
          </p>

          <p>
            O não aceite integral deste documento implicará na impossibilidade
            de finalização do cadastro perante o site, bem como, o não acesso ao
            conteúdo e serviços lá disponíveis.{" "}
          </p>

          <p>
            Não sendo finalizado o cadastro, conforme parágrafo anterior,
            qualquer dado já enviado até o momento será terminantemente
            descartado dos nossos servidores e banco de dados.
          </p>
        </div>
      </div>

      <div id="footer__container">
        <form onSubmit={handleSubmit}>
          <div id="container__input--manual">
            <div id="content__input--manual">
              <div id="box__container">
                <div
                  className={
                    acceptTermsOfUse === true
                      ? "input--manual__checked"
                      : acceptTermsOfUse === false
                      ? "input--manual__unchecked errorInputAcceptTerms"
                      : "input--manual__unchecked"
                  }
                  onClick={() => {
                    setAcceptTermsOfUse(!acceptTermsOfUse);
                  }}
                >
                  {acceptTermsOfUse ? <MdCheck /> : ""}
                </div>
                <span
                  className={
                    acceptTermsOfUse === false ? "errorAcceptTerms" : ""
                  }
                >
                  Eu li e concordo com os termos de uso
                </span>
                <div id="btn__container">
                  <button
                    type="submit"
                    id="btn-cadastrar"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <LoaderForButton /> : <span>Entrar</span>}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }, props) => {
  return {
    auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(TermosUso);
