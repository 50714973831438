import React from "react";
import { Link, useHistory } from "react-router-dom";

import "./MenuNaoLogado.css";

function MenuNaoLogado() {
  let history = useHistory();

  function goToHome() {
    history.push("/");
  }

  return (
    <div id="menuNaoLogado">
      <div id="__container">
        <div
          id="logo"
          onClick={() => {
            goToHome();
          }}
        >
          <span></span>
        </div>
        {/*  <div id='row'>
                    <div id='container'>
                        <div className="login icon">
                            <span></span>
                        </div>
                        <div id="text">
                            <Link to="/login">LOGIN / CADASTRO</Link>
                        </div>
                    </div>
                </div> */}
        {/*
                    <div id='row'>
                        <div id='container'>
                            <div className="faq icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/faq">FAQ</Link>
                            </div>
                        </div>
                    </div>
                */}
        {/*  <div id='row'>
                    <div id='container'>
                        <div className="quem-somos icon">
                            <span></span>
                        </div>
                        <div id="text">
                            <Link to="/quem-somos">QUEM SOMOS</Link>
                        </div>
                    </div>
                </div> */}
        <div id="row">
          <div id="container">
            <div className="lembretes icon">
              <span></span>
            </div>
            <div id="text">
              <Link to="/lembretes">LEMBRETES</Link>
            </div>
          </div>
        </div>
        <div id="row">
          <div id="container">
            <div className="dicas icon">
              <span></span>
            </div>
            <div id="text">
              <Link to="/blog">BLOG</Link>
            </div>
          </div>
        </div>
        <br />
        <Link to="/cadastrar">
          <button type="button" id="btn-cadastrar">
            Cadastre-se
          </button>
        </Link>
      </div>
    </div>
  );
}

export default MenuNaoLogado;
