import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Connect from "../../store/config/connect";
import { LogOutUser } from "../../store/auth";

import "./MenuUsuario.css";

const MenuUsuario = (props) => {
  let history = useHistory();

  function goToHome() {
    history.push("/");
  }

  function goToProfile() {
    history.push("/clube-de-vantagens");
  }

  function handleLogout() {
    props.handleClose(!props.status);
    props.dispatch(LogOutUser());
    goToHome();
  }

  const [username, setUsername] = useState("teste");
  useEffect(() => {
    function getUsername() {
      return props.user.name;
    }
    setUsername(getUsername());
  }, [props.user.name]);

  function handleDeleteProfile() {
    props.setModalClosed(false);
    props.handleClose(!props.status);
  }

  return (
    <div id="MenuUsuario">
      <div id="MenuUsuario__container">
        <div
          id="logo"
          onClick={() => {
            goToProfile();
          }}
        >
          <span></span>
        </div>
        <div id="row__container">
          <div id="row">
            <div id="container">
              <div id="text">
                <span id="light">Bem-vindo</span>
                <br />
                <span id="light">Olá</span>
                <span> {username}</span>
              </div>
            </div>
          </div>
          <div id="row">
            <div id="container">
              <div className="user icon">
                <span></span>
              </div>
              <div id="text">
                <Link to="/editar-meu-perfil">MEU PERFIL</Link>
              </div>
            </div>
          </div>

          <div id="row">
            <div id="container">
              <div className="meus-pets icon">
                <span></span>
              </div>
              <div id="text">
                <Link to="/meus-pets">MEUS PETS</Link>
              </div>
            </div>
          </div>

          <div id="row">
            <div id="container">
              <div className="minhas-compras icon">
                <span></span>
              </div>
              <div id="text">
                <Link to="/minhas-compras">MINHAS COMPRAS</Link>
              </div>
            </div>
          </div>
          <div id="row">
            <div id="container">
              <div className="duvidas icon">
                <span></span>
              </div>
              <div id="text">
                <Link to="/duvidas">DÚVIDAS</Link>
              </div>
            </div>
          </div>

          {/*                     
                    <div id='row'>
                    <div id='container'>
                            <div className="beneficios icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/meus-beneficios">BENEFÍCIOS</Link>
                            </div>
                        </div>
                    </div>
                    <div id='row'>
                        <div id='container'>
                            <div className="dicas icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/blog">BLOG</Link>
                            </div>
                        </div>
                    </div>
                    <div id='row'>
                        <div id='container'>
                            <div className="onde-comprar icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/onde-comprar">ONDE COMPRAR</Link>
                            </div>
                        </div>
                    </div>
                    <div id='row'>
                        <div id='container'>
                            <div className="faq icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/faq">FAQ</Link>
                            </div>
                        </div>
                    </div>
                    <div id='row'>
                        <div id='container'>
                            <div className="quem-somos icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/quem-somos">QUEM SOMOS</Link>
                            </div>
                        </div>
                    </div>
                    <div id='row'>
                        <div id='container'>
                            <div className="lembretes icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/cadastrar-lembrete/0">CADASTRAR LEMBRETE</Link>
                            </div>
                        </div>
                    </div>
                    <div id='row'>
                        <div id='container'>
                            <div className="subir-nota icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/cadastrar-nota">CADASTRAR NOTA FISCAL</Link>
                            </div>
                        </div>
                    </div>
                    <div id='row'>
                        <div id='container'>
                            <div className="desconto icon">
                                <span></span>
                            </div>
                            <div id="text">
                                <Link to="/desconto-em-bravecto">DESCONTO EM BRAVECTO</Link>
                            </div>
                        </div>
                    </div> */}
          <div id="row">
            <div id="container">
              <div className="exit icon">
                <span></span>
              </div>
              <div
                id="text"
                onClick={() => {
                  handleLogout();
                }}
              >
                <span>SAIR</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }, props) => {
  return {
    ...auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(MenuUsuario);
