import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { CSSTransition } from "react-transition-group";

import Connect from "../../../store/config/connect";

import { putDeletePet } from "../../../services/services";

import "./ModalDeletePet.css";

const ModalDeletePet = (props) => {
  function handleClose() {
    props.setModalClose(false);
    props.setPetId(0);
  }

  function handleDeletePet() {
    putDeletePet(props.petId, props.token)
      .then((res) => {
        console.log(res);
        if (res && res.status === 200) {
          const resp = JSON.parse(res.request.response);
          handleClose();
        }
      })
      .catch((e) => {
        console.log(e.response);
      });
  }

  return (
    <div
      id="modal--deletePet"
      style={{ display: props.modalClose ? "flex" : "none" }}
    >
      <CSSTransition
        in={props.modalClose}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        {/* <span>petid: {props.petId}</span> */}
        <div id="modal--deletePet__container">
          <div id="modal--deletePet__container--close">
            <span><b>Atenção!</b></span>
          </div>
          {/* <div id="modal--deletePet__container--title">
          </div> */}
          <div id="modal--deletePet__container--desc">
            <span>
              Tem certeza que deseja excluir esse pet?
            </span>
            <div id="modal--deletePet__container--save">
              <button
                id="yes"
                type="button"
                onClick={() => {
                  handleDeletePet();
                }}
              >
                Sim
              </button>
              <button
                id="no"
                type="button"
                onClick={() => {
                  handleClose();
                }}
              >
                Não
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Connect()(ModalDeletePet);
