import React from "react";
// import { Helmet } from 'react-helmet';

import MetaTags from "../../MetaTags/MetaTags";

import "./Contato.css";

const Contato = () => {
  return (
    <div id="Contato">
      <MetaTags
        title="Entre em Contato | Clube de Vantagens Bravecto"
        description="Entre em contato com a equipe do Clube de Vantagens Bravecto e tire todas as suas dúvidas. Responderemos o mais breve possível. Clique! "
      />
      <div id="Contato__container">
        <p>
          O Clube de Vantagens Bravecto foi criado para você, que sempre confiou
          no Bravecto para proteger o seu melhor amigo. Temos muito interesse em
          saber o que você pensa.
        </p>
        <p>
          Para informações ou dúvidas relacionadas ao Clube de Vantagens
          Bravecto, envie sua mensagem para nós, nesse{" "}
          <a href="mailto:clubebravecto@merck.com">e-mail</a>.
        </p>
        <p>
          Para informações ou dúvidas relacionadas ao produto,{" "}
          <a
            href="https://www.msd-saude-animal.com.br/fale-conosco"
            target="_blank"
          >
            acesse aqui
          </a>{" "}
          e veja nossos canais de contato
        </p>
        <p>Avenida Doutor Chucri Zaidan, 296, 13º Andar<br />
          CEP: 04583-110<br />
          São Paulo SP – Brasil</p>
      </div>
    </div>
  );
};

export default Contato;
