import React from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import bannerDesk from "../../images/banner-garantia.png";
import bannerMobile from "../../images/banner-garantia-mobile.png";
import regulationButton from "../../images/regulamento-button.png";
import gLogo from "../../images/garantia_interna.png";
import gBox from "../../images/box_garantia.png";
import gEsteto from "../../images/estetoscopio.png";

import garantiaRegulamento from "../../docs/Regulamento_Garantia_Bravecto_-_2024.pdf";
// import { react } from "@babel/types";

import "./GarantiaBravecto.scss";

const GarantiaBravecto = (props) => {
  document.title = "Clube Bravecto | Garantia Bravecto";

  return (
    <div id="GarantiaBravecto">
      <Header />
      {/* <div className="page-title">
          <span>Garantia Bravecto</span>
        </div> */}
      <div className="banner">
        <img className="bnr-desk" src={bannerDesk} alt="banner-garantia" />
        <img className="bnr-mob" src={bannerMobile} alt="banner-garantia" />
      </div>
      <div className="GarantiaBravecto__container">
        <div className="rules__main">
          <div className="rules">
            <a
              href={garantiaRegulamento}
              target="_blank"
              rel="noopener noreferrer"
              class="regulation-btn"
            >
              <img src={regulationButton} alt="regulamento" />
            </a>
            <h4>GARANTIA PENSADA NA SUA TRANQUILIDADE</h4>
            <img src={gLogo} alt="" />
            <p>
              Bravecto e a MSD Saúde Animal estão sempre buscando o melhor para
              você. <br className="hide-mobile" />
              E, para sua tranquilidade, <br />
              oferecemos um benefício adicional: <br className="hide-mobile" />
              GARANTIA BRAVECTO.
            </p>
            <p>
              Como confiamos tanto na qualidade de{" "}
              <br className="hide-mobile" />
              Bravecto, agora disponibilizamos <br className="hide-mobile" />
              um suporte e cuidado <br className="hide-desktop" />
              adicional frente a <br className="hide-mobile" />
              doenças transmitidas por pulgas e <br className="hide-mobile" />
              carrapatos, que podem acometer seu <br className="hide-mobile" />
              pet, como: <br className="hide-desktop" />
              erliquiose, babesiose,
              <br className="hide-mobile" />
              micoplasmose, anaplasmose, <br className="hide-mobile" />
              rangeliose, borreliose, hepatozoonose,
              <br className="hide-mobile" />
              bartonelose <br className="hide-desktop" />e febre maculosa.
            </p>
            <div className="title">
              <img src={gBox} alt="" />
              <h5 className="steps">
                Passo 1.
                <br />
                COMO PARTICIPAR?
              </h5>
            </div>
            <p>
              A cada NF de Bravecto cadastrada, <br className="hide-mobile" />
              você terá acesso à nossa <br className="hide-desktop" />
              Garantia <br className="hide-mobile" />
              Bravecto. Fique tranquilo, pois seu pet{" "}
              <br className="hide-mobile" />
              já possuirá um <br className="hide-desktop" />
              cuidado além da dose <br className="hide-mobile" />
              com a Garantia Bravecto.
            </p>
            <div className="title">
              <img src={gEsteto} alt="" />
              <h5 className="steps">
                Passo 2.
                <br />
                COMO ACIONAR?
              </h5>
            </div>
            <p>
              Se o seu pet for diagnosticado com <br className="hide-mobile" />
              alguma doença transmitida por <br />
              pulgas e carrapatos cobertas pela <br className="hide-mobile" />
              Garantia Bravecto, 21 dias após a <br />
              administração inicial do produto e <br className="hide-mobile" />
              cadastramento prévio, o<br />
              médico-veterinário responsável pelo <br className="hide-mobile" />
              caso deverá entrar em <br className="hide-desktop" />
              contato com a <br className="hide-mobile" />
              equipe MSD para acionar o benefício.
            </p>
            <br />
            <p>
              Para mais informações, leia o{" "}
              <a
                href={garantiaRegulamento}
                target="_blank"
                rel="noopener noreferrer"
              >
                Regulamento da Garantia Bravecto
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GarantiaBravecto;
