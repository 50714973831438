import React from "react";

import Connect from "../../../store/config/connect";

import "./Desktop.css";

const DesktopDescontoBravecto = (props) => {
  return (
    <div id="descontos">
      <div id="descontos__container">
        <div id="image__bravecto">
          <span></span>
        </div>
        <div id="text__bravecto">
          <div id="text__bravecto--container">
            <div id="text__bravecto--title">
              <span>Meus descontos em Bravecto</span>
            </div>
            <div id="text__bravecto--percent">
              <span>{props.bravectoDiscount.bravecto.discount}</span>
              <span>%</span>
            </div>
            {props.bravectoDiscount.bravecto.finishDate && (
              <div id="text__bravecto--expiration">
                <span>
                  Válido até {props.bravectoDiscount.bravecto.finishDate}
                </span>
              </div>
            )}
            <div id="text__bravecto--desc">
              <span>
                Lorem ipsum dolor sit amet, bla bla blabalLorem ipsum dolor sit
                amet, bla bla blabalLorem ipsum dolor sit amet, bla bla
                blabalLorem ipsum dolor sit amet, bla bla blabal
              </span>
            </div>
            <div id="text__bravecto--promotionalCode">
              <span>Possui um código promocional?</span>
              <form onSubmit={props.handleSubmitPromotionalCode}>
                <div id="container__input">
                  <input type="text" />
                  <button type="submit">Enviar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connect()(DesktopDescontoBravecto);
