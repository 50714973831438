import React from "react";

import Connect from "../../../store/config/connect";

import "./Mobile.css";

const MobileDescontoBravecto = (props) => {
  return (
    <React.Fragment>
      <div id="descontos-mobile">
        <div id="meus-descontos">
          <div id="meus-descontos__title">
            <span>Meus descontos em Bravecto</span>
          </div>
          <div id="meus-descontos__info">
            <div id="descontos__content"></div>
            <div id="descontos__content">
              <div id="descontos__content--container">
                <div id="descontos__content--num">
                  <span>{props.bravectoDiscount.bravecto.discount}</span>
                  <span>%</span>
                </div>
                <div id="descontos__content--expiration">
                  {props.bravectoDiscount.bravecto.finishDate && (
                    <span>
                      Válido até {props.bravectoDiscount.bravecto.finishDate}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="bravecto-produtos">
          <span></span>
        </div>
      </div>
      <div id="descontos__info">
        <span>
          Lorem ipsum dolor sit amet, consectetur <br /> adipiscing elit ipsum
          sit.
        </span>
      </div>
      <div id="promotionalCode">
        <span>Possui um código promocional?</span>
        <form onSubmit={props.handleSubmitPromotionalCode}>
          <div id="container__input">
            <input type="text" />
            <button type="submit">Enviar</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default Connect()(MobileDescontoBravecto);
