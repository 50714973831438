import React, { useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./Faq.scss";

function Faq() {
  document.title = "Clube Bravecto | Dúvidas";

  const questions = [
    {
      number: 1,
      title: "O que é a Garantia Bravecto?",
      content:
      "O programa entrou em vigência em 01/07/2021 e é destinada aos médicos-veterinários, e oferece suporte financeiro de até <strong>R$750,00</strong> para cobrir eventuais custos de seus clientes derivadps de consultas, exames diagnósticos e tratamento em caso de doenças transmitidas por carrapatos e pulgas que venham a acometer os cães ou gatos após 21 dias corridos a partir da data da compra do produto, a qual é considerada como o dia da administração, devendo ter comprovação por nota fiscal.<br></br> A <strong>Garantia Bravecto</strong> estende-se também à compensação financeira no valor de até <strong>R$2.000,00</strong> para a aquisição de outro animal em caso de óbito por estas doenças listadas",
    },
    {
      number: 2,
      title: "Quais as doenças cobertas pela Garantia Bravecto?",
      content:
        "Considera-se para efeito deste programa as seguintes doenças transmitidas por carrapatos ou pulgas: erliquose, babesiose, micoplasmose, anaplasmose, rangeliose, borreliose, hepatozoonose, bartonelose e febre maculosa. O vetor transmissor da doença precisa ter indicação de prevenção e/ou tratamento expresso em bula.",
    },
    {
      number: 3,
      title: "Quais as premissas para acionar a Garantia Bravecto?",
      content:
        `- Abertura da Ficha de Farmacovigilância na MSD Saúde Animal</br>
        Contato pode ser realizado com um representante da empresa ou com o SAC (0800 7070 512)
        </br></br>
        - Cópia da Nota Fiscal do Bravecto constando a data de compra do produto, a qual deverá estar cadastrada no site <a href="www.clubebravecto.com.br" target="_blank">www.clubebravecto.com.br</a>
        </br></br>
        - Cópia dos exames complementares e COMPROBATÓRIOS DO DIAGNÓSTICO (para efeito da Garantia são aceitos somente os exames descritos no item 3.2 do regulamento), devidamente preenchidos, assinados, datados e carimbados pelo profissional médico-veterinário responsável.
        </br></br>
        - Cópia do histórico clínico devidamente preenchido, assinado, datado e carimbado pelo profissional médico-veterinário responsável.
        </br></br>
        - Cópia das Notas Fiscais que comprovam os gastos com consultas, exames, diagnóstico e tratamento do animal.
        `,
    },
    {
      number: 4,
      title: "O acionamento da Garantia Bravecto implica necessariamente na aprovação da mesma e consequentemente no ressarcimento?",
      content:
        "Não, pois após o envio dos documentos o caso passa por uma avaliação, havendo possibilidade de aprovação ou negativa, de acordo com a análise.",
    },
    {
      number: 5,
      title: "Perdi a Nota fiscal e não a cadastrei no Clube de Vantagens Bravecto, consigo acionar a Garantia caso seja necessário?",
      content: `Não, a nota fiscal de compra do produto é um dos documentos necessários para acionamento da Garantia Bravecto, devendo a mesma estar cadastrada no Clube.`,
    },
    {
      number: 6,
      title:
        "Mantenho o Bravecto dos meus pets em dia – faço a cada 12 semanas. Nesse caso, a cada nova administração haverá os 21 dias de carência para acionamento do Programa?",
      content:
        "O documento fiscal de compra do produto deve ter a data de pelo menos 21 dias antes da data do teste diagnóstico, considerando a primeira administração de Bravecto. Caso sejam feitas administrações sequenciais no período correto – a cada 84 dias – não existirá este período de carência a partir da segunda administração. ",
    },
    {
      number: 7,
      title: "Se a administração do produto ocorreu antes do dia 01/07/2021, consigo acionar a Garantia Bravecto caso seja necessário?",
      content:
        `Não, como o período de vigência da Garantia Bravecto é a partir do dia 01/07/21, são considerados cobertos pelo programa apenas os animais que receberam as administrações de Bravecto após esta data.
        </br></br>
        <a href="/garantia-bravecto" target="_blank">Acesse o regulamento da Garantia Bravecto para mais detalhes sobre o Programa.</a>
        `,
    },
  ];

  const [questionOpen, setQuestionOpen] = useState({
    1: true,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
  });

  function handleQuestionOpen(name, value) {
    setQuestionOpen({ ...questionOpen, [name]: value });
  }

  return (
    <div id="Faq">
      <Header />
      <div id="faq__container">
        <div id="title">
          <div id="main-title">
            <span>FAQ - Garantia Bravecto</span>
          </div>
        </div>
        <div id="question">
          <div id="question__container">
            {questions.map((i) => {
              return (
                <div
                  key={i.number}
                  id="question__content"
                  className={`${
                    questionOpen[i.number]
                      ? "question__content--open"
                      : "question__content--closed"
                  }`}
                >
                  <div id="question__title">
                    <div id="icon">
                      <span>{i.number}</span>
                    </div>
                    <div id="main-title">{i.title}</div>
                    <div
                      id="icon-arrow"
                      onClick={() => {
                        handleQuestionOpen(i.number, !questionOpen[i.number]);
                      }}
                    >
                      {questionOpen[i.number] ? (
                        <MdKeyboardArrowUp />
                      ) : (
                        <MdKeyboardArrowDown />
                      )}
                    </div>
                  </div>
                  <div
                    className={`${
                      questionOpen[i.number] ? "" : "answer--closed"
                    }`}
                    id="answer"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: i.content }}
                    ></span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
