import React from "react";
import { Link, useHistory } from "react-router-dom";

import "./MenuLogado.css";

function MenuLogado() {
  let history = useHistory();

  function goToHome() {
    history.push("/");
  }

  return (
    <div id="MenuLogado">
      <div id="__container">
        <div
          id="logo"
          onClick={() => {
            goToHome();
          }}
        >
          <span></span>
        </div>

        <div id="row">
          <div id="container">
            <div id="text">
              <Link to="/momentosbravecto10anos">PROMOÇÃO #MOMENTOSBRAVECTO10ANOS</Link>
            </div>
          </div>
        </div>

        <div id="row">
          <div id="container">
            <div className="desconto icon">
              <span></span>
            </div>
            <div id="text">
              <Link to="/garantia-bravecto">GARANTIA BRAVECTO</Link>
            </div>
          </div>
        </div>

        <div id="row">
          <div id="container">
            <div className="beneficios icon">
              <span></span>
            </div>
            <div id="text">
              <Link to="/meus-beneficios">MEUS BENEFÍCIOS</Link>
            </div>
          </div>
        </div>

        <div id="row">
          <div id="container">
            <div className="lembretes icon">
              <span></span>
            </div>
            <div id="text">
              <Link to="/cadastrar-lembrete/0">CADASTRAR LEMBRETE</Link>
            </div>
          </div>
        </div>

        {/* <div id='row'>
                    <div id='container'>
                        <div className="onde-comprar icon">
                            <span></span>
                        </div>
                        <div id="text">
                            <Link to="/onde-comprar">ONDE COMPRAR</Link>
                        </div>
                    </div>
                </div> */}
        {/* <div id='row'>
                    <div id='container'>
                        <div className="faq icon">
                            <span></span>
                        </div>
                        <div id="text">
                            <Link to="/faq">FAQ</Link>
                        </div>
                    </div>
                </div> */}
        {/*  <div id='row'>
                    <div id='container'>
                        <div className="quem-somos icon">
                            <span></span>
                        </div>
                        <div id="text">
                            <Link to="/quem-somos">QUEM SOMOS</Link>
                        </div>
                    </div>
                </div> */}

        <div id="row">
          <div id="container">
            <div className="subir-nota icon">
              <span></span>
            </div>
            <div id="text">
              <Link to="/cadastrar-nota">CADASTRAR NOTA FISCAL</Link>
            </div>
          </div>
        </div>

        <div id="row">
          <div id="container">
            <div className="dicas icon">
              <span></span>
            </div>
            <div id="text">
              <Link to="/blog">BLOG</Link>
            </div>
          </div>
        </div>

        {/*  <div id='row'>
                    <div id='container'>
                        <div className="desconto icon">
                            <span></span>
                        </div>
                        <div id="text">
                            <Link to="/desconto-em-bravecto">DESCONTO EM BRAVECTO</Link>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
}

export default MenuLogado;
