import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Connect from "../../store/config/connect";
import { EditedPet } from "../../store/helper";

import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";
import SelectCustom from "../Utils/Select/Select";

import {
  getPetBreeds,
  putEditPet,
  getPet,
  getPetKg,
} from "../../services/services";

import ModalPhoto from "../CadastrarPet/ModalPhoto/ModalPhoto";

import Loader from "../Loader/Loader";

import moment from "moment";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./EditarPet.css";

const EditarPet = (props) => {
  document.title = "Clube Bravecto | Editar Pet";

  const [selectedBreed, setSelectedBreed] = useState("");
  const [selectBreed, setSelectBreed] = useState(null);
  const [breedsLoaded, setBreedsLoaded] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [firstEntry, setFirstEntry] = useState(true);
  const [errorPhoto, setErrorPhoto] = useState("");
  const [editedImage, setEditedImage] = useState(null);
  const [modalClose, setModalClose] = useState(false);
  const [image, setImage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [imageIsEdited, setImageIsEdited] = useState(false);

  function handleNewImage(e) {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 2097152) {
        setErrorPhoto("Esta foto é superior a 2MB, suba outra foto");
        setImage("");
        setModalClose(false);
      } else {
        setErrorPhoto("");
        setImage(e.target.files[0]);
        setModalClose(true);
        setImageIsEdited(true);
        setImage64(false);
      }
    }
  }

  const [breedsOptions, setBreedsOptions] = useState(null);
  const [inputPetTypeValue, setInputPetTypeValue] = useState(false);
  useEffect(() => {
    if (inputPetTypeValue) {
      getPetBreeds(inputPetTypeValue).then((res) => {
        if (res.status === 200) {
          let breedsOptions = [];
          setBreedsLoaded(false);
          const breeds = JSON.parse(res.request.response);
          breeds.map((i) => {
            return breedsOptions.push({
              label: i.breedName,
              value: i.breedName,
            });
          });
          setBreedsOptions(breedsOptions);
          setBreedsLoaded(true);
        }
      });

      getPetKg(inputPetTypeValue).then((res) => {
        if (res && res.status === 200) {
          let kgOptions = [];
          const kgResponse = JSON.parse(res.request.response);
          kgResponse.map((i) => {
            return kgOptions.push({
              label: i.kg,
              value: i.kg,
            });
          });
          setPetKgOptions(kgOptions);
        }
      });
    }
  }, [inputPetTypeValue]);

  useEffect(() => {
    if (breedsOptions && selectedBreed) {
      setBreedsLoaded(false);
      setSelectBreed({
        placeholder: "Selecione a raça",
        options: breedsOptions,
        value: selectedBreed,
        isSearchable: false,
        noOptionsMessage: function () {
          return "Procure novamente!";
        },
        onChange: function (value) {
          setSelectedBreed(value);
        },
      });
      setBreedsLoaded(true);
    }
  }, [breedsOptions, selectedBreed]);

  const [selectPetKg, setSelectPetKg] = useState(false);
  const [selectedPetKg, setSelectedPetKg] = useState(false);
  const [petKgOptions, setPetKgOptions] = useState(false);
  const [petKgLoaded, setPetKgLoaded] = useState(false);
  useEffect(() => {
    if (petKgOptions && selectedPetKg) {
      setPetKgLoaded(false);
      setSelectPetKg({
        placeholder: "Selecione o peso do seu pet",
        options: petKgOptions,
        value: selectedPetKg,
        isSearchable: false,
        noOptionsMessage: function () {
          return "Procure novamente!";
        },
        onChange: function (value) {
          setSelectedPetKg(value);
        },
      });
      setPetKgLoaded(true);
    }
  }, [petKgOptions, selectedPetKg]);

  const [inputName, setInputName] = useState({
    type: "text",
    name: "name",
    id: "name",
    value: '',
    replaceValueOnChange: false,
    onChange: function (value) {
      return setInputName({
        ...inputName, value
      });
    },
  })


  const [inputGenderValue, setInputGenderValue] = useState("");

  const [inputBirthdayValue, setInputBirthdayValue] = useState("");
  const [birthdayError, setBirthdayError] = useState(false);
  const [inputBirthday, setInputBirthday] = useState(null);
  useEffect(() => {
    let birthdayProps = {
      type: "text",
      name: "birthday",
      maxLength: 10,
      placeholder: "DD/MM/AAAA",
      onChange: function (birthday) {
        birthday =
          birthday.length > 0 && birthday.slice(0, 2) > 31 ? 31 : birthday;
        birthday =
          birthday.length > 3 && birthday.slice(3, 5) > 12
            ? birthday.slice(0, 3) + 12
            : birthday;
        let v = birthday
          .toString()
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{2})(\d)/, "$1/$2");
        setInputBirthdayValue(v);
        return v;
      },
      onBlur: function (e) {
        const birthday = e.target.value.split("/").reverse().join("-"),
          today = moment();
        if (moment(birthday).isAfter(today, "days")) {
          setBirthdayError(true);
          setInputBirthdayValue(false);
        } else {
          setBirthdayError(false);
        }
      },
    };

    if (firstEntry) {
      birthdayProps.value = inputBirthdayValue;
    } else {
      birthdayProps.replaceValueOnChange = true;
    }

    setInputBirthday(birthdayProps);
  }, [firstEntry, inputBirthdayValue]);

  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [hasRequestErrors, setHasRequestErrors] = useState(false);

  function formValidation() {
    let formErrors = {};
    const fields = {
      name: inputName.value,
      petType: inputPetTypeValue,
      petGender: inputGenderValue,
      breed: selectedBreed.value,
      DateOfBirth: inputBirthdayValue,
      kg: selectedPetKg.value,
      CustomerId: props.user.id,
    };

    Object.keys(fields).map((i) => {
      if (!fields[i]) {
        formErrors[i] = true;
      }
      return formErrors[i];
    });

    if (Object.keys(formErrors).length > 0) {
      setHasFormErrors(formErrors);
      window.scrollTo(0, 0);
      setSubmitting(false);
      return false;
    } else {
      const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };

      if (editedImage && imageIsEdited) {
        const blob = b64toBlob(editedImage.split(",")[1], "image/jpg");
        fields.imageBase64 = new File([blob], "filename.jpg");
      } else {
        fields.imageBase64 = null;
      }

      fields.reminderDate = null;
      fields.imageIsEdited = imageIsEdited;
      return fields;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setHasFormErrors(false);
    setHasRequestErrors(false);
    setSubmitting(true);
    const fields = formValidation();

    if (fields) {
      let bodyFormData = new FormData();
      bodyFormData.set("name", fields.name);
      bodyFormData.set("petType", fields.petType);
      bodyFormData.set("petGender", fields.petGender);
      bodyFormData.set("breed", fields.breed);
      bodyFormData.set("dateOfBirth", fields.DateOfBirth);
      bodyFormData.set("kg", fields.kg);
      bodyFormData.set("customerId", fields.CustomerId);
      bodyFormData.set("reminderDate", fields.reminderDate);
      bodyFormData.set("imageBase64", fields.imageBase64);
      bodyFormData.set("imageIsEdited", fields.imageIsEdited);
      bodyFormData.set("id", props.match.params.id);

      const token = props.user.token;
      putEditPet(bodyFormData, token)
        .then((res) => {
          if (res && res.status === 200) {
            // const petResponse = JSON.parse(res.request.response);
            props.dispatch(EditedPet(true));
            props.history.push("/meus-pets");
          }
        })
        .catch((e) => {
          console.log(e.response);
          let error = "";
          setHasRequestErrors(error);
          setSubmitting(false);
        });
    }
  }

  const [image64, setImage64] = useState(false);
  useEffect(() => {
    if (props.match.params.id) {
      getPet(props.match.params.id)
        .then((res) => {
          if (res && res.status === 200) {
            const petResponse = JSON.parse(res.request.response);
            console.log(petResponse);
            setInputName({
              ...inputName, value: petResponse.name
            });
            setInputPetTypeValue(petResponse.petType);
            setInputGenderValue(petResponse.petGender);
            const valueInputBirthday = moment(petResponse.dateOfBirth).format("DD/MM/YYYY")
            setInputBirthdayValue(
              valueInputBirthday
            );
              console.log(valueInputBirthday)
              console.log(petResponse.dateOfBirth)
            setSelectedPetKg({ value: petResponse.kg, label: petResponse.kg });
            setImage64(petResponse.imageBase64);
            setSelectedBreed({
              label: petResponse.breed,
              value: petResponse.breed,
            });
            setLoaded(true);
            setFirstEntry(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setLoaded(false);
        });
    }
  }, [props.match.params.id]);

  if (loaded) {
    return (
      <div id="CadastrarPet">
        <ModalPhoto
          setEditedImage={setEditedImage}
          modalClose={modalClose}
          setModalClose={setModalClose}
          image={image}
        />
        <Header />
        <div id="cadastrarPet__container">
          <div id="title">
            <div id="main-title">
              <span>Editar pet</span>
            </div>
            {/* <div id="info">
                <span>Cadastre os seus animais para melhor <br/> administração da aplicação de seus produtos.</span>
            </div>  */}
          </div>
          <div
            id="error__main"
            style={{ display: hasFormErrors ? "flex" : "none" }}
          >
            <div>
              <span id="title">ERRO NO CADASTRO</span>
            </div>
            <div>
              <span id="info">
                Por favor, corrija as informações dos
                <br /> campos assinalados em Vermelho.
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div id="row">
              <div id="content">
                <div id="select-a-photo">
                  <div
                    id="select-a-photo__container"
                    onClick={() => {
                      document.getElementById("newPhoto").click();
                    }}
                  >
                    <div id="box-photo">
                      {!image64 &&
                        (!editedImage ? (
                          <React.Fragment>
                            <div id="box-photo--icon">
                              <span></span>
                            </div>
                            <div id="box-photo--archive">
                              <span>Selecionar arquivo</span>
                            </div>
                          </React.Fragment>
                        ) : (
                          <span
                            id="box-photo--editedPhoto"
                            style={{ backgroundImage: `url("${editedImage}")` }}
                          ></span>
                        ))}
                      {image64 && (
                        <span
                          id="box-photo--editedPhoto"
                          style={{
                            backgroundImage: `url("data:image/jpeg;base64,${image64}")`,
                          }}
                        ></span>
                      )}
                    </div>
                    <input
                      name="newImage"
                      id="newPhoto"
                      type="file"
                      accept=".jpg, .gif"
                      onChange={handleNewImage}
                    />
                  </div>
                  {/* <div id="photo--maxSize">
                    <span style={{ color: errorPhoto ? "#b42446" : "#717274" }}>
                      Limite de peso 2MB, somente arquivos .jpg .gif
                    </span>
                  </div> */}
                </div>
                <div id="name-pet">
                  <span>Nome do seu Pet:</span>
                  <Input {...inputName} data-value={inputName.value} />
                </div>
              </div>
            </div>
            <hr />
            <div id="row1">
              <div id="content">
                <div id="content-mobile">
                  <span>Sexo*</span>
                </div>
                <div id="container__input--radio">
                  <div id="input--radio__content" className="two-radios">
                    <input
                      id="input--1"
                      type="radio"
                      name="gender"
                      value="M"
                      onChange={(e) => {
                        setInputGenderValue(e.target.value);
                      }}
                      checked={inputGenderValue === "M" ? true : false}
                    />
                    <label htmlFor="input--1">Macho</label>
                  </div>
                  <div id="input--radio__content" className="two-radios">
                    <input
                      id="input--2"
                      type="radio"
                      name="gender"
                      value="F"
                      onChange={(e) => {
                        setInputGenderValue(e.target.value);
                      }}
                      checked={inputGenderValue === "F" ? true : false}
                    />
                    <label htmlFor="input--2">Fêmea</label>
                  </div>
                </div>
                <div
                  id="error__field"
                  style={{ display: hasFormErrors.petGender ? "flex" : "none" }}
                >
                  <span>Favor selecionar uma opção</span>
                </div>
              </div>
              <div id="content">
                <div id="content-mobile">
                  <span>Qual é a espécie do seu Pet?*</span>
                </div>
                <div id="container__input--radio">
                  <div id="input--radio__content" className="two-radios">
                    <input
                      id="inputSpecies--1"
                      type="radio"
                      name="species"
                      value="1"
                      onChange={(e) => {
                        setInputPetTypeValue(e.target.value);
                      }}
                      checked={parseInt(inputPetTypeValue) === 1 ? true : false}
                    />
                    <label htmlFor="inputSpecies--1">Cão</label>
                  </div>
                  <div id="input--radio__content" className="two-radios">
                    <input
                      id="inputSpecies--2"
                      type="radio"
                      name="species"
                      value="2"
                      onChange={(e) => {
                        setInputPetTypeValue(e.target.value);
                      }}
                      checked={parseInt(inputPetTypeValue) === 2 ? true : false}
                    />
                    <label htmlFor="inputSpecies--2">Gato</label>
                  </div>
                </div>
                <div
                  id="error__field"
                  style={{ display: hasFormErrors.petType ? "flex" : "none" }}
                >
                  <span>Favor selecionar uma opção</span>
                </div>
              </div>
            </div>
            <div id="row2">
              <div id="content">
                <div>
                  <span id="content-option">Nascimento:</span>
                  <Input {...inputBirthday} data-value={inputBirthdayValue} />
                  <div
                    id="error__field"
                    style={{
                      display:
                        hasFormErrors.DateOfBirth || birthdayError
                          ? "flex"
                          : "none",
                    }}
                  >
                    <span>Data inválida</span>
                  </div>
                </div>
                <div>
                  <span id="content-option">Raça:</span>
                  {breedsLoaded ? (
                    <SelectCustom {...selectBreed} />
                  ) : (
                    <div id="choose-a-specie">
                      <span>Escolha uma espécie para desbloquear este campo!</span>
                    </div>
                  )}
                  <div
                    id="error__field"
                    style={{ display: hasFormErrors.breed ? "flex" : "none" }}
                  >
                  </div>
                </div>
                <div>
                  <span id="content-option">Peso:</span>
                  {petKgLoaded ? (
                    <SelectCustom {...selectPetKg} />
                  ) : (
                    <div id="choose-a-specie">
                      <span>Escolha uma espécie para desbloquear este campo!</span>
                    </div>
                  )}
                  <div
                    id="error__field"
                    style={{ display: hasFormErrors.kg ? "flex" : "none" }}
                  >
                    <span>Peso inválido</span>
                  </div>
                </div>
              </div>
              <div
                id="error__main"
                style={{ display: hasRequestErrors ? "flex" : "none" }}
              >
                <div>
                  <span id="title">ERRO NO CADASTRO</span>
                </div>
                <div>
                  <span id="info">{hasRequestErrors}</span>
                </div>
              </div>
            </div>
          </form>
          <div id="btn-submit-editar">
            <Link to="/meus-pets" id="btn-voltar"></Link>
            <button
              type="submit"
              id="btn-edit-salvar"
              disabled={isSubmitting}
              onClick={(ev) => handleSubmit(ev, false)}
            >
              {isSubmitting ? <LoaderForButton /> : <span>Salvar</span>}
            </button>
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div id="preLoader">
        <Loader />
      </div>
    );
  }
};

const mapStateToProps = ({ auth, helper }, props) => {
  return {
    ...auth,
    ...props,
    ...helper,
  };
};

export default Connect(mapStateToProps)(EditarPet);
