/*global pushDataLayer*/

import React, { useState, useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft, MdCheck } from "react-icons/md";

import Connect from "../../store/config/connect";

import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";
import SelectCustom from "../Utils/Select/Select";
import { emailSuggestions } from "../../utils/helpers/general";

import CircularProgressBar from "./CircularProgressBar";

import Loader from "../Loader/Loader";

import {
  getStates,
  getCitiesByState,
  getCustomer,
  putEditCustomer,
} from "../../services/services";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import ModalDeleteProfile from "./ModalDeleteProfile/ModalDeleteProfile";
import ModalChangePassword from "./ModalChangePassword/ModalChangePassword";

import "./EditarMeuPerfil.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const EditarMeuPerfil = (props) => {
  document.title = "Clube Bravecto | Meu Perfil";

  const [selectedEstado, setSelectedEstado] = useState("");
  const [selectEstado, setSelectEstado] = useState({});
  const [statesLoaded, setStatesLoaded] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [modalNewPassClosed, setModalNewPassClosed] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  function handleOpenPassword() {
    setModalNewPassClosed(false);
  }

  useEffect(() => {
    getStates().then((res) => {
      if (res && res.status === 200) {
        let statesOptions = [];
        const states = JSON.parse(res.request.response);
        states.map((i) => {
          return statesOptions.push({
            label: i.name,
            value: i.uf,
          });
        });
        setOptionsEstado(statesOptions);
      }
    });
  }, []);

  //loader
  const [optionsEstado, setOptionsEstado] = useState(null);
  useEffect(() => {
    setStatesLoaded(false);
    setSelectEstado({
      placeholder: "Selecione seu estado",
      options: optionsEstado,
      value: selectedEstado,
      isSearchable: false,
      noOptionsMessage: function () {
        return "Procure novamente!";
      },
      onChange: function (value) {
        setSelectedEstado(value);
      },
    });
    setStatesLoaded(true);
  }, [optionsEstado, selectedEstado]);

  const [addressesId, setAddressesId] = useState(false);
  const [firstEntry, setFirstEntry] = useState(true);
  function transformarData(dataStr) {
    const dataObj = new Date(dataStr);
    console.log(dataObj)
  
    const dataFormatada = dataObj.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  
    return dataFormatada;
  }
  useEffect(() => {
    if (props.user.id && props.user.token && firstEntry) {
      getCustomer(props.user.id, props.user.token)
        .then((res) => {
          if (res && res.status === 200) {
            const user = JSON.parse(res.request.response);
            console.log(user);
            setInputNameValue(user.name);
            setInputEmailValue(user.email);
            setInputCpfValue(user.documentNumber);
            setInputYearBirthValue(transformarData(user.dateOfBirth));
            setInputGenderValue(user.gender);
            setSelectedCidade({ label: user.city, value: user.city });
            setSelectedEstado({ label: user.state, value: user.state });
            setInputPhonevalue(user.phoneNumber);
            setInputPetTypeValue(user.petType);
            setInputCepValue(user.cep);
            setInputEnderecoValue(user.street);
            setInputComplementoValue(user.complement); 
            setInputNumeroValue(user.number)
            // setInputIsBravectoValue(user.isBravecto);
            // setSelectedQuantoTempo(user.DaysFromLatestBravectoHandling);
            setAcceptNews(user.isEmail);
            setAddressesId(user.addressId);
            setPageLoaded(true);
            loadsCities(user.state);
            setUserInputs(calcularPorcentagemValoresPreenchidos(user))
          }
        })
        .catch((e) => {
          console.log("error", e.response);
        });
    }
    return () => {
      setFirstEntry(false);
    };
  }, [props, firstEntry]);

  function loadsCities(uf) {
    getCitiesByState(uf).then((res) => {
      if (res && res.status === 200) {
        const cities = JSON.parse(res.request.response);
        const citiesOptions = cities.map((i) => {
          return {
            label: i.name,
            value: i.name,
          };
        });
        setOptionsCidade(citiesOptions);
      }
    });
  }

  function calcularPorcentagemValoresPreenchidos(objeto) {
    const chavesEspecificas = ['name', 'email', 'dateOfBirth', 'gender', 'cep', 'state', 'city', 'street', 'number', 'phone', 'petType'];
    const totalChaves = chavesEspecificas.length;

    const chavesPreenchidas = chavesEspecificas.reduce((contador, chave) => {
        if (objeto[chave] !== undefined && objeto[chave] !== null && objeto[chave] !== '') {
            return contador + 1;
        }
        return contador;
    }, 1);

    const porcentagem = (chavesPreenchidas / totalChaves) * 100;
    return porcentagem;
  }

  const [optionsCidade, setOptionsCidade] = useState(null);
  useEffect(() => {
    if (selectedEstado) {
      loadsCities(selectedEstado.value);
    }
  }, [selectedEstado]);

  const [selectedCidade, setSelectedCidade] = useState('');
  const [cityLoaded, setCityLoaded] = useState(true);
  const [selectCidade, setSelectCidade] = useState({
    options: [],
    placeholder: "Selecione sua cidade",
    isSearchable: false,
    noOptionsMessage: function () {
      return "Para habilitar as opções de Cidade, selecione um Estado!";
    },
  });

  useEffect(() => {
    if (optionsCidade && selectedCidade) {
      setCityLoaded(false);
      setSelectCidade({
        placeholder: "Selecione sua cidade",
        options: optionsCidade,
        value: selectedCidade,
        isSearchable: false,
        onChange: function (value) {
          setSelectedCidade(value);
        },
      });
      setCityLoaded(true);
    }
  }, [optionsCidade, selectedCidade]);

  const [inputNameValue, setInputNameValue] = useState("");
  const [inputName, setInputName] = useState();
  useEffect(() => {
    setInputName({
      type: "text",
      name: "name",
      value: inputNameValue,
      onChange: function (value) {
        return setInputNameValue(value);
      },
    });
  }, [inputNameValue]);

  function handleEmailSugg(e) {
    const sugg = e.target.value;
    let currEmail = inputEmailValue;
    currEmail = currEmail.split("@")[0] + sugg;
    setInputEmailValue(currEmail);
    setEmailListFiltered([]);
    setEmailMask(true);
  }

  const [inputEmailValue, setInputEmailValue] = useState("");
  const [inputEmail, setInputEmail] = useState();
  const [emailMask, setEmailMask] = useState(true);
  const [emailList] = useState(emailSuggestions());
  const [emailListFiltered, setEmailListFiltered] = useState([]);
  useEffect(() => {
    let emailProps = {
      type: "text",
      name: "email",
      onChange: function (email) {
        // eslint-disable-next-line
        var re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(email).toLowerCase())
          ? setEmailMask(true)
          : setEmailMask(false);
        if (email.includes("@")) {
          let filtered = emailList.filter((i) => {
            return i.includes(email.split("@")[1]);
          });
          setEmailListFiltered(filtered);
        }
        setInputEmailValue(email);
      },
    };

    if (firstEntry) {
      emailProps.replaceValueOnChange = true;
    } else {
      emailProps.value = inputEmailValue;
    }
    setInputEmail(emailProps);
  }, [inputEmailValue, firstEntry, emailList]);

  const [inputCpfValue, setInputCpfValue] = useState("");
  const [inputCpf, setInputCpf] = useState({});
  useEffect(() => {
    if (inputCpfValue) {
      let cpfProps = {
        type: "text",
        name: "document",
        maxLength: 14,
        disabled: true,
        onChange: function (cpf) {
          let value = cpf
            .toString()
            .replace(/\D/g, "")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d)/, "$1.$2")
            .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
          setInputCpfValue(value);
          return value;
        },
      };

      if (firstEntry) {
        cpfProps.replaceValueOnChange = true;
      } else {
        cpfProps.value = inputCpfValue;
      }

      setInputCpf(cpfProps);
    }
  }, [inputCpfValue, firstEntry]);

  const [inputYearBirthValue, setInputYearBirthValue] = useState("");
  const [inputYearBirth, setInputYearBirth] = useState();

  useEffect(() => {
    setInputYearBirth({
      type: "text",
      name: "yearBirth",
      value: inputYearBirthValue,
      maxLength: 10,
      placeholder: "05/08/1985",
      onChange: function (year) {
        let value = year.replace(/\D/g, "");
        if (value.length > 2) {
          value = value.substring(0, 2) + '/' + value.substring(2);
        }
        if (value.length > 5) {
            value = value.substring(0, 5) + '/' + value.substring(5);
        }
        return setInputYearBirthValue(value);
      },
    });
  }, [inputYearBirthValue]);

  const history = useHistory();

  const [inputGenderValue, setInputGenderValue] = useState("");

  const [inputPhonevalue, setInputPhonevalue] = useState("");
  const [inputPhone, setInputPhone] = useState();
  useEffect(() => {
    let phoneProps = {
      type: "text",
      name: "phone",
      maxLength: 15,
      placeholder: "(11) 99999-9999",
      onChange: function (phone) {
        let value = phone
          .replace(/\D/g, "")
          .replace(/^(\d\d)(\d)/g, "($1) $2")
          .replace(/(\d{5})(\d)/, "$1-$2");

        setInputPhonevalue(value);
        return value;
      },
    };

    if (firstEntry) {
      phoneProps.replaceValueOnChange = true;
    } else {
      phoneProps.value = inputPhonevalue
        .replace(/\D/g, "")
        .replace(/^(\d\d)(\d)/g, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2");
    }

    setInputPhone(phoneProps);
  }, [firstEntry, inputPhonevalue]);

  const [inputPetTypeValue, setInputPetTypeValue] = useState("");
  const [acceptNews, setAcceptNews] = useState(false);

  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [hasRequestErrors, setHasRequestErrors] = useState(false);

  function capitalizeFirstLetter(str) {
    return str.replace(/(^\w|\s\w)(\S*)/g, (fullWord, firstLetter, rest) =>
      fullWord.length > 2
        ? `${firstLetter.toUpperCase()}${rest.toLowerCase()}`
        : fullWord.toLowerCase()
    );
  }

  function formValidation() {
    let formErrors = {};
    const fields = {
      name: inputNameValue ? capitalizeFirstLetter(inputNameValue) : false,
      email: emailMask ? inputEmailValue : false,
      document: inputCpfValue ? inputCpfValue : false,
      DateOfBirth: inputYearBirthValue ? inputYearBirthValue : "",
      gender: inputGenderValue,
      cep: inputCepValue ? inputCepValue : "",
      state: selectedEstado ? selectedEstado.value : "",
      city: selectedCidade ? selectedCidade.value : "",
      street: inputEnderecoValue ? inputEnderecoValue : "",
      number: inputNumeroValue ? inputNumeroValue : "",
      complement: inputComplementoValue ? inputComplementoValue : "",
      phoneNumber: inputPhonevalue ? inputPhonevalue : false,
      petType: inputPetTypeValue ? inputPetTypeValue : 0,
      // usedBravecto: inputUsedBravectoValue ? inputUsedBravectoValue : 0,
      // isBravecto: inputIsBravectoValue ? inputIsBravectoValue : false,
      // DaysFromLatestBravectoHandling: inputIsBravectoValue == "1" ? selectedQuantoTempo.value : null,
      isEmail: acceptNews,
      addressId: addressesId,
    };

    Object.keys(fields).map((i) => {
      if (
        !fields[i] &&
        i !== "isEmail" &&
        i !== "yearBirth" &&
        i !== "gender" &&
        i !== "city" &&
        i !== "state" &&
        i !== "petType" &&
        i !== "isBravecto" &&
        i !== "DaysFromLatestBravectoHandling" &&
        i !== "complement" &&
        i !== "addressId"
      ) {
        formErrors[i] = true;
      }
      return formErrors[i];
    });

    if (Object.keys(formErrors).length > 0) {
      setHasFormErrors(formErrors);
      console.log(formErrors)
      window.scrollTo(0, 0);
      setSubmitting(false);
      return false;
    } else {
      fields.id = props.user.id;
      return fields;
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitSuccess(null);
    setHasFormErrors(false);
    setHasRequestErrors(false);
    setSubmitting(true);
    const fields = formValidation();

    if (fields) {
      putEditCustomer(fields, props.user.token)
        .then((res) => {
          if (res && res.status === 200) {
            setSubmitSuccess(true);
            setSubmitting(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });

            setTimeout(() => {
              history.push("/meus-pets");
            }, 1000);
          }
        })
        .catch((e) => {
          let error = "";
          console.log(e.response);
          setHasRequestErrors(
            "Ocorreu um erro ao atualizar seu perfil, tente novamente"
          );
          setSubmitting(false);
        });
    }
  }

  const [modalClosed, setModalClosed] = useState(true);
  function handleDeleteProfile() {
    setModalClosed(false);
  }

  const [invalidCep, setInvalidCep] = useState(false);
  const [inputCepValue, setInputCepValue] = useState("");
  const [inputCep, setInputCep] = useState();

  useEffect(() => {
    setInputCep({
      type: "text",
      name: "cep",
      value: inputCepValue,
      minLength: 8,
      maxLength: 8,
      onChange: function (value) {
        setInputCepValue(value.replace(/[^0-9]/g, ''));
        return value.replace(/[^0-9]/g, '');
      },
      onBlur: function (e) {
        e.target.value.length !== 8 ? setInvalidCep(true) : setInvalidCep(false);
      },
    })
  }, [inputCepValue])

  const [invalidEndereco, setInvalidEndereco] = useState(false);
  const [inputEnderecoValue, setInputEnderecoValue] = useState("");
  const [inputEndereco, setInputEndereco] = useState();

  useEffect(() => {
    setInputEndereco({
      type: "text",
      name: "endereco",
      value: inputEnderecoValue,
      minLength: 3,
      maxLength: 100,
      onChange: function (value) {
        setInputEnderecoValue(value);
        return value;
      },
      onBlur: function (e) {
        e.target.value.length < 3 ? setInvalidEndereco(true) : setInvalidEndereco(false);
      }
    });
  }, [inputEnderecoValue]);

  const [invalidNumero, setInvalidNumero] = useState(false);
  const [inputNumeroValue, setInputNumeroValue] = useState("");
  const [inputNumero, setInputNumero] = useState();

  useEffect(() => {
    setInputNumero({
      type: "text",
      name: "numero",
      value: inputNumeroValue,
      minLength: 1,
      maxLength: 10,
      onChange: function (value) {
        setInputNumeroValue(value);
        return value;
      },
      onBlur: function (e) {
        e.target.value.length < 1 ? setInvalidNumero(true) : setInvalidNumero(false);
      }
    })
  }, [inputNumeroValue])

  const [inputComplementoValue, setInputComplementoValue] = useState("");
  const [inputComplemento, setInputComplemento] = useState();

  useEffect(() => {
    setInputComplemento({
      type: "text",
      name: "complemento",
      value: inputComplementoValue,
      maxLength: 50,
      onChange: function (value) {
        setInputComplementoValue(value);
        return value;
      }
    })
  }, [inputComplementoValue])



  const [userInputs, setUserInputs] = useState('')

  useEffect(() => {
    console.log(Number(userInputs))
  }, [userInputs])

  return (
    <div id="EditarMeuPerfil">
      <div id="skip">
        <a
          href={
            pageLoaded
              ? "#EditarMeuPerfil__container"
              : "#EditarMeuPerfil__preLoader"
          }
        >
          Pular para o conteúdo principal da página
        </a>
      </div>
      <h1 className="sr-only">Minhas Compras - Clube de Vantagens Bravecto</h1>
      <ModalDeleteProfile
        setModalClosed={setModalClosed}
        modalClosed={modalClosed}
        userId={props.user.id}
        token={props.user.token}
      />
      <ModalChangePassword
        setModalClosed={setModalNewPassClosed}
        modalClosed={modalNewPassClosed}
        userId={props.user.id}
        token={props.user.token}
      />
      <Header />
      {submitSuccess === true && (
        <div id="EditarMeuPerfil__success">
          <div id="success__container">
            <FaRegCheckCircle />
            <div id="success__text">
              <span id="success__text--little">
                Perfil atualizado <br id="noDesktop" /> <b>com sucesso</b>
              </span>
            </div>
          </div>
        </div>
      )}
      {!pageLoaded && (
        <div id="EditarMeuPerfil__preLoader">
          <Loader />
        </div>
      )}
      {pageLoaded && (
        <div id="EditarMeuPerfil__container">
          <div id="desktop-percentage" style={{backgroundColor: '#FFA700', display: "flex", width: '100%', maxWidth: '350px', padding: '8px', marginTop: '4rem', borderRadius: '64px', gap: '20px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'}}> 
            <CircularProgressBar radius={40} maxValue={10} percentage={userInputs} selectedValue={Math.floor(userInputs / 10)} inactiveStrokeColor="white" activeStrokeColor='#E11959' backgroundColor="#FFA700" textColor="white" strokeWidth={6} valueFontSize={20} anticlockwise={true} />
            <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center', textAlign: 'start', width: '60%'}}>
              <p style={{color: '#805196', fontWeight: 600, fontSize: '18px'}}>Complete seu cadastro</p>
              <p style={{color: 'white', fontSize: '16px'}}>e participe de promoções exclusivas!</p>
            </div>
          </div>
          <div id="mobile-percentage" style={{backgroundColor: '#FFA700', display: "flex", width: '100%', maxWidth: '300px', padding: '8px', marginTop: '4rem', borderRadius: '64px', gap: '20px', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'}}> 
            <CircularProgressBar radius={30} maxValue={10} percentage={userInputs} selectedValue={Math.floor(userInputs / 10)} inactiveStrokeColor="white" activeStrokeColor='#E11959' backgroundColor="#FFA700" textColor="white" strokeWidth={6} valueFontSize={16} anticlockwise={true} />
            <div style={{display: "flex", flexDirection: 'column', justifyContent: 'center', textAlign: 'start', width: '60%'}}>
              <p style={{color: '#805196', fontWeight: 600, fontSize: '16px'}}>Complete seu cadastro</p>
              <p style={{color: 'white', fontSize: '12px'}}>e participe de promoções exclusivas!</p>
            </div>
          </div>
          <div id="title">
            <div id="main-title">
              <span>Seu perfil</span>
            </div>
            {/* <div id="info">
                            <span>Troque informações pertinentes a sua pessoa.</span>
                        </div>  */}
          </div>
          <div
            id="error__main"
            style={{ display: hasFormErrors ? "flex" : "none" }}
          >
            <div>
              <span id="title">ERRO NO CADASTRO</span>
            </div>
            <div>
              <span id="info">
                Por favor, corrija as informações dos
                <br /> campos assinalados em Vermelho.
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div id="white-card" className="yellow-mark">
              <div id="row">
                <div id="content">
                  <span>Nome completo:*</span>
                </div>
                <Input {...inputName} />
                <div
                  id="error__field"
                  style={{ display: hasFormErrors.name ? "flex" : "none" }}
                >
                  <span>{hasFormErrors.name && "Nome inválido"}</span>
                </div>
              </div>
              <div id="row">
                <div id="content">
                  <span>E-mail:*</span>
                </div>
                <Input {...inputEmail} />
                {emailListFiltered.length > 0 && !emailMask && (
                  <div id="emailSugg">
                    <div id="emailSugg__container">
                      {emailListFiltered.map((i) => {
                        return (
                          <input
                            readOnly
                            type="text"
                            key={i}
                            defaultValue={`@${i}`}
                            onClick={handleEmailSugg}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                <div
                  id="error__field"
                  style={{ display: hasFormErrors.email ? "flex" : "none" }}
                >
                  <span>E-mail não válido</span>
                </div>
                <div
                  id="error__field"
                  style={{ display: !emailMask ? "flex" : "none" }}
                >
                  <span>E-mail não válido</span>
                </div>
              </div>
              <div id="row">
                <div id="content">
                  <span>CPF:*</span>
                </div>
                <Input {...inputCpf} />
                <div
                  id="error__field"
                  style={{ display: hasFormErrors.document ? "flex" : "none" }}
                >
                  <span>CPF não válido</span>
                </div>
              </div>
              <div id="row">
                <div id="content">
                  <span>Nascimento:</span>
                </div>
                <Input alert {...inputYearBirth} />
                <div id="error__field" style={{ display: hasFormErrors.yearBirth ? 'flex' : 'none' }}>
                  <span>data de nascimento inválido</span>
                </div>
              </div>
              <div id="row">
                <div id="content">
                  <span>Sexo:</span>
                </div>
                <div id="container__input--radio">
                  <div id="input--radio__content" className="two-radios">
                    <input
                      id="input--1"
                      type="radio"
                      name="gender"
                      value="M"
                      onChange={(e) => {
                        setInputGenderValue(e.target.value);
                      }}
                      checked={inputGenderValue === "M" ? true : false}
                    />
                    <label htmlFor="input--1">Masculino</label>
                  </div>
                  <div id="input--radio__content" className="two-radios">
                    <input
                      id="input--2"
                      type="radio"
                      name="gender"
                      value="F"
                      onChange={(e) => {
                        setInputGenderValue(e.target.value);
                      }}
                      checked={inputGenderValue === "F" ? true : false}
                    />
                    <label htmlFor="input--2">Feminino</label>
                  </div>
                  <div id="input--radio__content" className="two-radios">
                    <input
                      id="input--3"
                      type="radio"
                      name="gender"
                      value="N/A"
                      onChange={(e) => {
                        setInputGenderValue(e.target.value);
                      }}
                      checked={inputGenderValue === "N/A" ? true : false}
                    />
                    <label htmlFor="input--3">Prefiro não informar</label>
                  </div>
                </div>
                {/*
                            <div id="error__field" style={{display: hasFormErrors.gender ? 'flex' : 'none'}}>
                                <span>Favor selecionar uma opção</span>
                            </div>
                            */}
              </div>
            </div>
            <div id="white-card">
              <div id="row">
                <div id="content">
                  <span>CEP*</span>
                </div>
                <Input alert {...inputCep} />
                <div
                  id="error__field"
                  style={{ display: invalidCep ? "flex" : "none" }}
                >
                  <span>Cep não válido</span>
                </div>
              </div>

              <div id="double-row" style={{display: "flex", gap: "20px"}}>
                <div id="row">
                <div id="content">
                  <span>Estado:*</span>
                </div>
                {statesLoaded ? (
                  <SelectCustom {...selectEstado} />
                ) : (
                  <div id="EditarMeuPerfil__preLoader">
                    <Loader />
                  </div>
                )}
                {/*
                            <div id="error__field" style={{display: hasFormErrors.state ? 'flex' : 'none'}}>
                                <span>Selecione seu estado</span>
                            </div>
                            */}
                </div>
                <div id="row">
                <div id="content">
                  <span>Cidade:*</span>
                </div>
                {cityLoaded ? <SelectCustom {...selectCidade} /> : <div></div>}
                {/*
                            <div id="error__field" style={{display: hasFormErrors.city ? 'flex' : 'none'}}>
                                <span>Selecione sua cidade</span>
                            </div>
                            */}
                </div>
              </div>

              <div id="row">
                <div id="content">
                  <span>Endereço:*</span>
                </div>
                <Input alert {...inputEndereco} />
                <div
                  id="error__field"
                  style={{ display: invalidEndereco ? "flex" : "none" }}
                >
                  <span>{invalidEndereco && "Endereço inválido"}</span>
                </div>
              </div>

              <div id="double-row" style={{display: "flex", gap: "20px"}}>
                <div id="row">
                  <div id="content">
                    <span>Número:*</span>
                  </div>
                  <Input alert {...inputNumero} />
                  <div
                    id="error__field"
                    style={{ display: invalidNumero ? "flex" : "none" }}
                  >
                    <span>{invalidNumero && "Número inválido"}</span>
                  </div>
                </div>
                <div id="row">
                  <div id="content">
                    <span>Complemento:</span>
                  </div>
                  <Input {...inputComplemento} />
                </div>
              </div>
              <div id="row">
                <div id="content">
                  <span>Telefone:*</span>
                </div>
                <Input {...inputPhone} />
                <div
                  id="error__field"
                  style={{ display: hasFormErrors.phoneNumber ? "flex" : "none" }}
                >
                  <span>Telefone não válido</span>
                </div>
              </div>
              <div id="row">
                <div id="content">
                  <span>Eu tenho:</span>
                </div>
                <div id="container__input--radio">
                  <div id="input--radio__content">
                    <input
                      id="input--euTenho1"
                      type="radio"
                      name="euTenho"
                      value="1"
                      onChange={(e) => {
                        setInputPetTypeValue(e.target.value);
                      }}
                      checked={parseInt(inputPetTypeValue) === 1 ? true : false}
                    />
                    <label htmlFor="input--euTenho1">Cão</label>
                  </div>
                  <div id="input--radio__content">
                    <input
                      id="input--euTenho2"
                      type="radio"
                      name="euTenho"
                      value="2"
                      onChange={(e) => {
                        setInputPetTypeValue(e.target.value);
                      }}
                      checked={parseInt(inputPetTypeValue) === 2 ? true : false}
                    />
                    <label htmlFor="input--euTenho2">Gato</label>
                  </div>
                  <div id="input--radio__content">
                    <input
                      id="input--euTenho3"
                      type="radio"
                      name="euTenho"
                      value="3"
                      onChange={(e) => {
                        setInputPetTypeValue(e.target.value);
                      }}
                      checked={parseInt(inputPetTypeValue) === 3 ? true : false}
                    />
                    <label htmlFor="input--euTenho3">Cão e Gato</label>
                  </div>
                </div>
                {/*
                            <div id="error__field" style={{display: hasFormErrors.petType ? 'flex' : 'none'}}>
                                <span>Favor selecionar uma opção</span>
                            </div>
                            */}
              </div>
              {/* <div id="row">
                            <div id="content">
                                <span>
                                    Já usou Bravecto antes:
                                </span>
                            </div>
                            <div id="container__input--radio">
                                <div id="input--radio__content">
                                    <input id="input--usouBravecto1" type="radio" name="usouBravecto" value="1" onChange={(e) => {
                                        setInputUsedBravectoValue(e.target.value)
                                    }} 
                                    checked={parseInt(inputUsedBravectoValue) === 1 ? true : false}
                                    /> 
                                    <label htmlFor="input--usouBravecto1">Sim</label>
                                </div>
                                <div id="input--radio__content">
                                    <input id="input--usouBravecto2" type="radio" name="usouBravecto" value="2" onChange={(e) => {
                                        setInputUsedBravectoValue(e.target.value)
                                    }} 
                                    checked={parseInt(inputUsedBravectoValue) === 2 ? true : false}
                                    /> 
                                    <label htmlFor="input--usouBravecto2">Não</label>
                                </div>
                            </div>

                            <div id="error__field" style={{display: hasFormErrors.usedBravecto ? 'flex' : 'none'}}>
                                <span>Favor selecionar uma opção</span>
                            </div>

                        </div> */}
              <div
                id="error__main"
                style={{ display: hasRequestErrors ? "flex" : "none" }}
              >
                <div>
                  <span id="title">ERRO NO CADASTRO</span>
                </div>
                <div>
                  <span id="info">{hasRequestErrors}</span>
                </div>
              </div>
            </div>
            <button
              type="button"
              id="row-grey"
              onClick={handleOpenPassword}
            >
              Alterar minha senha
              <MdKeyboardArrowRight size={22} color="#404040" />
            </button>
            <button
              type="button"
              id="row-grey"
              onClick={() => history.push("/meus-pets")}
            >
              Ver meus pets cadastrados
              <MdKeyboardArrowRight size={22} color="#404040" />
            </button>
            <button
              type="button"
              id="row-grey"
              onClick={handleDeleteProfile}
            >
              Excluir meu perfil
              <MdKeyboardArrowRight size={22} color="#404040" />
            </button>
            {/* <div id="row">
              <div id="container__input--manual">
                <div id="content__input--manual">
                  <div
                    className={
                      acceptNews
                        ? "input--manual__checked"
                        : "input--manual__unchecked"
                    }
                    onClick={() => {
                      setAcceptNews(!acceptNews);
                    }}
                  >
                    {acceptNews ? <MdCheck /> : ""}
                  </div>
                  <span>Desejo receber novidades</span>
                </div>
              </div>
            </div> */}
            <div id="row-submit">
              <Link id="arrow-back" to="/clube-de-vantagens">
                <MdKeyboardArrowLeft size={32} color="#404040" />
              </Link>
              <button
                type="submit"
                id="EditarMeuPerfil__btn-salvar"
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? <LoaderForButton />
                  :
                  <>
                    Salvar alterações
                    <MdKeyboardArrowRight size={22} color="#fff" />
                  </>
                }
              </button>
            </div>
            <span id="text-tip">* Campos de preenchimento obrigatórios</span>
          </form>
        </div>
      )}
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth }, props) => {
  return {
    ...auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(EditarMeuPerfil);
