import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";

import Connect from "../../store/config/connect";

import "./Modal.scss";

const Modal = (props) => {
  return (
    <div id="Modal" style={{ display: props.modalClose ? "flex" : "none" }}>
      <CSSTransition
        in={props.modalClose}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div id="Modal__container">
          <div id="Modal__container--close">
            <span><b>{props.title}</b></span>
            <span id="mdClose" onClick={() => {
              props.setModalClose(false);
            }}>
            </span>
          </div>
          <div
            id="Modal__container--desc"
            dangerouslySetInnerHTML={{ __html: props.modalContent }}
          ></div>
          <div id="Modal__container--save">
            {props.modalRef && props.modalRef.ref && (
              <Link to={props.modalRef.route}>
                <button
                  type="button"
                  onClick={() => {
                    props.action();
                    props.setModalClose(false);
                  }}
                >
                  {props.modalButtonValue}
                </button>
              </Link>
            )}
            {props.modalRef && !props.modalRef.ref && (
              <button
                type="button"
                onClick={() => {
                  props.action();
                  props.setModalClose(false);
                }}
              >
                {props.modalButtonValue}
              </button>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Connect()(Modal);
