import React, { useEffect, useMemo, useState } from "react";

import "./RankingMomentosBravecto.css";
import Header from "../../Header/Header.js";
import Footer from "../../Footer/Footer.js";
import {
  getRanking,
  searchRanking,
  getDownloadRankingLink,
  getDownloadRankingGroupByCepLink,
} from "../../../services/services";

import bannerDesk from "../../../images/MomentosBravecto10Anos/BannerRankingDesktop.webp";
import bannerMob from "../../../images/MomentosBravecto10Anos/BannerRankingMobile.webp";
import iconExport from "../../../images/PromocaoMomentosBravecto/exportar-arquivo.webp";
import Input from "../../Utils/Input/Input";

const RankingMomentosBravecto = () => {
  document.title = "Clube Bravecto | Ranking Momentos Bravecto";
  const apiLink = getDownloadRankingLink();
  const apiLinkCep = getDownloadRankingGroupByCepLink();

  var url = window.location.href;
  const api = //url.includes('localhost') ||
    url.includes("clubebravecto") && !url.includes("shiftinc")
      ? "https://api.clubebravecto.com.br/api/v1/store/"
      : "https://clubebravectoapi.shiftinc.com.br/api/v1/store/";

  const [ranking, setRanking] = useState([]);
  function responseRanking() {
    const result = getRanking();
    const resultData = result
      .then((res) => {
        if (res && res.status === 200) {
          const responseRanking = JSON.parse(res.request.response);
          setRanking(responseRanking);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    return resultData;
  }

  useEffect(() => {
    responseRanking();
  }, []);

  function rankingFilter(item, search) {
    return item.city.toLowerCase().includes(search.toLowerCase());
  }

  const [inputSearchValue, setInputSearchValue] = useState("");
  const [inputSearch] = useState({
    type: "text",
    name: "search",
    placeholder: "Buscar por: Posição, nome, etc...",
    replaceValueOnChange: true,
    onChange: function (value) {
      return setInputSearchValue(value);
    },
  });

  const rankingFiltred = useMemo(() => {
    if (isNaN(inputSearchValue.trim())) {
      return inputSearchValue.trim()
        ? ranking.ranking.filter((item) =>
            rankingFilter(item, inputSearchValue)
          )
        : ranking.ranking;
    } else {
      return inputSearchValue.trim()
        ? ranking.ranking.filter(
            (item) => item.position === Number(inputSearchValue.trim())
          )
        : ranking.ranking;
    }
  }, [inputSearchValue, ranking]);

  function onClickSearch() {
    const result = searchRanking(inputSearchValue);
    const resultData = result
      .then((res) => {
        if (res && res.status === 200) {
          const responseRanking = JSON.parse(res.request.response);
          setRanking(responseRanking);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return resultData;
  }

  return (
    <div id="RankingMomentosBravecto">
      <Header />

      {/* Banner */}
      <section className="RankingMomentosBravecto-container">
        <img
          src={bannerDesk}
          alt="RankingMomentosBravecto"
          width="100%"
          className="banner-desk"
        />
        <img
          src={bannerMob}
          alt="RankingMomentosBravecto"
          width="100%"
          className="banner-mob"
        />
      </section>

      {/* Total NFS */}
      <section className="RankingMomentosBravecto-container-nf">
        <div className="RankingMomentosBravecto-sub-container-nf">
          <div className="total-nfs">
            <div className="total-nf">
              <p>{ranking.sumTotalNewMemberPoints}</p>
              <span>Novos Usuários</span>
            </div>
            <div className="total-nf">
              <p>{ranking.sumTotalAttPoints}</p>
              <span>Cadastros Completos e atualizados</span>
            </div>
          </div>
        </div>
      </section>

      {/* Ranking ganhadores */}
      <section className="RankingMomentos-container">
        <div className="RankingMomentos-sub-container">
          {/* <h1>Confira os ganhadores</h1> */}
          <h1>Ranking</h1>
          <h2>Confira as cidades com mais Novos Cadastros e atualizações</h2>
          <div className="RankingMomentos-inputSearch">
            <span onClick={() => onClickSearch()}></span>
            <Input {...inputSearch} />
          </div>
          <div className="RankingMomentos-ganhadores-container">
            {/* {ranking?.stores?.map((item, i) => (
              <div key={i} className={["RankingMomentos-ganhadores",
                item.rank === 1 ? "primeiroLugar" : "",
                item.rank === 2 ? "segundoLugar" : "",
                item.rank === 3 ? "terceiroLugar" : ""
              ].join(" ")}>
                <h2>{item.rank}º Lugar</h2>
                <p>{item.nf_generate_count}</p>
                <span>Notas</span>
                <div className="RankingMomentos-ganhadores-infos">
                  <p>{item.storeName === '' ? 'SEM NOME' : item.storeName}</p>
                  <span>{item.cnpj}</span>
                  <span className="valor-premio">R$3.360,00</span>
                </div>
              </div>
            )).slice(0, 3)} */}
          </div>
        </div>
      </section>

      {/* Lista lojas rankings */}
      <section className="RankingMomentos-container">
        <div className="RankingMomentos-classificacao-container">
          {/* INPUT MOBILE QUANDO A PROMO ACABAR */}
          {/* <div className="RankingMomentos-inputSearch">
            <div className="total-nf-cadastradas">
              <p>Total de NFs cadastradas:</p>
              <span>{ranking.nf_total_count}</span>
            </div>
            <div className="inputSearch">
              <span onClick={() => onClickSearch()}></span>
              <Input {...inputSearch} />
            </div>
          </div> */}
          {/* INPUT MOBILE QUANDO A PROMO ACABAR */}
          <div className="Ranking-classificacao-sub-container">
            <div className="Ranking-classificacao1">
              <span>Posição</span>
              <p id="uf">UF</p>
              <p>Cidade</p>
            </div>
            <div className="Ranking-classificacao2">
              <p id="produtos">Atualizações</p>
              <p id="notas">Novos usuários</p>
            </div>
          </div>
          {/* Lista Ranking */}
          {rankingFiltred?.map((item, i) => (
            <div
              key={i}
              className={
                item.position < 6 ? "Ranking-loja topFive" : "Ranking-loja"
              }
            >
              <div className="Ranking-valores1">
                <span>{item.position}</span>
                <p id="uf">{item.uf}</p>
                <p>{item.city}</p>
              </div>
              <div className="Ranking-valores2">
                <p id="produtos">{item.attTotalPoint}</p>
                <span id="notas">{item.newMemberTotalPoint}</span>
              </div>
            </div>
          ))}
          {rankingFiltred?.length === 0 && (
            <p className="zeroResult">
              Nenhum resultado encontrado com esse nome
            </p>
          )}
          <div className="icon-export">
            <a href={`${apiLink}`} target="_blank" rel="noopener noreferrer">
              <span>Baixar ranking</span>
              <img src={iconExport} alt="icon-export" />
            </a>
          </div>

          <div className="icon-export">
            <a href={`${apiLinkCep}`} target="_blank" rel="noopener noreferrer">
              <span>Baixar ranking agrupado por CEP</span>
              <img src={iconExport} alt="icon-export" />
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default RankingMomentosBravecto;
