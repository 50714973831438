import React, { useState } from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ComprarOnline from "./ComprarOnline/ComprarOnline";
import ComprarFisica from "./ComprarFisica/ComprarFisica";

import "./OndeComprar.css";

const OndeComprar = () => {
  document.title = "Clube Bravecto | Onde Comprar";

  const [comprarOnlineStatus, setComprarOnlineStatus] = useState(false);
  const [hideOptions, setHideOptions] = useState(false);

  function handleComprarOnlineStatus(arg) {
    return setComprarOnlineStatus(arg);
  }

  function handleHideOptions(arg) {
    return setHideOptions(arg);
  }

  return (
    <div id="OndeComprar">
      <Header />
      <div id="container">
        <div id="title">
          <div id="main-title">
            <span>Onde Comprar</span>
          </div>
          <div id="info">
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </span>
          </div>
        </div>
        {!hideOptions ? (
          <div id="options">
            <div id="option">
              <figure id="optionIconFigure">
                <img
                  alt="Comprar online"
                  id="optionIconImage"
                  src={require("../../images/onde-comprar-shop-online.svg")}
                />
              </figure>
              <button
                id="optionButton"
                onClick={() => {
                  handleHideOptions(true);
                  handleComprarOnlineStatus(true);
                }}
              >
                Comprar online
              </button>
            </div>
            <div id="option">
              <figure id="optionIconFigure">
                <img
                  alt="Comprar em loja física"
                  id="optionIconImage"
                  src={require("../../images/onde-comprar-shop.svg")}
                />
              </figure>
              <button
                id="optionButton"
                onClick={() => {
                  handleHideOptions(true);
                  handleComprarOnlineStatus(false);
                }}
              >
                Comprar em
                <br /> loja física
              </button>
            </div>
          </div>
        ) : comprarOnlineStatus ? (
          <ComprarOnline handleHideOptions={handleHideOptions} />
        ) : (
          <ComprarFisica handleHideOptions={handleHideOptions} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default OndeComprar;
