import React from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import Connect from "../../../store/config/connect";

import "./ModalReminderPet.css";
import modalCadastrarPet from '../../../images/modal-cadastro-pet-sucess.png';
import modalCadastrarMobile from '../../../images/modal-cadastro-pet-sucess-mobile.png';
import closeModal from '../../../images/close-modal-cadastrar-pet.png';


const ModalReminderPet = (props) => {

  return (
    <div
      id="modal--reminder--pet"
      style={{ display: props.modalClose ? "flex" : "none" }}
    >
      <CSSTransition
        in={props.modalClose}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div id="modal--reminder--pet__container">
          <div id="modal--reminder--pet__container--close">
            <button type="button" onClick={() => props.setModalClose(false)}><img src={closeModal} alt="icon-close-modal" /></button>
          </div>
          <img src={modalCadastrarPet} alt="img-cadastrar-pet" id="img-cadastrar-desk" />
          <img src={modalCadastrarMobile} alt="img-cadastrar-pet" id="img-cadastrar-mobile" />
          <div id="modal--reminder--pet__container--desc">
            <div id="modal--reminder--pet__container--info">
              <h1>Parabéns,</h1>
              <p>
                agora você é um <span>membro 5 estrelas</span> e já pode aproveitar os <span>melhores benefícios</span> do Clube de Vantagens Bravecto, que foram pensados para você e para o seu pet.
              </p>
            </div>
            <div id="modal--reminder--pet__container--save">
              <Link to="/meus-beneficios">Aproveitar agora</Link>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Connect()(ModalReminderPet);
