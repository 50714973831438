/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { MdClose } from "react-icons/md";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Connect from "../../store/config/connect";
import { NewPet } from "../../store/helper";

import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";
import SelectCustom from "../Utils/Select/Select";
import bravectoLogo from '../../images/bravecto-logo.svg';

import {
  getPetBreeds,
  postRegisterPet,
  getPetKg,
} from "../../services/services";

import ModalPhoto from "./ModalPhoto/ModalPhoto";

import "./CadastrarPet.css";
import { Link } from "react-router-dom";
import FormCadastrarPet from "./FormCadastrarPet";
import ModalReminderPet from "./ModalReminderCadastroPet/ModalReminderPet";

const CadastrarPet = (props) => {
  document.title = "Clube Bravecto | Cadastrar Pet";

  const [isSubmitting, setSubmitting] = useState(false);

  const [petsList, setPetsList] = useState([{}]);
  const [petsIndices, setPetsIndices] = useState([0]);


  function addNewPet() {
    dataLayer.push({
      event: "cadastro_click_adicionar_pet"
    });

    setPetsList([
      ...petsList,
      null,
    ])
    setPetsIndices([
      ...petsIndices,
      petsIndices.length,
    ])
  }
  const updatePetIndex = useCallback(function (index, pet) {
    const newList = [...petsList]
    newList[index] = pet
    setPetsList(newList)
    // console.log(`CadastrarPet: newList updated`, newList)
  }, [petsList])

  const removePetIndex = useCallback(function (index) {
    const newList = [...petsList]
    newList[index] = undefined
    setPetsList(newList)
    const newIndices = [...petsIndices]
    newIndices[index] = undefined
    setPetsIndices(newIndices)
    console.log(`CadastrarPet: removePet newList updated`, newList, newIndices)
  }, [petsList, petsIndices])

  useEffect(() => {
    console.log(`CadastrarPet: petsList updated effect`, petsList)
  }, [petsList])

  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [hasRequestErrors, setHasRequestErrors] = useState(false);

  function formValidation() {
    const petsValid = []
    let errorFound
    for (const pet of petsList) {
      if (!pet) continue;
      const petValid = pet.formValidation()
      if (petValid) {
        petsValid.push(petValid)
      } else {
        errorFound = pet
        break
      }
    }
    console.log(`CadastrarPet: formValidation`, errorFound, petsValid)
    return null == errorFound
      ? { petsValid, CustomerId: props.user.id }
      : false
  }

  function dataLayerBackRegisterPet() {
    dataLayer.push({
      event: "cadastro_pet_click_voltar"
    });
  }

  function handleSubmit(e, addNewPet) {
    dataLayer.push({
      event: "cadastro_pet_click_salvar"
    });
    e.preventDefault();
    setHasFormErrors(false);
    setHasRequestErrors(false);
    setSubmitting(true);
    const fields = formValidation();

    if (fields) {
      console.log(`CadastrarPet: handleSubmit validou com sucesso`, fields)

      let bodyFormData = new FormData();
      bodyFormData.set("CustomerId", fields.CustomerId);
      fields.petsValid.forEach((pet, i) => {
        bodyFormData.set(`pets[${i}].name`, pet.name);
        bodyFormData.set(`pets[${i}].petType`, pet.petType);
        bodyFormData.set(`pets[${i}].petGender`, pet.petGender);
        bodyFormData.set(`pets[${i}].breed`, pet.breed);
        bodyFormData.set(`pets[${i}].DateOfBirth`, pet.DateOfBirth);
        bodyFormData.set(`pets[${i}].kg`, pet.kg);
        bodyFormData.set(`pets[${i}].reminderDate`, pet.reminderDate);
        bodyFormData.set(`pets[${i}].imageBase64`, pet.imagePath);

      })

      const token = props.user.token;
      const isNotOwnRoute = props.isNotOwnRoute ? props.isNotOwnRoute : false;
      postRegisterPet(bodyFormData, token)
        .then((res) => {
          if (res && res.status === 200) {
            if (addNewPet) {
              setSubmitting(false);
              // setEditedImage(null);
              // setInputNameValue("");
              // setInputBirthdayValue("");
              // setInputGenderValue("");
              // setInputPetTypeValue("");
              // setSelectedBreed("");
              // setSelectedPetKg(false);
              // setPetKgOptions([]);
              // setSelectBreed({ ...selectBreed, options: [], value: "" });
              // setInputUseDateValue("");
            } else if (isNotOwnRoute) {
              props.callback();
            } else {
              if (res.data.data.petPromotion === true) {
                props.history.push("/meus-pets?promo");
              } else {
                dataLayer.push({
                  event: "cadastro_pet_sucesso"
                });
                props.history.push("/meus-pets");
              }
              props.dispatch(NewPet(true));
            }
          } else {
            setSubmitting(false);
          }
        })
        .catch((e) => {
          console.log(e.response);
          let error = "";
          setHasRequestErrors(error);
          setSubmitting(false);
        });
    } else {
      console.warn(`CadastrarPet: handleSubmit falhou`)
      setSubmitting(false);
    }
  }

  return (
    <React.Fragment>
      <Header />
      <div id="CadastrarPet">
        <div id="skip">
          <a href="#cadastrarPet__container">
            Pular para o conteúdo principal da página
          </a>
        </div>
        <h1 className="sr-only">
          Cadastrar Pets - Clube de Vantagens Bravecto
        </h1>
        <div id="cadastrarPet__container">
          <div id="title">
            <div id="main-title">
              <span>Cadastrar pet</span>
            </div>
          </div>
          <div
            id="error__main"
            style={{ display: hasFormErrors ? "flex" : "none" }}
          >
            <div>
              <span id="title">ERRO NO CADASTRO</span>
            </div>
            <div>
              <span id="info">
                Por favor, corrija as informações dos
                <br /> campos assinalados em Vermelho.
              </span>
            </div>
          </div>
        </div>
      </div>
      {petsIndices.filter(n => null != n).map((pi) => <FormCadastrarPet updatePet={updatePetIndex} removePet={removePetIndex} petIndex={pi} petCount={petsIndices.filter(n => null != n).length} key={`pet${pi}`} />)}
      <div id="CadastrarPet">
        <div id="cadastrarPet__container">
          <div id="btn-add-pet">
            <button type="button" id="button-add-pet" onClick={() => addNewPet()}>Adicionar outro pet</button>
          </div>
          <div id="btn-submit">
            <Link to="/meus-pets" id="btn-voltar" onClick={() => dataLayerBackRegisterPet()}></Link>
            <button
              type="submit"
              id="btn-cadastrarPet"
              disabled={isSubmitting}
              onClick={(ev) => handleSubmit(ev, false)}
            >
              {isSubmitting ? <LoaderForButton /> : <span>Salvar</span>}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment >
  )
}

const mapStateToProps = ({ auth, helper }, props) => {
  return {
    ...auth,
    ...props,
    ...helper,
  };
};

export default Connect(mapStateToProps)(CadastrarPet);
