import React from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./Page404.css";

function Page404() {
  return (
    <div id="page404">
      <Header />
      <div id="page404Container">
        <div id="page404Title">
          <span>Erro</span>
        </div>
        <div id="page404SubTitle">
          <span>404</span>
        </div>
        <span id="page404Text">
          Desculpe, a página que você está procurando não pode ser encontrada.
        </span>
      </div>
      <Footer />
    </div>
  );
}

export default Page404;
