/* eslint-disable no-undef */
import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { MdClose } from "react-icons/md";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Connect from "../../store/config/connect";
import { NewPet } from "../../store/helper";

import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";
import SelectCustom from "../Utils/Select/Select";
import bravectoLogo from '../../images/bravecto-logo.svg';
import iconRemove from '../../images/icon-remove.svg';

import {
  getPetBreeds,
  postRegisterPet,
  getPetKg,
} from "../../services/services";

import ModalPhoto from "./ModalPhoto/ModalPhoto";

import "./CadastrarPet.css";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";

const FormCadastrarPet = (props) => {
  document.title = "Clube Bravecto | Cadastrar Pet";

  const boxEl = useRef(null);
  const imageInputEl = useRef(null);
  const [useBravecto, setUseBravecto] = useState("");
  const [breedsLoaded, setBreedsLoaded] = useState(true);
  const [selectBreedOptions, setSelectBreedOptions] = useState([]);

  const [selectedBreed, setSelectedBreed] = useState("");
  const [selectBreed, setSelectBreed] = useState(null);

  const [errorPhoto, setErrorPhoto] = useState("");
  const [editedImage, setEditedImage] = useState(null);
  const [modalClose, setModalClose] = useState(false);
  const [modalCloseForm, setModalCloseForm] = useState(false);
  const [image, setImage] = useState("");

  const [modalContent] = useState(
    `<span>Por favor, corrija ou complete <br /> os campos destacados.</span>`
  );
  const [title] = useState(
    `Preenchimento incorreto`
  );

  function handleNewImage(e) {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 2097152) {
        setErrorPhoto("Esta foto é superior a 2MB, suba outra foto");
        setImage("");
        setModalClose(false);
      } else {
        setErrorPhoto("");
        setImage(e.target.files[0]);
        setModalClose(true);
      }
    }
  }

  const [inputPetTypeValue, setInputPetTypeValue] = useState("");
  useEffect(() => {
    if (inputPetTypeValue) {
      getPetBreeds(inputPetTypeValue).then((res) => {
        if (res.status === 200) {
          // dataLayer.push({
          //   event: "cadastro_selecionou_raca_do_pet"
          // });
          let breedsOptions = [];
          setBreedsLoaded(false);
          const breeds = JSON.parse(res.request.response);
          breeds.map((i) => {
            return breedsOptions.push({
              label: i.breedName,
              value: i.breedName,
            });
          });
          setSelectedBreed("");
          setSelectBreedOptions(breedsOptions);
          setBreedsLoaded(true);
        }
      });

      getPetKg(inputPetTypeValue).then((res) => {
        if (res && res.status === 200) {
          let kgOptions = [];
          const kgResponse = JSON.parse(res.request.response);
          kgResponse.map((i) => {
            return kgOptions.push({
              label: i.kg,
              value: i.kg,
            });
          });
          setSelectedPetKg({ value: null, label: null });
          setPetKgOptions(kgOptions);
        }
      });
    }
  }, [inputPetTypeValue]);

  useEffect(() => {
    if (selectBreedOptions) {
      setSelectBreed({
        value: selectedBreed,
        placeholder: "",
        options: selectBreedOptions,
        isSearchable: false,
        noOptionsMessage: function () {
          return "Escolha uma espécie para desbloquear este campo!";
        },
        onChange: function (value) {
          dataLayer.push({
            event: "cadastro_pet_selecionou_raca"
          });
          return setSelectedBreed(value);
        },
      });
    }
  }, [selectBreedOptions, selectedBreed]);

  const [selectPetKg, setSelectPetKg] = useState(false);
  const [selectedPetKg, setSelectedPetKg] = useState(false);
  const [petKgOptions, setPetKgOptions] = useState([]);
  const [petKgLoaded, setPetKgLoaded] = useState(true);
  useEffect(() => {
    if (petKgOptions) {
      setPetKgLoaded(false);
      setSelectPetKg({
        placeholder: "",
        options: petKgOptions,
        value: selectedPetKg,
        isSearchable: false,
        noOptionsMessage: function () {
          return "Escolha uma espécie para desbloquear este campo!";
        },
        onChange: function (value) {
          dataLayer.push({
            event: "cadastro_pet_selecionou_peso"
          });
          setSelectedPetKg(value);
        },
      });
      setPetKgLoaded(true);
    }
  }, [petKgOptions, selectedPetKg]);

  const [inputNameValue, setInputNameValue] = useState("");
  const [inputName] = useState({
    type: "text",
    name: "name",
    placeholder: "Nome do seu pet*",
    replaceValueOnChange: true,
    onChange: function (value) {
      setInputNameValue(value);
      return value;
    },
    onBlur: function () {
      dataLayer.push({
        event: "cadastro_preencheu_nome_do_pet"
      });
    },
  });

  const [inputGenderValue, setInputGenderValue] = useState("");
  const [birthdayError, setBirthdayError] = useState(false);
  const [inputBirthdayValue, setInputBirthdayValue] = useState("");
  const [inputBirthday] = useState({
    type: "text",
    name: "birthday",
    maxLength: 10,
    replaceValueOnChange: true,
    placeholder: "dd/mm/aaaa",
    onChange: function (birthday) {
      birthday =
        birthday.length > 0 && birthday.slice(0, 2) > 31 ? 31 : birthday;
      birthday =
        birthday.length > 3 && birthday.slice(3, 5) > 12
          ? birthday.slice(0, 3) + 12
          : birthday;
      let v = birthday
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2");
      setInputBirthdayValue(v);
      return v;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_nascimento_do_pet"
      });
      const birthday = e.target.value.split("/").reverse().join("-"),
        today = moment();
      if (moment(birthday).isAfter(today, "days")) {
        setBirthdayError(true);
        setInputBirthdayValue(false);
      } else {
        setBirthdayError(false);
      }
    },
  });

  useEffect(() => {
    if (inputBirthdayValue === false) {
      setInputBirthdayValue('')
    }
  }, [inputBirthdayValue])

  const [useDateError, setUseDateError] = useState(false);
  const [useDateErrorMsg, setUseDateErrorMsg] = useState(false);
  const [inputUseDateValue, setInputUseDateValue] = useState("");
  console.log('data', inputUseDateValue)
  const [inputUseDate] = useState({
    type: "text",
    name: "useDate",
    maxLength: 10,
    replaceValueOnChange: true,
    placeholder: "dd/mm/aaaa",
    onChange: function (useDate) {
      useDate =
        useDate.length > 0 && useDate.slice(0, 2) > 31 ? 31 : useDate;
      useDate =
        useDate.length > 3 && useDate.slice(3, 5) > 12
          ? useDate.slice(0, 3) + 12
          : useDate;
      let v = useDate
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2");
      setInputUseDateValue(v);
      return v;
    },
    onBlur: function (e) {
      dataLayer.push({
        event: "cadastro_preencheu_ultima_data_bravecto"
      });
      const date = e.target.value.split("/").reverse().join("-"),
        today = moment();
      if (moment(date).isAfter(today, "days")) {
        setUseDateError(true);
        setInputUseDateValue(false);
      } else {
        setUseDateError(false);
        setUseDateErrorMsg(false);
      }
    },
  });

  useEffect(() => {
    if (useBravecto === 'n') {
      console.log('Voce usa bravecto?', useBravecto)
      setUseDateError(false);
      setInputUseDateValue('');
    }
    if (useBravecto === 's' && inputUseDateValue === false) {
      setUseDateError(true);
    }
    if (inputUseDateValue === false) {
      setInputUseDateValue('');
      setUseDateErrorMsg(true);
      setUseDateError(true);
    }
  }, [useBravecto, inputUseDateValue])

  const [hasFormErrors, setHasFormErrors] = useState(false);

  useEffect(() => {
    const obj = {
      selectedBreed,
      editedImage,
      inputPetTypeValue,
      selectedPetKg,
      inputNameValue,
      inputGenderValue,
      inputBirthdayValue,
      inputUseDateValue,
      useBravecto,
      formValidation,
    }
    // console.log(`FormCadastrarPet: useEffect props.updatePet`, obj)
    props.updatePet(props.petIndex, obj)
  }, [
    selectedBreed,
    editedImage,
    inputPetTypeValue,
    selectedPetKg,
    inputNameValue,
    inputGenderValue,
    inputBirthdayValue,
    inputUseDateValue,
    useBravecto,
    props.petIndex,
    formValidation,
  ])

  const formValidation = useCallback(function () {
    let formErrors = {};
    setHasFormErrors(false);
    const fields = {
      name: inputNameValue,
      petType: inputPetTypeValue,
      petGender: inputGenderValue,
      breed: selectedBreed.value,
      DateOfBirth: inputBirthdayValue,
      kg: selectedPetKg.value,
      useBravecto: useBravecto,
      useDate: inputUseDateValue,
      // CustomerId: props.user.id,
    };

    Object.keys(fields).map((i) => {
      if (
        !fields[i] &&
        // i !== 'breed' &&
        // i !== 'DateOfBirth' &&
        // i !== 'kg' &&
        // i !== 'useBravecto' &&
        // i !== 'useDate'
        !(i === 'useDate' && fields.useBravecto === 'n')
      ) {
        formErrors[i] = true;
      }
      return formErrors[i];
    });

    if (birthdayError === true) {
      formErrors.DateOfBirth = true;
    }
    console.log('useDateError', useDateError)
    if (useBravecto === 's' && useDateError === true) {
      formErrors.useDate = true;
    }

    if (Object.keys(formErrors).length > 0) {
      console.log('FormErrors', formErrors)
      setHasFormErrors(formErrors);
      setModalCloseForm(true);
      boxEl.current.scrollIntoView();
      // window.scrollTo(0, 0);
      // setSubmitting(false);
      return false;
    } else {
      // return true;

      const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
        ) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };

      if (editedImage) {
        const blob = b64toBlob(editedImage.split(",")[1], "image/jpg");
        fields.imagePath = new File([blob], "filename.jpg");
      }

      fields.reminderDate = inputUseDateValue;
      return fields;
    }
  }, [
    inputNameValue,
    inputPetTypeValue,
    inputGenderValue,
    selectedBreed,
    inputBirthdayValue,
    selectedPetKg,
    useBravecto,
    inputUseDateValue,
    editedImage,
  ])

  return (
    <React.Fragment>
      <div id="ModalError-CadastrarPet">
        <Modal
          title={title}
          modalClose={modalCloseForm}
          setModalClose={setModalCloseForm}
          modalContent={modalContent}
        />
      </div>
      <div id="CadastrarPet">
        <ModalPhoto
          setEditedImage={setEditedImage}
          modalClose={modalClose}
          setModalClose={setModalClose}
          image={image}
        />
        <div id="cadastrarPet__container">
          {/* <div
            id="error__main"
            style={{ display: hasFormErrors ? "flex" : "none" }}
          >
            <div>
              <span id="title">ERRO NO CADASTRO</span>
            </div>
            <div>
              <span id="info">
                Por favor, corrija as informações dos
                <br /> campos assinalados em Vermelho.
              </span>
            </div>
          </div> */}
          <form id="form-pet" ref={boxEl}>
            {props.isNotOwnRoute && (
              <span
                id="close"
                onClick={() => {
                  props.callback();
                }}
              >
                <MdClose size={24} />
              </span>
            )}
            <div id="row">
              <div id="content">
                <div id="select-a-photo">
                  <div
                    id="select-a-photo__container"
                    onClick={() => {
                      imageInputEl.current.click();
                    }}
                  >
                    <div id="box-photo" onClick={() => {
                      dataLayer.push({
                        event: "cadastro_pet_click_foto"
                      });
                    }}>
                      {!editedImage ? (
                        <React.Fragment>
                          <div id="box-photo--icon">
                            <span></span>
                          </div>
                        </React.Fragment>
                      ) : (
                        <span
                          id="box-photo--editedPhoto"
                          style={{ backgroundImage: `url('${editedImage}')` }}
                        ></span>
                      )}
                    </div>
                    <input
                      name="newImage"
                      ref={imageInputEl}
                      type="file"
                      accept=".jpg, .gif"
                      onChange={handleNewImage}
                    />
                  </div>
                </div>
                <div id="name-pet">
                  {/* <span>Nome do seu Pet</span> */}
                  <Input {...inputName} data-value={inputNameValue} />
                  <div
                    id="error__field"
                    style={{ display: hasFormErrors.name ? "flex" : "none" }}
                  >
                    <span>Favor digite o nome</span>
                  </div>
                </div>
                {props.petCount > 1 ?
                  <div id="btn-remove-pet">
                    <button type="button" id="button-remove-pet" onClick={() => {
                      dataLayer.push({
                        event: "cadastro_pet_click_remover"
                      });
                      props.removePet(props.petIndex)
                    }}><img src={iconRemove} alt="icon-remove" /></button>
                  </div>
                  : undefined
                }
              </div>
            </div>
            <hr id="hr-01" />
            <div id="row1">
              <div id="content">
                <div id="content-mobile">
                  <span>Sexo*</span>
                </div>
                <div id="container__input--radio">
                  <div id="input--radio__content" className="two-radios">
                    <input
                      type="radio"
                      id={`${props.petIndex}-gender-1`}
                      name={`${props.petIndex}-gender`}
                      checked={inputGenderValue === "M"}
                      value="M"
                      onChange={(e) => {
                        setInputGenderValue(e.target.value);
                        dataLayer.push({
                          event: "cadastro_selecionou_sexo_do_pet"
                        });
                      }}
                    />
                    <label htmlFor={`${props.petIndex}-gender-1`}>Macho</label>
                  </div>
                  <div id="input--radio__content" className="two-radios">
                    <input
                      type="radio"
                      id={`${props.petIndex}-gender-2`}
                      name={`${props.petIndex}-gender`}
                      value="F"
                      checked={inputGenderValue === "F"}
                      onChange={(e) => {
                        setInputGenderValue(e.target.value);
                        dataLayer.push({
                          event: "cadastro_selecionou_sexo_do_pet"
                        });
                      }}
                    />
                    <label htmlFor={`${props.petIndex}-gender-2`}>Fêmea</label>
                  </div>
                </div>
                <div
                  id="error__field"
                  style={{ display: hasFormErrors.petGender ? "flex" : "none" }}
                >
                  <span>Favor selecionar uma opção</span>
                </div>
              </div>
              <div id="content">
                <div id="content-mobile">
                  <span>Qual espécie?*</span>
                </div>
                <div id="container__input--radio">
                  <div id="input--radio__content" className="two-radios">
                    <input
                      type="radio"
                      id={`${props.petIndex}-species-1`}
                      name={`${props.petIndex}-species`}
                      value="1"
                      checked={inputPetTypeValue === "1"}
                      onChange={(e) => {
                        setInputPetTypeValue(e.target.value);
                        dataLayer.push({
                          event: "cadastro_selecionou_especie_do_pet"
                        });
                      }}
                    />
                    <label htmlFor={`${props.petIndex}-species-1`}>Cão</label>
                  </div>
                  <div id="input--radio__content" className="two-radios">
                    <input
                      type="radio"
                      id={`${props.petIndex}-species-2`}
                      name={`${props.petIndex}-species`}
                      value="2"
                      checked={inputPetTypeValue === "2"}
                      onChange={(e) => {
                        setInputPetTypeValue(e.target.value);
                        dataLayer.push({
                          event: "cadastro_selecionou_especie_do_pet"
                        });
                      }}
                    />
                    <label htmlFor={`${props.petIndex}-species-2`}>Gato</label>
                  </div>
                </div>
                <div
                  id="error__field"
                  style={{ display: hasFormErrors.petType ? "flex" : "none" }}
                >
                  <span>Favor selecionar uma opção</span>
                </div>
              </div>
            </div>
            <div id="row2">
              <div id="content">
                <div>
                  <span id="content-option">Nascimento:*</span>
                  <Input {...inputBirthday} data-value={inputBirthdayValue} />
                  <div
                    id="error__field"
                    style={{
                      display:
                        hasFormErrors.DateOfBirth || birthdayError
                          ? "flex"
                          : "none",
                    }}
                  >
                    <span>Data inválida</span>
                  </div>
                </div>
                <div>
                  <span id="content-option">Raça:*</span>
                  {breedsLoaded ? (
                    <SelectCustom {...selectBreed} />
                  ) : (
                    <div id="choose-a-specie">
                      <span>
                        Escolha uma espécie para desbloquear este campo!
                      </span>
                    </div>
                  )}
                  <div
                    id="error__field"
                    style={{ display: hasFormErrors.breed ? "flex" : "none" }}
                  >
                    <span>Selecione a raça</span>
                  </div>
                </div>
                <div>
                  <span id="content-option">Peso:*</span>
                  {petKgLoaded ? (
                    <div className="weight">
                      <SelectCustom {...selectPetKg} />
                    </div>
                  ) : (
                    <div id="choose-a-specie">
                      <span>
                        Escolha uma espécie para desbloquear este campo!
                      </span>
                    </div>
                  )}
                  <div
                    id="error__field"
                    style={{ display: hasFormErrors.kg ? "flex" : "none" }}
                  >
                    <span>Peso inválido</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              id="error__main"
              style={{ display: /*hasRequestErrors*/ false ? "flex" : "none" }}
            >
              <div>
                <span id="title">ERRO NO CADASTRO</span>
              </div>
              <div>
                <span id="info">{/*hasRequestErrors*/}</span>
              </div>
            </div>
            <div id="row3">
              <div id="row3-content">
                <span>Já usou <img src={bravectoLogo} alt="logo-braveto" width="92px" />?*</span>
                <div id="container__input--radio">
                  <div id="input--radio__content" className="two-radios">
                    <input
                      type="radio"
                      id={`${props.petIndex}-useBravecto-1`}
                      name={`${props.petIndex}-useBravecto`}
                      value="s"
                      checked={useBravecto === "s"}
                      onChange={(e) => {
                        setUseBravecto(e.target.value);
                        dataLayer.push({
                          event: "cadastro_selecionou_ja_usou_bravecto"
                        });
                      }}
                    />
                    <label htmlFor={`${props.petIndex}-useBravecto-1`}>Sim</label>
                  </div>
                  <div id="input--radio__content" className="two-radios">
                    <input
                      type="radio"
                      id={`${props.petIndex}-useBravecto-2`}
                      name={`${props.petIndex}-useBravecto`}
                      value="n"
                      checked={useBravecto === "n"}
                      onChange={(e) => {
                        setUseBravecto(e.target.value);
                        dataLayer.push({
                          event: "cadastro_selecionou_ja_usou_bravecto"
                        });
                      }}
                    />
                    <label htmlFor={`${props.petIndex}-useBravecto-2`}>Não</label>
                  </div>
                </div>
              </div>
              <div
                id="error__field"
                style={{ display: hasFormErrors.useBravecto ? "flex" : "none" }}
              >
                <span>Favor escolha uma opção</span>
              </div>
              {
                useBravecto === 's' &&
                <div id="use-date">
                  <span>Qual a última data de uso (aproximadamente)?</span>
                  <Input {...inputUseDate} data-value={inputUseDateValue} />
                  <div
                    id="error__field"
                    style={{
                      display:
                        hasFormErrors.useDate || useDateErrorMsg
                          ? "flex"
                          : "none",
                    }}
                  >
                    <span>Data inválida</span>
                  </div>
                </div>
              }
              <span id="required">*Campos de preenchimento obrigatório</span>
            </div>
          </form>
        </div>
      </div >
    </React.Fragment >
  );
};

const mapStateToProps = ({ auth, helper }, props) => {
  return {
    ...auth,
    ...props,
    ...helper,
  };
};

export default Connect(mapStateToProps)(FormCadastrarPet);
