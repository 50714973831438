import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
// import { MdClose } from "react-icons/md";

import Connect from "../../store/config/connect";
// import { LogInUser } from "../../store/auth";

import { postFirstAccessInfluencer } from "../../services/services";

import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";

import "./PrimeiroAcessoInfluenciador.css";

const PrimeiroAcessoInfluenciador = (props) => {
  document.title = "Clube Bravecto | Acesso Influenciador";

  const history = useHistory();

  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [tooltipPassword, setTooltipPassword] = useState(false);
  const [passwordMask, setPasswordMask] = useState(false);
  const [inputPrimeiroAcessoSenhaValue, setInputPrimeiroAcessoSenhaValue] =
    useState("");
  const [inputPrimeiroAcessoSenha] = useState({
    type: "password",
    name: "password",
    placeholder: "Senha de Primeiro Acesso",
    value: inputPrimeiroAcessoSenhaValue,
    replaceValueOnChange: true,
    onChange: function (pass) {
      const testAllMasks =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/g;
      const testNumber = /(\d)/g;
      const testLowerCase = /([a-z])/g;
      const testUpperCase = /([A-Z])/g;
      const testSymbols = /([@$!%*?&#])/g;
      setPasswordMask({
        uppercase: testUpperCase.test(pass.toString()) ? true : false,
        lowercase: testLowerCase.test(pass.toString()) ? true : false,
        numbers: testNumber.test(pass.toString()) ? true : false,
        symbols: testSymbols.test(pass.toString()) ? true : false,
        length: pass.length > 8 ? true : 8 - pass.length,
      });

      if (testAllMasks.test(pass.toString())) {
        setPasswordMask(false);
      }

      setInputPrimeiroAcessoSenhaValue(pass);
      return pass;
    },
    onBlur: function () {
      setTooltipPassword(false);
    },
    onFocus: function () {
      setTooltipPassword(true);
    },
  });

  const [inputSenhaValue, setInputSenhaValue] = useState("");
  const [inputSenha] = useState({
    type: "password",
    placeholder: "Nova Senha: Pelo menos 8 caracteres",
    value: inputSenhaValue,
    replaceValueOnChange: true,
    onChange: function (pass) {
      setInputSenhaValue(pass);
      return pass;
    },
  });

  const [inputConfirmarSenhaValue, setInputConfirmarSenhaValue] = useState("");
  const [inputConfirmarSenha] = useState({
    type: "password",
    placeholder: "Confirmar Senha",
    value: inputConfirmarSenhaValue,
    replaceValueOnChange: true,
    onChange: function (pass) {
      setInputConfirmarSenhaValue(pass);
      return pass;
    },
  });

  function handleSubmit(e) {
    setSubmitting(true);
    e.preventDefault();

    var url_string = window.location.href;
    var url = new URL(url_string);
    var hashCodeArray = url.searchParams.get("code");
    var hashCode = hashCodeArray.split(" ");
    hashCode = hashCode[0];

    if (inputPrimeiroAcessoSenha && inputSenha && inputConfirmarSenha) {
      let body = {
        passwordFirstAccess: inputPrimeiroAcessoSenhaValue,
        newPassword: inputSenhaValue,
        confirmPassword: inputConfirmarSenhaValue,
        hashCode: hashCode,
      };

      if (body) {
        postFirstAccessInfluencer(body)
          .then((res) => {
            if (res && res.status === 200) {
              history.push(
                "/acesso-influenciador/termos-de-uso?code=" + hashCode
              );
            }
          })
          .catch((e) => {
            if (parseInt(e.response.status) === 400) {
              let error = [];
              if (e.response && e.response.data) {
                const errorData = e.response.data.errors;
                if (errorData.length > 0) {
                  errorData.map((i) => {
                    setErrorMsg(i.message);
                    return error.push(i.message);
                  });
                }
              }
            }
            setSubmitting(false);
          });
      }
    }
  }

  return (
    <div id="PrimeiroAcessoInfluenciador">
      <div id="__container">
        <form onSubmit={handleSubmit}>
          <div id="title">
            <div id="main-title">
              <span>Troca da senha de Primeiro Acesso</span>
            </div>
          </div>

          <span>
            <p>
              Primeiro, cole a senha enviada por email na caixa de texto abaixo:
            </p>
          </span>

          <div id="input">
            <div id="box">
              <Input {...inputPrimeiroAcessoSenha} />
            </div>
          </div>

          <span>
            <p>
              A nova senha deverá ser composta&nbsp;
              <strong>
                obrigatoriamente por letras maiúsculas e minúsculas, números e
                caracteres especiais.
              </strong>
            </p>
          </span>

          <div id="input">
            <div id="box">
              <Input {...inputSenha} />
            </div>
            <div id="box">
              <Input {...inputConfirmarSenha} />
            </div>
          </div>

          <div id="acesso-btn">
            <button type="submit">
              {isSubmitting ? (
                <LoaderForButton />
              ) : (
                <span>Acessar o Clube de Vantagens</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }, props) => {
  return {
    auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(PrimeiroAcessoInfluenciador);
