import React from "react";
import Helmet from "react-helmet";

const basePath = "https://www.clubebravecto.com.br";

const locales = {
  pt: "pt_BR",
  "pt-BR": "pt_BR",
};

const MetaTags = (data) => {
  const lang = data.lang || "pt-BR";
  const title = data.title;
  const description = data.description;
  const image = data.image !== undefined && `${basePath}${data.image}`;
  const canonical =
    data.canonical !== undefined && `${basePath}${data.canonical}`;
  const type = data.type === undefined ? "article" : "website";
  const width = data.image ? data.width : undefined;
  const height = data.image ? data.height : undefined;

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {image ? <link rel="image_src" href={image} /> : null}
      {image ? <meta itemprop="image" content={image} /> : null}

      <meta property="og:site_name" content="..." />
      <meta property="og:title" content={title} />
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {canonical ? <meta property="og:url" content={canonical} /> : null}
      <meta property="og:locale" content={locales[lang] || lang} />
      <meta property="og:type" content={type} />
      {image ? <meta property="og:image" content={image} /> : null}
      {width ? <meta property="og:image:width" content={width} /> : null}
      {height ? <meta property="og:image:height" content={height} /> : null}
      {/* <meta property="fb:pages" content="..." /> */}

      {/* change type of twitter if there is no image? */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {description ? (
        <meta name="twitter:description" content={description} />
      ) : null}
      {image ? <meta name="twitter:image" content={image} /> : null}
      {/* <meta name="twitter:site" content="@..." /> */}
      {canonical ? (
        <link rel="alternate" href={canonical} hreflang={lang} />
      ) : null}
      {/* { canonical ? <link rel="alternate" href={ `${basePath}${alternatePathname}` } hreflang={ alternateLang } /> : null } */}
    </Helmet>
  );
};

export default MetaTags;
