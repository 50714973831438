import React from "react";

const FooterAccessibilityLink = () => {
	return (
		<a href="https://www.msd.com:443/wp-content/uploads/sites/9/2022/01/ea_msd_statement_en-us_pt-pt.pdf">
			Acessibilidade
		</a>
	);
};

export default FooterAccessibilityLink;
