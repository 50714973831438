import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
// import { Helmet } from 'react-helmet';

import Connect from "../../store/config/connect";
import { LogInUser } from "../../store/auth";

import Input from "../Utils/Input/Input";
import LoaderForButton from "../Utils/LoaderForButtons/LoaderForButtons";

import { postLoginUser, unclockCodeMatch } from "../../services/services";

import MetaTags from "../MetaTags/MetaTags";

import "./Login.css";

const Login = (props) => {
  useEffect(() => {
    handleLoad();
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  // let testLogin = '042.015.891-02';
  let testLogin = "433.327.918-69";
  const [inputCpfValue, setInputCpfValue] = useState(testLogin);
  const [inputCpf] = useState({
    type: "text",
    placeholder: "CPF",
    replaceValueOnChange: true,
    maxLength: 14,
    onChange: (cpf) => {
      let value = cpf
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      setInputCpfValue(value);
      return value;
    },
  });

  const [inputSenhaValue, setInputSenhaValue] = useState();
  const [inputSenha, setInputSenha] = useState({
    id:"password",
    type: "password",
    placeholder: "SENHA",
    value: inputSenhaValue,
    replaceValueOnChange: true,
    onChange: function (pass) {
      setInputSenhaValue(pass);
      return pass;
    },
  });

  function toogleInputSenhaType () {
    setInputSenha({
      ...inputSenha, type: inputSenha.type === "password" ? "text" : "password",
    })
  }

  function goBack() {
    return props.history.goBack();
  }

  const [unlockMsg, setUnlockMsg] = useState(false);
  function handleLoad() {
    var search = window.location.search;
    if (search) {
      var params = search.split("=");
      if (params) {
        unclockCodeMatch(params[1]).then((res) => {
          if (res && res.status === 200) {
            setUnlockMsg(true);
          }
        });
      }
    }
  }

  function handleSubmit(e) {
    setSubmitting(true);
    e.preventDefault();
    if (inputSenhaValue && inputCpfValue) {
      let bodyFormData = new FormData();
      bodyFormData.set("username", inputCpfValue);
      bodyFormData.set("password", inputSenhaValue);
      bodyFormData.set("grant_type", "password");

      postLoginUser(bodyFormData)
        .then((res) => {
          if (res && res.status === 200) {
            const loginResponse = JSON.parse(res.request.response),
              userToken = loginResponse.token,
              user = loginResponse.user;
            user.token = userToken;
            props.dispatch(LogInUser(user));
            props.history.push("/clube-de-vantagens");
            var stars = user.score;
            var userId = user.username;
            var email = user.email;
            var data = JSON.parse(localStorage.getItem("userData"));
            if (!data) {
              localStorage.setItem("userData", "[]");
              data = [];
            }
            var register = { stars, userId, email };
            data.push(register);
            localStorage.setItem("userData", JSON.stringify(data));
            stars = "";
            userId = "";
            email = "";
          }
        })
        .catch((e) => {
          if (parseInt(e.response?.status) === 400) {
            let error = [];
            if (e.response && e.response.data) {
              const errorData = e.response.data.errors;
              if (errorData.length > 0) {
                errorData.map((i) => {
                  setErrorMsg(i.message);
                  return error.push(i.message);
                });
              }
            }
          }
          setSubmitting(false);
        });
    }
  }

  return (
    <div id="Login">
      <div id="skip">
        <a href="#__container">Pular para o conteúdo principal da página</a>
      </div>
      <h1 className="sr-only">Faça seu Login | Clube de Vantagens Bravecto</h1>
      <MetaTags
        title="Faça seu Login | Clube de Vantagens Bravecto"
        description="Entre na Área de Cliente e acesse todos os Benefícios do Clube de Vantagens Bravecto. Confira! "
      />
      <div id="close">
        <MdClose
          onClick={() => {
            goBack();
          }}
        />
      </div>
      <div id="__container">
        {unlockMsg ? (
          <div id="info-unlocked-user">
            <span id="info-title"> Info: </span>
            <span> A sua conta foi desbloqueada com sucesso. </span>
          </div>
        ) : (
          <div></div>
        )}
        <div id="sou-cliente">
          <form onSubmit={handleSubmit}>
            <div id="__container-login">
              <div id="title">
                <div id="main-title">
                  <span>
                    Já faço parte do clube
                  </span>
                </div>
                {/* <div id="info">
                  <span>
                    Se você já faz parte do Clube de
                    <br /> Vantagens Bravecto, utilize seu CPF e<br /> sua senha
                    para entrar em sua conta.
                  </span>
                </div> */}
              </div>
              <div id="inputs">
                <Input {...inputCpf} />
                <Input {...inputSenha} />
                <span id="eyesPassword" onClick={() => toogleInputSenhaType()}></span>
              </div>
              <div id="erroMensagem">
                <span>{errorMsg}</span>
              </div>
              <div id="btn-contain">
                <div id="esqueci-a-senha">
                  <Link to="/esqueci-a-senha">Esqueci minha senha.</Link>
                </div>
                <div id="entrar">
                  <button type="submit" id="btnEntrar">
                    {isSubmitting ? <LoaderForButton /> : <span>Entrar</span>}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div id="quero-fazer-parte">
          <div id="__container-cadastro">
            <div id="title">
              <div id="main-title">
                <span>
                  Quero fazer parte!
                </span>
              </div>
              <div id="info">
                <p>
                  Se você ainda não é do Clube, <span><b>cadastre-se já,</b></span><br></br>
                  e aproveite todos os benefícios.
                </p>
              </div>
            </div>
            <div id="cadastrar">
              <Link to="/cadastrar">
                <button type="button" disabled={isSubmitting}>
                  Cadastre-se agora!
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }, props) => {
  return {
    auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(Login);
