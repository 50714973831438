import React, { useEffect, useState } from "react";

import {
  getStoreNeighborhoods,
  getStoreStates,
  getStoreCitiesByState,
  storesByState,
  storesByCity,
  storesByNeighborhood,
} from "../../../services/services";
//postStoresByType

import SelectCustom from "../../Utils/Select/Select";

import Loader from "../../Loader/Loader";

import "./ComprarFisica.css";

const ComprarFisica = (props) => {
  document.title = "Clube Bravecto | Lojas Físicas";

  const [states, setStates] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [neighborhoods, setNeighborhoods] = useState(null);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
  const [physicalStores, setPhysicalStores] = useState([]);

  useEffect(() => {
    getStoreStates().then((res) => {
      if (res && res.status === 200) {
        try {
          setStates({
            isDisabled: false,
            onChange: function (state) {
              setCities(null);
              setNeighborhoods(null);
              return setSelectedState(state);
            },
            options: JSON.parse(res.request.response).map((state) => {
              return {
                id: state.id,
                label: state.name,
                value: state.uf,
              };
            }),
            placeholder: "Estado",
          });
        } catch (e) {
          setStates(null);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (selectedState) {
      getStoreCitiesByState(selectedState.id).then((res) => {
        if (res && res.status === 200) {
          try {
            setCities({
              isDisabled: false,
              onChange: function (city) {
                setNeighborhoods(null);
                return setSelectedCity(city);
              },
              options: JSON.parse(res.request.response).map((city) => {
                return {
                  id: city.id,
                  label: city.name,
                  value: city.id,
                };
              }),
              placeholder: "Cidade",
            });
            storesByState(selectedState.id).then((res) => {
              if (res && res.status === 200) {
                try {
                  setPhysicalStores(JSON.parse(res.request.response));
                } catch (e) {
                  setPhysicalStores([]);
                }
              }
            });
          } catch (e) {
            setCities(null);
          }
        }
      });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      getStoreNeighborhoods(selectedCity.id).then((res) => {
        if (res && res.status === 200) {
          try {
            setNeighborhoods({
              isDisabled: false,
              onChange: function (neighborhood) {
                return setSelectedNeighborhood(neighborhood);
              },
              options: JSON.parse(res.request.response).map((neighborhood) => {
                return {
                  id: neighborhood.id,
                  label: neighborhood.name,
                  value: neighborhood.id,
                };
              }),
              placeholder: "Bairro",
            });
            storesByCity(selectedCity.id).then((res) => {
              if (res && res.status === 200) {
                try {
                  setPhysicalStores(JSON.parse(res.request.response));
                } catch (e) {
                  setPhysicalStores([]);
                }
              }
            });
          } catch (e) {
            setNeighborhoods(null);
          }
        }
      });
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedNeighborhood) {
      storesByNeighborhood(selectedNeighborhood.id).then((res) => {
        if (res && res.status === 200) {
          try {
            setPhysicalStores(JSON.parse(res.request.response));
          } catch (e) {
            setPhysicalStores([]);
          }
        }
      });
    }
  }, [selectedNeighborhood]);

  return (
    <div id="ComprarFisica">
      <div id="ComprarFisica__selects--container">
        <div id="ComprarFisica__aux"></div>
        <div id="ComprarFisica__selects">
          <span id="ComprarFisicaTitle">Escolher loja parceira:</span>
          {states ? (
            <SelectCustom {...states} />
          ) : (
            <div id="Loader">
              <Loader />
            </div>
          )}
          {cities ? <SelectCustom {...cities} /> : ""}
          {neighborhoods ? <SelectCustom {...neighborhoods} /> : ""}
        </div>
      </div>
      <div id="PhysicalStoresResults">
        <span>Resultado da busca</span>
      </div>
      <div id="DescontoBravecto__PhysicalStores">
        {physicalStores.map((store, i) => {
          return (
            <div id="StoreContent">
              <div id="PhysicalStore" key={i}>
                <span id="PhysicalStoreTitle">{store.storeName}</span>
                {store.storeEmail && (
                  <a href={`mailto:${store.storeEmail}`} id="PhysicalStoreLine">
                    <figure id="PhysicalStoreFigure">
                      <img
                        alt="Email"
                        id="PhysicalStoreFigureIcon"
                        src={require("../../../images/onde-comprar-mail.svg")}
                      />
                    </figure>
                    <span id="PhysicalStoreText">{store.storeEmail}</span>
                  </a>
                )}
                {store.storePhone && (
                  <a href={`tel:+55${store.storePhone}`} id="PhysicalStoreLine">
                    <figure id="PhysicalStoreFigure">
                      <img
                        alt="Telefone"
                        id="PhysicalStoreFigureIcon"
                        src={require("../../../images/onde-comprar-phone.svg")}
                      />
                    </figure>
                    <span id="PhysicalStoreText">{store.storePhone}</span>
                  </a>
                )}
                <div id="PhysicalStoreLine">
                  <figure id="PhysicalStoreFigure">
                    <img
                      alt="Endereço"
                      id="PhysicalStoreFigureIcon"
                      src={require("../../../images/onde-comprar-locationpin.svg")}
                    />
                  </figure>
                  <span id="PhysicalStoreText">
                    Avenida Elias Yazbek Tingidor - Embu das Artes/SP -
                    06803-000
                  </span>
                </div>
                <div id="PhysicalStoreRedeemCoupon">
                  <button type="button">Gerar cupom</button>
                </div>
              </div>
              <div id="CouponStore">
                <div id="CouponStore__container">
                  <button type="button">Gerar cupom</button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <a
        id="ComprarFisicaBack"
        onClick={() => {
          props.handleHideOptions(false);
        }}
      >
        Voltar
      </a>
    </div>
  );
};

export default ComprarFisica;
