import React, { useState, useEffect } from "react";

import "./Input.css";

const Input = (props) => {
  const [inputProps, setInputProps] = useState(null);
  const [value, setValue] = useState(props.defaultValue || "");

  useEffect(() => {
    setInputProps(props);
  }, [props]);

  useEffect(() => {
    setValue(props['data-value']);
  }, [props]);

  useEffect(() => {
    // console.log(`Utils/Input value effect`, props.name, value)
  }, [value, props.name])

  function handleValueChanged(e) {
    setIsAlert(false)
    if (inputProps.onChange) {
      let val = inputProps.onChange(e.target.value);
      if (inputProps.replaceValueOnChange) {
        setValue(val);
      }
    } else {
      return e.target.value;
    }
  }

  function handleOnBlur(e) {
    if (inputProps.onBlur) {
      return inputProps.onBlur(e);
    }
  }

  function handleOnFocus() {
    if (inputProps.onFocus) {
      return inputProps.onFocus();
    }
  }

  const [isAlert, setIsAlert] = useState(false)

  useEffect(() => {
    if (inputProps?.value === '' && props.alert) setIsAlert(true)
  }, [inputProps, props.alert]);

  if (inputProps) {
    return (
      <div
        id="container__input"
        className={props.hidden && "container__input--hidden"}
        style={isAlert ? { border: '1px solid #B32346'} : { border: '1px solid #dfe2e8'} }
      >
        <label>
          <span className="sr-only">
            {inputProps.label || inputProps.placeholder}
          </span>
          <input
            type={inputProps.type}
            name={inputProps.name}
            maxLength={inputProps.maxLength || 999999}
            placeholder={inputProps.placeholder}
            onChange={handleValueChanged}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            value={
              // inputProps.value
              inputProps.replaceValueOnChange
                ? value
                : inputProps.value
                ? inputProps.value
                : value
            }
            disabled={props.disabled}
            autoComplete="off"
          />
        </label>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Input;
