export const authStore = {
  auth: {
    isLogged: false,
    isUnauthorized: false,
    user: 'undefined' !== typeof window &&
      'undefined' !== typeof window.localStorage
      ? JSON.parse(window.localStorage.getItem('bravecto-user'))
      : {},
  },
};

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

const authReducer = (state = authStore, action) => {
  switch (action.type) {
    case LOGIN:
      window.localStorage.setItem(
        "bravecto-user",
        JSON.stringify(action.payload)
      );
      return {
        auth: {
          ...state.auth,
          isLogged: true,
          user: action.payload,
        },
      };
    case LOGOUT:
      window.localStorage.removeItem("bravecto-user");
      return {
        auth: {
          ...state.auth,
          isLogged: false,
          user: {},
        },
      };
    default:
      return state;
  }
};

export const FirstAccessInfluencer = (user) => {
  return {
    type: LOGIN,
    payload: user,
  };
};

export const LogInUser = (user) => {
  return {
    type: LOGIN,
    payload: user,
  };
};

export const LogOutUser = () => {
  return {
    type: LOGOUT,
  };
};

export default authReducer;
