import React from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import bannerDesk from "../../images/LP-Desk_PaollaIvete.png";
import bannerMobile from "../../images/LP-Mob_PaollaIvete.png";

import "./Ivete.scss";
const parceiro = "https://bit.ly/3cJgTL9";

const Ivete = () => {
  document.title = "Clube Bravecto | Paolla & Ivete";

  return (
    <div id="Ivete">
      <Header hideLoginButton />

      <div className="banner">
        <img className="bnr-desk" src={bannerDesk} alt="" />
        <img className="bnr-mob" src={bannerMobile} alt="" />
      </div>
      <div className="link">
        <a
          className="button"
          href={parceiro}
          target="_blank"
          rel="noopener noreferrer"
          title="Compre Agora"
        >
          COMPRE AGORA COM 10% OFF
        </a>
      </div>

      <Footer />
    </div>
  );
};

export default Ivete;
