import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Connect from "../../../store/config/connect";

import { getProductById } from "../../../services/services";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

import "./BeneficiosDetalhes.css";

const BeneficiosDetalhes = (props) => {
  document.title = "Clube Bravecto | Benefícios Detalhes";

  const [urlParams] = useState(useParams());
  const [product, setProduct] = useState([]);

  function handleGoBack() {
    return props.history.goBack();
  }

  function pushDataLayer(product) {
    var transactionId = function () {
      return "_" + Math.random().toString(36).substr(2, 9);
    };

    window.dataLayer.push({
      ecommerce: {
        purchase: {
          actionField: {
            id: transactionId(),
            affiliation: product.productName,
            revenue: 1.0,
            tax: 0.0,
            shipping: 0.0,
          },
          products: [
            {
              name: product.productName,
              id: product.id,
              price: 1.0,
              brand: product.productName,
              category: product.rate,
              quantity: 1,
            },
          ],
        },
      },
      event: "orderPlaced",
    });
  }

  useEffect(() => {
    function getProduct() {
      getProductById(urlParams.id)
        .then((res) => {
          if (res && res.status === 200) {
            let productResponse = JSON.parse(res.request.response);
            setProduct(productResponse);
          }
        })
        .catch((e) => {
          console.log(e.response);
        });
    }
    getProduct();
  }, [urlParams]);

  return (
    <div id="BeneficiosDetalhes">
      <Header />
      <div id="BeneficiosDetalhes__container">
        <div id="product__container">
          <div id="product__images">
            <div id="product__images--container">
              <div id="product__images--logo">
                <span
                  style={{
                    backgroundImage: `url("data:image/jpeg;base64,${product.imageLogoPartnerBase64}")`,
                  }}
                ></span>
              </div>
              <div id="product__images--image">
                <span
                  id="image__path"
                  style={{
                    backgroundImage: `url("data:image/jpeg;base64,${product.imageBase64}")`,
                  }}
                ></span>
                <div id="logo--stars">
                  <div id="logo--stars__container">
                    <div id="star">
                      <span>{product.rate}</span>
                    </div>
                    <div id="star-ghosting-middle"></div>
                    <div id="star-ghosting-last"></div>
                  </div>
                </div>
              </div>
              <div id="product__images--content">
                <span id="desc">{product.percentage}</span>
              </div>
            </div>
          </div>
          <div id="product__content">
            <div id="product__content--container">
              <div id="product__content--name">
                <span>{product.productName}</span>
              </div>
              <div
                id="product__content--desc"
                dangerouslySetInnerHTML={{ __html: product.description }}
              ></div>
              {product.discountCoupon && (
                <div id="product__content--discount">
                  <b>Cupom de Desconto: </b>
                  <span>{product.discountCoupon}</span>
                </div>
              )}
              <div id="product__content--container__discount">
                <div id="product__content--getDiscount">
                  <a
                    href={`${product.discountLink}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Disconto do Produto"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        pushDataLayer(product);
                      }}
                    >
                      Resgatar
                    </button>
                  </a>
                </div>
                <div id="product__content--back" onClick={handleGoBack}>
                  <span>Voltar</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Connect()(BeneficiosDetalhes);
