import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";

import { postForgotPassword, getDocumentExists, putChangeEmail } from "../../services/services";
import { emailSuggestions } from "../../utils/helpers/general";

import Input from "../Utils/Input/Input";

import "./RecuperarSenha.css";
import { Link } from "react-router-dom";

const RecuperarSenha = (props) => {
  document.title = "Clube Bravecto | Recuperar Senha";

  const [errorMsg] = useState(false);
  const [successRetrieve, setSuccessRetrieve] = useState(null);
  const [inputCpfValue, setInputCpfValue] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [inputCpf] = useState({
    type: "text",
    placeholder: "XXX.XXX.XXX-XX",
    replaceValueOnChange: true,
    maxLength: 14,
    onChange: (cpf) => {
      let value = cpf
        .toString()
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      setInputCpfValue(value);
      return value;
    },
    onBlur: function (e) {
      getDocumentExists(e.target.value)
        .then((res) => {
          if (res && res.status === 200) {
            if (res.data === true) {
              setCpfExists(true);
            } else {
              setInputCpfValue(null);
              setCpfExists(false);
            }
          } else {
            setInputCpfValue(null);
            setCpfExists("invalid");
          }
        })
        .catch((e) => {
          setInputCpfValue(null);
          setCpfExists("invalid");
        });
    },
  });

  function goBack() {
    return props.history.goBack();
  }

  const [cpfExists, setCpfExists] = useState(true);

  function handleSubmit(e) {
    e.preventDefault();
    let params;

    if (inputCpfValue?.length > 0) {
      params = {
        document: inputCpfValue,
      };
      postForgotPassword(params)
        .then((res) => {
          if (res.status === 200) {
            setSuccessRetrieve(true);
            const userMail = JSON.parse(res.request.response);
            setUserEmail(userMail.data.email);
          }
        })
        .catch((e) => {
          setSuccessRetrieve(false);
        });
    }
  }

  const [OldEmailIsValid, setOldEmailIsValid] = useState(false)
  const [OldEmailValue, setInputOldEmailValue] = useState("");
  const [changeEmail, setChangeEmail] = useState(false);
  const [changedEmail, setChangedEmail] = useState(false);


  const [inputOldEmail, setInputOldEmail] = useState({
      type: "text",
      name: "email",
      placeholder: "emailantigo@exemplo.com",
      value: OldEmailValue,
      onChange: function (email) {
        setOldEmailErrorMessage('')
        // eslint-disable-next-line
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(email).toLowerCase());
        if (re.test(String(email).toLowerCase()) && email.trim() === 0) {
          setOldEmailIsValid(true)
        } else {
          setOldEmailIsValid(false)
        }
        setInputOldEmailValue(email);
        return email;
      }
  });

  const [newEmailIsValid, setNewEmailIsValid] = useState(false)
  const [NewEmailValue, setInputNewEmailValue] = useState("");
  const [inputNewEmail, setInputNewEmail] = useState({
      type: "text",
      name: "email",
      placeholder: "novoemail@exemplo.com",
      value: NewEmailValue,
      onChange: function (email) {
        setNewEmailErrorMessage('')
        // eslint-disable-next-line
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(email).toLowerCase());
        setInputNewEmailValue(email);
        if (re.test(String(email).toLowerCase()) && email.trim() === 0) {
          setNewEmailIsValid(true)
        } else {
          setNewEmailIsValid(false)
        }
        return email;
      }
  });

  const [confirmEmailIsValid, setConfirmEmailIsValid] = useState(false)
  const [ConfirmEmailValue, setInputConfirmEmailValue] = useState("");
  const [inputConfirmEmail, setInputConfirmEmail] = useState({
      type: "text",
      name: "email",
      placeholder: "novoemail@exemplo.com",
      value: ConfirmEmailValue,
      onChange: function (email) {
        setConfirmEmailErrorMessage('')
        // eslint-disable-next-line
        var re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(email).toLowerCase());
        setInputConfirmEmailValue(email);
        if (re.test(String(email).toLowerCase()) && email.trim() === 0) {
          setConfirmEmailIsValid(true)
        } else {
          setConfirmEmailIsValid(false)
        }
        return email;
      }
  });

  function validateEmails() {
    var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(OldEmailValue).toLowerCase())) {
      setOldEmailErrorMessage('Digite um email antigo válido.')
      return false
    }
    if (!re.test(String(NewEmailValue).toLowerCase())) {
      setNewEmailErrorMessage('Digite um email novo válido.')
      return false
    }
    if (OldEmailValue === NewEmailValue) {
      setNewEmailErrorMessage('Email novo não pode ser igual ao antigo.')
      setOldEmailErrorMessage('Email novo não pode ser igual ao antigo.')
      return false
    }
    if (NewEmailValue !== ConfirmEmailValue) {
      setConfirmEmailErrorMessage('Confirme seu email corretamente.')
      return false
    }
      return true
  }

  function confirmEmailChange() {
    if (validateEmails()) {
      try {
        putChangeEmail({
          CurrentEmail: OldEmailValue,
          Email: NewEmailValue,
          ConfirmEmail: ConfirmEmailValue,
          Document: inputCpfValue,
        }).then((response) => {
          console.log(response)
          if (response.success === false) {
            console.log(response.errors[0].message)
            setOldEmailErrorMessage(response.errors[0].message)
          }
          setChangedEmail(true);
          setEndScreen(true);
        }).catch((error) => {
          if (error.response.data.errors[0].message === 'Este Email ja está em uso.') {
            setNewEmailErrorMessage(error.response.data.errors[0].message)
          }
          if (error.response.data.errors[0].message === 'Os Emails não coincidem.') {
            setOldEmailErrorMessage(error.response.data.errors[0].message)
          } else {
            console.log(error.response)
          }
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      console.log('Erro na validação dos emails')
    }
  }

  const [endScreen, setEndScreen] = useState(false)

  const [oldEmailErrorMessage, setOldEmailErrorMessage] = useState('');
  const [newEmailErrorMessage, setNewEmailErrorMessage] = useState('');
  const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] = useState('');



  return (
    <div id="RecuperarSenha">
      <div id="__container">
        <div id="close">
          <MdClose
            onClick={() => {
              goBack();
            }}
          />
        </div>
        <div id="sou-cliente">
          {successRetrieve ? (
            <>
              <div id="__container" style={{ display: !changeEmail ? '' : 'none' }}>
                <div id="title">
                  <div id="main-title">
                    <span>Recuperar senha</span>
                  </div>
                  <div id="info" className="send">
                    <span>
                      Enviamos um e-mail para você: <br /> <span id="email"><strong>{userEmail}</strong></span>.<br />
                      Por favor, verifique sua caixa de entrada<br />
                      ou caixa de spam.
                    </span>
                  </div>
                  <div id="back-login">
                    <Link to="/login" style={{ backgroundColor: '#E0E1E2', borderRadius: '20px', color: '#9C9D9E', width: '190px', height: '40px', outline: 'none', border: 'none', cursor: 'pointer' }}>Voltar para o login</Link>
                    <button onClick={() => setChangeEmail(true)} style={{ backgroundColor: '#A12E4A', borderRadius: '20px', color: 'white', height: '40px', outline: 'none', border: 'none', cursor: 'pointer', padding: '0 36px' }}>Quero trocar meu email</button>
                  </div>
                </div>
              </div>

              <div id="__container" style={{ display: changeEmail && !changedEmail ? '' : 'none' }}>
              <div id="title">
                <div id="main-title">
                  <span>Trocar email</span>
                </div>
                <div style={{width: '100%', height: '80%', backgroundColor: "white", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', boxShadow: '0px 1px 4px #00000014'}}>
                  <div id="row">
                    <div id="content">
                      <span>Digite seu email antigo</span>
                    </div>
                    <Input {...inputOldEmail} />
                    <span style={{color: '#A12E4A', fontSize: '12px', paddingTop: '12px'}}>{oldEmailErrorMessage}</span>
                  </div>

                  <div id="spacement-div">
                    <hr></hr>
                  </div>
                  <div id="row">
                    <div id="content">
                      <span>Digite seu novo email</span>
                    </div>
                    <Input {...inputNewEmail} />
                    <span style={{color: '#A12E4A', fontSize: '12px', paddingTop: '12px'}}>{newEmailErrorMessage}</span>
                  </div>

                  
                  <div id="row">
                    <div id="content">
                      <span>Confirme seu novo email</span>
                    </div>
                    <Input {...inputConfirmEmail} />
                    <span style={{color: '#A12E4A', fontSize: '12px', paddingTop: '12px'}}>{confirmEmailErrorMessage}</span>
                  </div>

                </div>
                <div id="back-login">
                  <button onClick={() => setChangeEmail(false)} style={{ backgroundColor: '#E0E1E2', borderRadius: '20px', color: '#9C9D9E', width: '75px', height: '40px', outline: 'none', border: 'none', cursor: 'pointer' }}></button>
                  <button onClick={() => confirmEmailChange()} style={{ backgroundColor: '#A12E4A', borderRadius: '20px', color: 'white', height: '40px', outline: 'none', border: 'none', cursor: 'pointer', padding: '0 36px' }}>Ok, trocar meu email</button>
                </div>
              </div>
              </div>

              <div id="__container" style={{ display: endScreen ? '' : 'none' }}>
                <div id="title">
                  <div id="main-title">
                    <span>Email alterado com sucesso</span>
                  </div>
                  <div id="info" className="send">
                    <span>
                      Enviamos um e-mail para você: <br /> <span id="email"><strong>{NewEmailValue}</strong></span><br />
                      Por favor, verifique sua caixa de entrada<br />
                      ou caixa de spam.
                    </span>
                  </div>
                  <div id="back-login">
                    <Link to="/login" style={{ backgroundColor: '#E0E1E2', borderRadius: '20px', color: '#9C9D9E', width: '190px', height: '40px', outline: 'none', border: 'none', cursor: 'pointer' }}>Voltar para o login</Link>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div id="__container">
              <div id="main-title">
                <span>Recuperar senha</span>
              </div>
              <div id="title">
                <div id="info">
                  <div id="info-text">
                    <p>
                      Se você já faz parte do Clube de
                      Vantagens Bravecto, <span>utilize seu CPF</span> para
                      recuperar sua senha.
                    </p>
                  </div>
                  <hr />
                  <form onSubmit={handleSubmit}>
                    <div id="inputs">
                      <div>
                        <span>Seu CPF</span>
                        <Input {...inputCpf} />
                      </div>
                      <div
                        id="error__field"
                        style={{ display: !cpfExists ? "flex" : "none" }}
                      >
                        <span>
                          {!cpfExists
                            ? "CPF não cadastrado"
                            : cpfExists === "invalid" && "CPF não válido"}
                        </span>
                      </div>
                    </div>
                    <div id="erroMensagem">
                      {errorMsg ? (
                        <span>
                          CPF não cadastrado. <br /> Tente novamente ou cadastre-se
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <div id="confirmar">
                <Link id="btnVoltar" to="/login"></Link>
                <button type="submit" id="btnConfirmar" onClick={(ev) => handleSubmit(ev)}>
                  Confirmar
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default RecuperarSenha;
