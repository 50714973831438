import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LogInUser } from "../../store/auth";
import Connect from "../../store/config/connect";

const VerifyAuth = (props) => {
  const [history] = useState(useHistory());
  const { pathname } = useLocation();

  useEffect(() => {
    const user = window.localStorage.getItem("bravecto-user")
      ? JSON.parse(window.localStorage.getItem("bravecto-user"))
      : "";

    if (user && !props.auth.isLogged) {
      props.dispatch(LogInUser(user));
    }
  }, [props, history, pathname]);

  return null;
};

const mapStateToProps = ({ auth }, props) => {
  return {
    auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(VerifyAuth);
