import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import 'add-to-calendar-button';
import moment from "moment";

import Connect from "../../store/config/connect";

import { getAllPetsByCustomer } from "../../services/services";

import Loader from "../Loader/Loader";
import ModalDeletePet from "./ModalDeletePet/ModalDeletePet";
// import { urlImages } from '../../utils/helpers/general';
import bravectoLogo from '../../images/bravecto-logo.svg'

import "./Pets.css";
import ModalReminder from "./ModalReminder/ModalReminder";

export const PrevButton = (props) => {
  return (
    <MdKeyboardArrowLeft
      className="slick-arrow slick-prev"
      onClick={props.onClick}
    />
  );
};
export const NextButton = (props) => {
  return (
    <MdKeyboardArrowRight
      className="slick-arrow slick-next"
      onClick={props.onClick}
    />
  );
};

const Pets = (props) => {
  document.title = "Clube Bravecto | Meus Pets";

  var url = window.location.href;
// const api = "https://clubebravectoapi.shiftinc.com.br/api/v1/";
  const api = //url.includes('localhost') ||
     url.includes("clubebravecto") && !url.includes("shiftinc")
       ? "https://api.clubebravecto.com.br/api/v1/"
       : "https://clubebravectoapi.shiftinc.com.br/api/v1/";

  const [noReminder, setNoReminder] = useState(null);

  const [petsSlider] = useState({
    dots: true,
    arrows: true,
    infinite: true,
    centerMode: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000,
    className: "MeusPets--slides",
    dotsClass: "MeusPets--dots slick-dots",
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
  });

  const [history] = useState(useHistory());
  const [deleteModal, setDeleteModal] = useState(false);
  const [petId, setPetId] = useState(null);
  function handleDeleteModal(petId) {
    setPetId(petId);
  }
  useEffect(() => {
    if (petId) {
      setDeleteModal(true);
    }
  }, [petId]);

  const [isMobile, setIsMobile] = useState(props.helper.isMobile);
  useEffect(() => {
    setIsMobile(props.helper.isMobile);
  }, [props.helper.isMobile]);

  function handleEdit(petId) {
    history.push(`/editar-pet/${petId}`);
  }

  const [pets, setPets] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modalReminder, setModalReminder] = useState(false);
  useEffect(() => {
    console.log(props.user)
    if (props.user.hasPets === false && props.user.isBeforePromo && props.user.score < 5) {
      setModalReminder(true);
    }
  }, [props.user])

  useEffect(() => {
    if (props.user.id || petId === 0) {
      getAllPetsByCustomer(props.user.id)
        .then((res) => {
          if (res && res.status === 200) {
            let petsResponse = JSON.parse(res.request.response);
            setPets(petsResponse);
            const noReminderPet = petsResponse.filter((i) => {
              return i.reminderDate === null;
            });
            const reminderPet = petsResponse.filter((i) => {
              return i.reminderDate !== null;
            });
            setNoReminder(
              noReminderPet.length >= 1 && reminderPet === 0 ? true : false
            );

            return setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e.response);
        });
    }
  }, [props.user, petId]);

  if (loading) {
    return (
      <div id="preLoader">
        <Loader />
      </div>
    );
  } else {
    if (pets.length > 0) {
      if (props.isChild) {
        return (
          <React.Fragment>
            <ModalDeletePet
              modalClose={deleteModal}
              setModalClose={setDeleteModal}
              petId={petId}
              setPetId={setPetId}
              token={props.user.token}
            />
            <Slider {...petsSlider}>
              {pets.map((i) => {
                return (
                  <div id="meu-pet" key={i.id}>
                    <div
                      id="meu-pet__container"
                      className={i.reminderExpired ? `pet-expired-date` : ""}
                    >

                      <div id="info--pet">
                        <div id="photo--pet">
                          <span id="photo-pet"
                            style={{
                              backgroundImage: `url(${api}image/getImage/${i.id}/Pet`
                            }
                            }
                          ></span>
                          <div id="info--pet__header">
                            <div id="name">
                              <span>{i.name}</span>
                              <span>{ }</span>
                            </div>
                            {isMobile && (
                              <div id="__header--icons">
                                <div
                                  id="edit"
                                  onClick={() => {
                                    handleEdit(i.id);
                                  }}
                                >
                                  <span></span>
                                </div>
                                <div
                                  id="delete"
                                  onClick={() => {
                                    handleDeleteModal(i.id);
                                  }}
                                >
                                  <span></span>
                                </div>
                              </div>
                            )}
                            {!isMobile && (
                              <React.Fragment>
                                <div id="header-icons">
                                  <div
                                    id="edit"
                                    onClick={() => {
                                      handleEdit(i.id);
                                    }}
                                  >
                                    <span></span>
                                  </div>
                                  <div
                                    id="delete"
                                    onClick={() => {
                                      handleDeleteModal(i.id);
                                    }}
                                  >
                                    <span></span>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                      <hr id="hr"></hr>
                      <div id="remember">
                        {i.reminderDate ? (
                          <React.Fragment>
                            <div id="icon">
                              <span></span>
                            </div>
                            <div id="infos">
                              <img src={bravectoLogo} alt="logo-bravecto" width="100px" />
                              <p>
                                Próxima aplicação: <span>{new Date(i.reminderDate).toLocaleDateString(
                                  "pt-BR"
                                )}</span>
                              </p>
                              <div className="btn-agenda">
                                  <add-to-calendar-button
                                    label="Adicionar ao Calendário"
                                    name="Próxima aplicação do Bravecto"
                                    options="'Apple','Google','Outlook.com','Microsoft 365','Microsoft Teams','Yahoo'"
                                    startDate={new Date(i.reminderDate).toISOString().slice(0, 10)}
                                    timeZone="America/Sao_Paulo"
                                    listStyle="modal"
                                    size="3"
                                  ></add-to-calendar-button>
                                </div>
                              {/* <span id="info--red">
                                
                              </span> */}
                            </div>
                            <Link to={`/cadastrar-lembrete/${i.id}`}>Editar lembrete</Link>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div id="noReminder">
                              <div id="zeroReminders">
                                <span>Já deu <img src={bravectoLogo} alt="logo-bravecto" width="80px" /> para seu pet?</span>
                              </div>
                              <div id="registerReminder">
                                <div id="registerReminder--desc">
                                  <Link to={`/cadastrar-lembrete/${i.id}`}>
                                    Cadastre seu lembrete
                                  </Link>
                                </div>
                                <div id="registerReminder--icon">
                                  <span></span>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    {i.reminderExpired && (
                      <div className="expired-date-alert">
                        <span>
                          <b>ATENÇÃO:</b> data de aplicação expirada
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </Slider>
            {noReminder && (
              <div id="create-firstAccess">
                <div id="create-firstAccess__container">
                  <div id="create-firstAccess__box">
                    <div id="create-firstAccess__reminder--icon">
                      <span></span>
                    </div>
                    <div id="create-firstAccess__box--desc">
                      <div id="create-firstAccess__box--title">
                        <span>Meus Lembretes</span>
                      </div>
                      <div id="create-firstAccess__box--info">
                        <span>Você ainda não tem lembretes cadastrados.</span>
                      </div>
                      <div id="create-firstAccess__box--register">
                        <Link to="/cadastrar-lembrete">Cadastrar</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <ModalDeletePet
              modalClose={deleteModal}
              setModalClose={setDeleteModal}
              petId={petId}
              setPetId={setPetId}
              token={props.user.token}
            />
            <div id="meusPets-container">
              {pets.map((i) => {
                return (
                  <div id="meu-pet" key={i.id}>
                    <div id="meu-pet__container" className={i.reminderExpired ? `pet-expired-date` : ""}>
                      <div className={i.reminderDate === null ? "meu-pet__container__noReminder" : ""} >
                        <div id="info--pet">
                          <div id="photo--pet">
                            <span id="photo-pet"
                              style={{
                                backgroundImage: `url(${api}image/getImage/${i.id}/Pet`
                              }}
                            ></span>
                            <div id="info--pet__header">
                              <div id="name">
                                <span>{i.name}</span>
                                <span>{ }</span>
                              </div>
                              {isMobile && (
                                <div id="__header--icons">
                                  <div
                                    id="edit"
                                    onClick={() => {
                                      handleEdit(i.id);
                                    }}
                                  >
                                    <span></span>
                                  </div>
                                  <div
                                    id="delete"
                                    onClick={() => {
                                      handleDeleteModal(i.id);
                                    }}
                                  >
                                    <span></span>
                                  </div>
                                </div>
                              )}
                              {!isMobile && (
                                <React.Fragment>
                                  <div id="header-icons">
                                    <div
                                      id="edit"
                                      onClick={() => {
                                        handleEdit(i.id);
                                      }}
                                    >
                                      <span></span>
                                    </div>
                                    <div
                                      id="delete"
                                      onClick={() => {
                                        handleDeleteModal(i.id);
                                      }}
                                    >
                                      <span></span>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr id="hr"></hr>
                        <div id="remember" className={i.reminderDate === null ? "meu-pet__container__noReminder" : ""}>
                          {i.reminderDate ? (
                            <React.Fragment>
                              <div id="icon">
                                <span></span>
                              </div>
                              <div id="infos">
                                <img src={bravectoLogo} alt="logo-bravecto" width="100px" />
                                <p>
                                  Próxima aplicação: <span>{new Date(i.reminderDate).toLocaleDateString(
                                    "pt-BR"
                                  )}</span>
                                </p>
                                <div className="btn-agenda">
                                  <add-to-calendar-button
                                    label="Adicionar ao Calendário"
                                    name="Próxima aplicação do Bravecto"
                                    options="'Apple','Google','Outlook.com','Microsoft 365','Microsoft Teams','Yahoo'"
                                    startDate={new Date(i.reminderDate).toISOString().slice(0, 10)}
                                    timeZone="America/Sao_Paulo"
                                    size="3"
                                  ></add-to-calendar-button>
                                </div>
                                {/* <span id="info--red">
                                
                              </span> */}
                              </div>
                              <Link to={`/cadastrar-lembrete/${i.id}`}>Editar lembrete</Link>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div id="noReminder">
                                <div id="zeroReminders">
                                  <span>Já deu <img src={bravectoLogo} alt="logo-bravecto" width="80px" /> para seu pet?</span>
                                </div>
                                <div id="registerReminder">
                                  <div id="registerReminder--desc">
                                    <Link to={`/cadastrar-lembrete/${i.id}`}>
                                      Cadastre seu lembrete
                                    </Link>
                                  </div>
                                  <div id="registerReminder--icon">
                                    <span></span>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {i.reminderExpired && (
                      <div className="expired-date-alert">
                        <span>
                          <b>ATENÇÃO:</b> data de aplicação expirada
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {noReminder && (
              <div id="create-firstAccess">
                <div id="create-firstAccess__container">
                  <div id="create-firstAccess__box">
                    <div id="create-firstAccess__reminder--icon">
                      <span></span>
                    </div>
                    <div id="create-firstAccess__box--desc">
                      <div id="create-firstAccess__box--title">
                        <span>Meus Lembretes</span>
                      </div>
                      <div id="create-firstAccess__box--info">
                        <span>Você ainda não tem lembretes cadastrados.</span>
                      </div>
                      <div id="create-firstAccess__box--register">
                        <Link to="/cadastrar-lembrete">Cadastrar</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      }
    } else {
      if (props.isChild || props.isMobile) {
        return (
          <div id="create-firstAccess">
            <ModalReminder
              modalClose={modalReminder}
              setModalClose={setModalReminder}
            />
            <div id="create-firstAccess__container">
              <div id="create-firstAccess__box">
                <div id="create-firstAccess__box--desc">
                  {/* <div id="create-firstAccess__box--title">
                    <span>Meus Pets</span>
                  </div> */}
                  <div id="create-firstAccess__pet--icon">
                    <span></span>
                  </div>
                  <div id="create-firstAccess__box--info">
                    <p>Você <span>não possui</span> pet cadastrado.</p>
                  </div>
                  <div id="create-firstAccess__box--register">
                    <Link to="/cadastrar-pet">Cadastrar</Link>
                  </div>
                </div>
              </div>
              {/* <div id="create-firstAccess__box">
                <div id="create-firstAccess__reminder--icon">
                  <span></span>
                </div>
                <div id="create-firstAccess__box--desc">
                  <div id="create-firstAccess__box--title">
                    <span>Meus Lembretes</span>
                  </div>
                  <div id="create-firstAccess__box--info">
                    <span>Você ainda não tem lembretes cadastrados.</span>
                  </div>
                  <div id="create-firstAccess__box--register">
                    <Link to="/cadastrar-lembrete">Cadastrar</Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        );
      } else {
        return (
          <>
            {props.isChild && (
              <div id="where-is-my-pets">
                <div id="no-pets">
                  <span>Nenhum Pet cadastrado</span>
                </div>
                <div id="register-your-pet">
                  <span>
                    Cadastre os seus animais para melhor <br /> administração da
                    aplicação de seus produtos.
                  </span>
                </div>
              </div>
            )}
            <div id="create-firstAccess">
              <div style={{ display: history.location.pathname === '/meus-pets' ? 'none' : '' }}>
                <ModalReminder
                  modalClose={modalReminder}
                  setModalClose={setModalReminder}
                />
              </div>
              <div id="create-firstAccess__container">
                <div id="create-firstAccess__box">
                  <div id="create-firstAccess__box--desc">
                    <div id="create-firstAccess__pet--icon">
                      <span></span>
                    </div>
                    <div id="create-firstAccess__box--info">
                      <p>Você <span>não possui</span> pet cadastrado.</p>
                    </div>
                    <div id="create-firstAccess__box--register">
                      <Link to="/cadastrar-pet">Cadastrar pet</Link>
                    </div>
                  </div>
                </div>
                {/* <div id="create-firstAccess__box">
                  <div id="create-firstAccess__reminder--icon">
                    <span></span>
                  </div>
                  <div id="create-firstAccess__box--desc">
                    <div id="create-firstAccess__box--title">
                      <span>Meus Lembretes</span>
                    </div>
                    <div id="create-firstAccess__box--info">
                      <span>Você ainda não tem lembretes cadastrados.</span>
                    </div>
                    <div id="create-firstAccess__box--register">
                      <Link to="/cadastrar-lembrete">Cadastrar</Link>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        );
      }
    }
  }
};

const mapStateToProps = ({ auth, helper }, props) => {
  return {
    helper,
    ...auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(Pets);
