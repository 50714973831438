import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { slugify } from "../../../utils/helpers/general";
import Loader from "../../Loader/Loader";

import "./DesktopProducts.css";

const DesktopProducts = (props) => {
  document.title = "Clube Bravecto | Produtos";

  const [products, setProducts] = useState([]);

  var url = window.location.href;
  // const api = "https://clubebravectoapi.shiftinc.com.br/api/v1/";
  const api = //url.includes('localhost') ||
     url.includes("clubebravecto") && !url.includes("shiftinc")
       ? "https://api.clubebravecto.com.br/api/v1/"
       : "https://clubebravectoapi.shiftinc.com.br/api/v1/";

  useEffect(() => {
    setProducts(props.products);
  }, [props.products]);

  return (
    <div id="product-list-desktop">
      {products.length > 0 ?
        products.map((i) => {
          return (
            <div id="product" key={i.id}>
              <div id="product__container">
                <div id="product__container--logo">
                  <img
                    alt="imageLogo"
                    src={`${api}image/getImage/${i.partnerId}/Partner`}
                  />
                </div>
                <div id="product__container--image">
                  <span
                    id="image__path"
                    style={{
                      backgroundImage: `url("${api}image/getImage/${i.id}/Product")`,
                    }}
                  ></span>
                  <div id="logo--stars">
                    <div id="logo--stars__container">
                      <div id="star">
                        <span>{i.rate}</span>
                      </div>
                      <div id="star-ghosting-middle"></div>
                      <div id="star-ghosting-last"></div>
                    </div>
                  </div>
                </div>
                <div id="product__container--content">
                  <span id="desc">{i.percentage}</span>
                  {i.isAvailable ? (
                    <Link
                      to={`beneficios/${slugify(i.productName)}/${i.rate}/${i.id
                        }`}
                    >
                      <button type="button">Resgatar</button>
                    </Link>
                  ) : (
                    <button type="button" id="btn-disabled">
                      Resgatar
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        }) : <div id="preLoader">
          {" "}
          <Loader />
          {" "}
        </div>}
    </div>
  );
};

export default DesktopProducts;
