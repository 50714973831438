import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "moment-timezone";

import { slugify } from "../../../utils/helpers/general";

import {
  getTipById,
  getSuggestions,
  getTips,
  getTipByTitle,
} from "../../../services/services";

import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Loader from "../../Loader/Loader";

import "./DicasShow.css";

// import Moment from "react-moment";

const DicasShow = (props) => {
  const [tip, setTip] = useState([]);
  const [tipId, setTipId] = useState(null);
  const [tipCategories, setTipCategories] = useState([]);
  var url = window.location.href;
// const api = "https://clubebravectoapi.shiftinc.com.br/api/v1/";
  const api = //url.includes('localhost') ||
     url.includes("clubebravecto") && !url.includes("shiftinc")
       ? "https://api.clubebravecto.com.br/api/v1/"
       : "https://clubebravectoapi.shiftinc.com.br/api/v1/";

  useEffect(() => {
    var params = props && props.match && props.match.params;
    var slug = params && params.slug;

    getTipByTitle(slug).then((res) => {
      if (res && res.status === 200) {
        const { data } = res;
        setTip(data)
        setTipId(data[0]?.id)
        setTipCategories(data[0]?.tipsCategories)
      }
    })
  }, []);

  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (tipId) {
      getSuggestions(tipId).then((res) => {
        if (res && res.status === 200) {
          var tipSuggestionsResponse = JSON.parse(res.request.response);

          setSuggestions(tipSuggestionsResponse);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipId]);

  return (
    <div id="DicasShow">
      <Header />
      <div id="DicasShowContainer">
        <div id="title">
          <div id="main-title">
            <span>
              <Link to={"/blog"} className="main-title__underline">
                Blog
              </Link>
            </span>
          </div>
          <div id="info">
            <span>&nbsp;</span>
          </div>
        </div>
        {tip.length ? (
          <>
            {tip.map((tip) => (
              <div key={tip.id}>
                <span className="body__title">{tip.title}</span>
                <div className="header">
                  <figure className="header__figure">
                    <img className="header__img" src={`${api}image/getImage/${tip.id}/TipLogo`} alt="Blog" />
                  </figure>
                  <div className="header__text">
                    <span className="header__writtenby">
                      <b>Por: </b>
                      {tip.name && (
                        <a href={`/blog?name=${tip.name}`}>{tip.name}</a>
                      )}
                    </span>
                    {tipCategories.length && (
                      <span className="header__date">
                        Tema:{" "}
                        {tipCategories.map((tipCategory, i) => (
                          <a key={i} href={`/blog?category=${tipCategory.slug}`}>
                            {tipCategory.name}
                          </a>
                        ))}
                      </span>
                    )}
                  </div>
                  {/* <div className="header__icons">
              <figure className="header__icon--figure">
                <img className="header__icon--img" 
                src={require("../../../images/dicas-download.svg")} alt="Blog"/>
              </figure>
              <figure className="header__icon--figure">
                <img className="header__icon--img" 
                src={require("../../../images/dicas-share.svg")} alt="Blog"/>
              </figure>
            </div> */}
                </div>
                <div className="body">
                  <figure className="body__figure">
                    <img
                      className="header__img"
                      src={`${api}image/getImage/${tip.id}/TipThumbnail`}
                      alt="Blog"
                    />
                  </figure>
                  <span className="body__text">
                    <span
                      dangerouslySetInnerHTML={{ __html: tip.description }}
                    ></span>
                  </span>
                </div>
                {tip && tip.embed ? (
                  <div className="embed__container">
                    <div id="video">
                      <div id="embed">
                        <iframe
                          width="100%"
                          height="100%"
                          src={`${tip.embed.replace("watch?v=", "embed/")}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Clube de Vantagens Bravecto"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="embed__container"></div>
                )}
                <div className="footer">
                  <figure className="footer__figure">
                    <img className="header__img" src={`${api}image/getImage/${tip.id}/TipLogo`} alt="Blog" />
                  </figure>
                  <div className="footer__content">
                    <span className="footer__text">
                      <span
                        dangerouslySetInnerHTML={{ __html: tip.partnerText }}
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div id="preLoader">
            <Loader />
          </div>
        )}
        <div id="title" className="footer-title">
          <div id="main-title">
            <span>Ver mais</span>
          </div>
        </div>
        {suggestions && suggestions.length ? (
          <div className="dicas__container">
            {suggestions.map((i) => {
              return (
                <Link
                  to={`${slugify(i.title)}`}
                  key={i.id}
                  className="dicas__item"
                >
                  <figure className="dicas__item--figure">
                    <img
                      className="header__img"
                      src={`${api}image/getImage/${i.id}/TipThumbnail`}
                      alt="Blog"
                    />
                  </figure>
                  <div className="dicas__item--title">
                    <figure className="dicas__item--logoFigure">
                      <img
                        className="header__img"
                        src={`${api}image/getImage/${i.id}/TipLogo`}
                        alt="Blog"
                      />
                    </figure>
                    <span className="dicas__item--titleText">{i.title}</span>
                  </div>
                  <span className="dicas__item--text">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: i.descriptionPreview,
                      }}
                    ></span>
                  </span>
                  <span className="dicas__item--link">Leia mais &raquo;</span>
                </Link>
              );
            })}
          </div>
        ) : (
          <div id="preLoader">
            <Loader />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default DicasShow;
