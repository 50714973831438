import { useEffect, useState } from "react";

import { ResizeWindow } from "../../store/helper";
import Connect from "../../store/config/connect";

const VerifyDevice = (props) => {
  const [size, setSize] = useState([0, 0]);
  function verifyMobile() {
    return window.innerWidth <= 800;
  }

  /*eslint-disable */
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    props.dispatch(ResizeWindow(verifyMobile()));
  }, []);

  useEffect(() => {
    props.dispatch(ResizeWindow(verifyMobile()));
  }, [size]);
  /*eslint-enable */

  return null;
};

const mapStateToProps = ({ helper }) => {
  return {
    helper,
  };
};

export default Connect(mapStateToProps)(VerifyDevice);
