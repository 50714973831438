import React from "react";
import { MdClose } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import Connect from "../../../store/config/connect";
import { LogOutUser } from "../../../store/auth";

import { putUnregisterCustomer } from "../../../services/services";

import "./ModalDeleteProfile.css";

const ModalDeleteProfile = (props) => {
  let history = useHistory();

  function handleClose() {
    props.setModalClosed(true);
  }

  function handleDeletePet() {
    putUnregisterCustomer(props.userId, props.token)
      .then((res) => {
        if (res && res.status === 200) {
          props.dispatch(LogOutUser());
          history.push("/");
        }
      })
      .catch((e) => {
        console.log(e.response);
      });
  }

  return (
    <div
      id="modal--deleteProfile"
      style={{ display: !props.modalClosed ? "flex" : "none" }}
    >
      <CSSTransition
        in={!props.modalClosed}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div id="modal--deleteProfile__container">
          {/* <span>petid: {props.userId}</span> */}
          <div id="modal--deleteProfile__container--close">
            <span>
              <MdClose
                onClick={() => {
                  handleClose();
                }}
              />
            </span>
          </div>
          <div id="modal--deleteProfile__container--title">
            <span>Excluir meu perfil</span>
          </div>
          <div id="modal--deleteProfile__container--desc">
            <span>
              Você tem certeza que deseja excluir seu perfil?
              <br /> Essa ação não poderá ser desfeita.
            </span>
          </div>
          <div id="modal--deleteProfile__container--save">
            <button
              type="button"
              onClick={() => {
                handleDeletePet();
              }}
            >
              Sim
            </button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = ({ auth, helper }, props) => {
  return {
    ...auth,
    ...helper,
    ...props,
  };
};

export default Connect(mapStateToProps)(ModalDeleteProfile);
