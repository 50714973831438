import React from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import Connect from "../../../store/config/connect";

import "./ModalReminder.css";
import modalCadastrarPet from '../../../images/modal-cadastrar-pet.png';
import modalCadastrarMobile from '../../../images/modal-cadastrar-pet-mobile.png';
import closeModal from '../../../images/close-modal-cadastrar-pet.png';


const ModalReminder = (props) => {

  return (
    <div
      id="modal--reminder"
      style={{ display: props.modalClose ? "flex" : "none" }}
    >
      <CSSTransition
        in={props.modalClose}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div id="modal--reminder__container">
          <div id="modal--reminder__container--close">
            <button type="button" onClick={() => props.setModalClose(false)}><img src={closeModal} alt="icon-close-modal" /></button>
          </div>
          <div>
            <img src={modalCadastrarPet} alt="img-cadastrar-pet" id="img-cadastrar-desk" />
            <img src={modalCadastrarMobile} alt="img-cadastrar-pet" id="img-cadastrar-mobile" />
          </div>
          <div id="modal--reminder__container--desc">
            <h1>Que tal ter acesso <span>de graça</span> aos <span>maiores descontos</span> nos parceiros do Clube de Vantagens Bravecto <span>agora</span> mesmo?</h1>
            <p>
              É super <span>fácil</span> e rápido, basta cadastrar ou atualizar os dados do seu pet aqui no Clube! Automaticamente, você se tornará um membro 5 estrelas e poderá aproveitar os <span>melhores e maiores</span> benefícios dos nossos parceiros, que foram pensados <span>exclusivamente</span> para você e para o seu pet.
            </p>
            <p>Mas corre para aproveitar, que é por tempo <span>limitado!</span></p>
            <div id="modal--reminder__container--save">
              <Link to="/meus-pets">QUERO OS MELHORES DESCONTOS AGORA</Link>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Connect()(ModalReminder);
