import React from "react";
import calendar from "../../images/BravectoExperience/calendar.png";
import location from "../../images/BravectoExperience/location.png";
import maps from "../../images/BravectoExperience/maps.png";
import waze from "../../images/BravectoExperience/waze.png";

const CardStore = (props) => {
  return (
    <div className="card">
      <div className="card-content">
        <h3>{props.nome}</h3>
        <div className="infos">
          <img src={calendar} alt="icon-calendario" />
          <div>
            <p>{props.startDate} a {props.finishDate}</p>
            <p>Das {props.startHour} às {props.finishHour}</p>
          </div>
        </div>
        <div className="infos">
          <img src={location} alt="icon-localizacao" />
          <div className="address">
            <p>{`${props.street} - ${props.neighborhood}`}</p>
            <p>{`${props.city} - ${props.state}`}</p>
            <p>CEP: {props.cep}</p>
          </div>
        </div>
      </div>
      <div className="location">
        <div className="btn-loc">
          <a href={props.maps} target="_blank" rel="noopener noreferrer">
            <img src={maps} alt="icon-maps" />
            <p>Maps</p>
          </a>
        </div>
        <div className="btn-loc">
          <a href={props.waze} target="_blank" rel="noopener noreferrer">
            <img src={waze} alt="icon-waze" />
            <p>Waze</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CardStore;
