import React from "react";
// import { Helmet } from 'react-helmet';

import MetaTags from "../../MetaTags/MetaTags";

import "./Regulamento.css";

const Regulamento = () => {
  return (
    <div id="Regulamento">
      <MetaTags
        title="Confira nosso Regulamento | Clube de Vantagens Bravecto"
        description="Entenda o Regulamento do Programa de Relacionamento do Clube de Vantagens Bravecto e Navegue sem Problemas!"
      />
      <div id="Regulamento__container">
        <div id="pf2" className="pf w0 h0" data-page-no="2">
          <strong>INTRODU&Ccedil;&Atilde;O</strong>
          <br />
          <br />
          O presente regulamento (&ldquo;Regulamento&rdquo;) estabelece as
          regras para participa&ccedil;&atilde;o, bem como as vantagens e
          benef&iacute;cios do Programa de Relacionamento Clube de Vantagens
          Bravecto (&ldquo;Clube de Vantagens Bravecto&rdquo;).
          <br />
          <br />
          O Clube de Vantagens Bravecto &eacute; um programa de relacionamento
          com o cliente, mantido pela empresa MSD SA&Uacute;DE ANIMAL, pessoa
          jur&iacute;dica de direito privado, inscrita no CNPJ sob o n&deg; CNPJ
          07.954.091/0001-43 propriet&aacute;ria da marca Bravecto&reg;,
          doravante denominada apenas como MSD SA&Uacute;DE ANIMAL, ou como
          Administradora.
          <br />
          <br />
          <div>
            Para participar do Clube de Vantagens Bravecto, o consumidor
            dever&aacute; fazer seu cadastro no site{" "}
            <a href="https://www.clubebravecto.com.br" target="_blank">
              https://www.clubebravecto.com.br
            </a>{" "}
            (&ldquo;Site&rdquo;){" "}
            <span id="strongUnderlined">
              ler atentamente este Regulamento e, somente se estiver de acordo
              com todos os seus termos, efetuar o seu cadastro.
            </span>
          </div>
          <br />
          <br />
          A efetiva&ccedil;&atilde;o do cadastro no Clube de Vantagens Bravecto
          e o aceite dos Clientes do Clube Bravecto a este Regulamento se
          dar&atilde;o quando do in&iacute;cio da utiliza&ccedil;&atilde;o do
          Clube de Vantagens Bravecto pelo mesmo.
          <br />
          <br />
          Para o cadastro, ser&aacute; necess&aacute;rio que o interessado em
          participar informe em seu cadastro: nome, sobrenome, CPF/MF, data de
          nascimento, g&ecirc;nero, cidade/estado de resid&ecirc;ncia, senha de
          acesso e responder a pesquisa sobre uso dos produtos Bravecto&reg;.
          <br />
          <br />
          O Clube de Vantagens Bravecto pode oferecer os seguintes
          benef&iacute;cios:
          <br />
          <br />
          Os benef&iacute;cios consistir&atilde;o em descontos em produtos ou
          servi&ccedil;os de Parceiros do Clube de Vantagens Bravecto;
          <br />
          <br />
          Para os Clientes do Clube Bravecto terem acesso aos benef&iacute;cios
          ter&atilde;o que efetuar o cadastro no Site do Clube de Vantagens
          Bravecto e cadastrar os dados de Notas Fiscais nas quais contenham
          qualquer produto, de qualquer valor, desde que contenha qualquer
          produto que seja da Marca Bravecto;
          <br />
          <br />
          O simples cadastro no Site j&aacute; gera o acesso do Cliente do Clube
          ao primeiro n&iacute;vel, dando acesso aos primeiros descontos e
          parceiros do programa;
          <br />
          <br />
          Os n&iacute;veis de acesso aos benef&iacute;cios e suas vantagens, bem
          como, descontos, ser&atilde;o diferenciados em 5 (cinco) categorias.
          Quanto maior a categoria, maior o desconto e maior a quantidade de
          Parceiros do Clube de Vantagens Bravecto que o Cliente ter&aacute;
          acesso;
          <br />
          <br />
          A cada novo cadastro de Notas Fiscais contendo produtos Bravecto, o
          Cliente poder&aacute; subir um n&iacute;vel acima de acordo com a
          quantidade de produtos Bravecto contidas na(s) nota(s) fiscal(s), ou
          seja, o Cliente poder&aacute; comprar cinco produtos Bravecto e subir
          ao n&iacute;vel 5 de categoria independente se tais produtos foram
          comprados em uma &uacute;nica nota fiscal;
          <br />
          <br />
          Os n&iacute;veis &ndash; 1&ordm; ao 5&ordm; - ser&atilde;o
          identificados por estrelas, consistindo 1 (uma) estrela ao
          n&iacute;vel b&aacute;sico e 5 (cinco) ao n&iacute;vel mais
          avan&ccedil;ado;
          <br />
          <br />
          Quanto mais avan&ccedil;ado o n&iacute;vel, maior as vantagens e
          n&uacute;mero de parceiros dados como benef&iacute;cios do Clube de
          Vantagens Bravecto dispon&iacute;veis;
          <br />
          <br />
          Outros Benef&iacute;cios e, at&eacute; mesmo pr&ecirc;mios podem
          eventualmente ser oferecidos, contudo estes ser&atilde;o devidamente
          informados aos Clientes Clube de Vantagens Bravecto, bem como,
          ser&atilde;o regulados por documentos independentes deste, conforme
          preleciona a legisla&ccedil;&atilde;o promocional brasileira.
          <br />
          <br />
          Para informa&ccedil;&otilde;es e outras quest&otilde;es relativas ao
          Clube de Vantagens Bravecto, a Administradora disponibilizar&aacute;
          como Central de Atendimento este{" "}
          <a href="mailto:clubebravecto@merck.com">e-mail</a> para contato.
          <br />
          <br />
          <strong>PRAZO</strong>
          <br />
          <br />
          O Clube de Vantagens Bravecto vigorar&aacute; por prazo indeterminado,
          podendo a Administradora suspend&ecirc;-lo ou encerr&aacute;-lo a
          qualquer tempo, obrigando-se a comunicar os Clientes Clube de
          Vantagens Bravecto com a anteced&ecirc;ncia m&iacute;nima de 60
          (sessenta) dias, por meio de comunica&ccedil;&atilde;o no
          s&iacute;tio, envios de e-mails e SMS (desde que autorizados pelos
          usu&aacute;rios em seus cadastros), sendo que, nesse per&iacute;odo, o
          Cliente Clube de Vantagens Bravecto poder&aacute; usufruir suas
          vantagens e benef&iacute;cios.
          <br />
          <br />
          Ap&oacute;s a suspens&atilde;o ou encerramento, o Clube de Vantagens
          Bravecto estar&aacute; definitivamente encerrado, sendo que as
          vantagens n&atilde;o resgatadas ser&atilde;o canceladas. O Cliente
          Clube de Vantagens Bravecto desde j&aacute; reconhece que nenhuma
          indeniza&ccedil;&atilde;o lhe ser&aacute; devida pela Administradora
          em raz&atilde;o da suspens&atilde;o ou encerramento do Clube de
          Vantagens Bravecto.
          <br />
          <br />
          <strong>PARTICIPANTES</strong>
          <br />
          <br />
          Qualquer pessoa f&iacute;sica com idade igual ou superior a 18
          (dezoito) anos pode fazer parte do Clube de Vantagens Bravecto, de
          forma gratuita. Para efetivar a sua participa&ccedil;&atilde;o, deve
          se cadastrar, ler atentamente o presente Regulamento e manifestar a
          sua aceita&ccedil;&atilde;o a todas &agrave;s regras. Al&eacute;m
          disso, dever&aacute; indicar se deseja ou n&atilde;o receber
          comunica&ccedil;&atilde;o dirigida da Administradora.
          <br />
          <br />
          Os colaboradores da Administradora est&atilde;o autorizados a
          participar do Clube de Vantagens Bravecto, na condi&ccedil;&atilde;o
          de clientes, bem como seus familiares e dependentes.
          <br />
          <br />
          O cadastro no Clube de Vantagens Bravecto acontece de forma gratuita,
          das seguintes formas:
          <br />
          <br />
          Entrar no site{" "}
          <a href="https://www.clubebravecto.com.br" target="_blank">
            https://www.clubebravecto.com.br
          </a>{" "}
          e incluir os dados necess&aacute;rios;
          <br />
          Informar al&eacute;m dos dados necess&aacute;rios, o aceite expresso
          dos Termos de Uso, Pol&iacute;ticas de Privacidade e Regulamento;
          <br />
          <br />
          3.3. Um mesmo cliente n&atilde;o pode possuir mais de uma conta no
          Clube de Vantagens Bravecto, uma vez que ser&aacute; identificado pelo
          n&uacute;mero do seu CPF. Caso um Cliente Clube de Vantagens Bravecto
          tente fazer novo cadastro no programa, ele estar&aacute;
          impossibilitado de continuar, sendo informado que seu CPF j&aacute;
          est&aacute; cadastrado em outra conta.
          <br />
          <br />
          <strong>CANCELAMENTO DE CONTA</strong>
          <br />
          <br />
          O Cliente poder&aacute; cancelar sua conta, se assim desejar, a
          qualquer momento e por qualquer motivo, devendo, para tanto,
          demonstrar sua inten&ccedil;&atilde;o de cancelamento &agrave;
          Administradora, pelo Site ou por e-mail.
          <br />
          <br />
          4.1.1. O cancelamento de conta efetivado tamb&eacute;m gerar&aacute;
          automaticamente o cancelamento de eventuais vantagens em aberto
          (n&atilde;o utilizadas at&eacute; a data do cancelamento) do Cliente.
          <br />
          <br />
          Caso o Cliente Clube de Vantagens Bravecto venha a falecer, e, havendo
          terceiro interessado no cancelamento de sua conta, dever&aacute; ser
          comprovada sua morte, pela comunica&ccedil;&atilde;o do &oacute;bito
          &agrave; Central de Atendimento por este{" "}
          <a href="mailto:clubebravecto@merck.com">e-mail</a> e entrega da
          c&oacute;pia do atestado de &oacute;bito por meio do endere&ccedil;o
          eletr&ocirc;nico ou outro meio indicado durante o contato, sendo
          exclu&iacute;da sua conta. Nesse caso, ocorrer&aacute; o cancelamento
          do n&iacute;vel ao momento do Cliente Clube Bravecto e a perda
          imediata do direito &agrave; utiliza&ccedil;&atilde;o de quaisquer
          pr&ecirc;mios e benef&iacute;cios, que s&atilde;o
          intransfer&iacute;veis.
          <br />
          <br />
          A participa&ccedil;&atilde;o no Clube de Vantagens Bravecto &eacute;
          pessoal e n&atilde;o poder&aacute; ser transferido para qualquer outro
          Cliente Clube de Vantagens Bravecto, mesmo membros da mesma
          fam&iacute;lia, n&atilde;o sendo admitida, ainda, sua
          negocia&ccedil;&atilde;o ou substitui&ccedil;&atilde;o por outra
          esp&eacute;cie de bens ou servi&ccedil;os, nem sua convers&atilde;o em
          dinheiro.
          <br />
          <br />
          <br />
          <strong>MEC&Acirc;NICA MEUS BENEF&Iacute;CIOS</strong>
          <br />
          <br />
          Para participar da mec&acirc;nica Meus Benef&iacute;cios, o Cliente
          Clube de Vantagens Bravecto dever&aacute; seguir os seguintes passos:
          <br />
          <br />
          Cadastrar-se no Site;
          <br />
          <br />
          J&aacute; sendo cadastrado como Cliente Clube de Vantagens Bravecto,
          acessar o Site, fazer o login e, se necess&aacute;rio, passar pela
          valida&ccedil;&atilde;o positiva para criar uma nova senha.
          <br />
          <br />
          Incluir no seu cadastro os dados das compras efetuadas (dados de Nota
          Fiscal requeridos) de produtos Bravecto, os quais dar&atilde;o direito
          de uso dos benef&iacute;cios do Clube de Vantagens Bravecto, conforme
          o n&iacute;vel ao momento do resgate do benef&iacute;cio(s). A
          inclus&atilde;o dos dados de compra ser&atilde;o realizadas da
          seguinte maneira:
          <br />
          <br />
          i) Notas fiscais v&aacute;lidas ser&atilde;o todas aquelas com data de
          compra de at&eacute; 90 (noventa) dias antes da data de cadastro da
          Nota Fiscal e que possuam produtos Bravectos registrados.
          <br />
          <br />
          ii) Campos que o Cliente dever&aacute; preencher:
          <br />
          &nbsp; &nbsp; - Envio de uma Foto (.jpeg, .jpg, .png, .gif) ou arquivo
          PDF da referida Nota ou Cupom Fiscal;
          <br />
          &nbsp; &nbsp; - Data da compra;
          <br />
          &nbsp; &nbsp; - CNPJ da loja onde efetuou aquela compra;
          <br />
          &nbsp; &nbsp; - Nome da loja onde efetuou aquela compra;
          <br />
          - N&uacute;mero da Nota Fiscal;
          <br />
          - Produtos/Quantidade.
          <br />
          <br />
          As Notas Fiscais cadastradas ser&atilde;o validadas pela
          Administradora para que haja a progress&atilde;o de n&iacute;vel e
          libera&ccedil;&atilde;o das vantagens respectivas. Em caso de
          reprova&ccedil;&atilde;o, a Administradora informar&aacute; o motivo.
          Todas as informa&ccedil;&otilde;es de notas fiscais cadastradas,
          hist&oacute;rico de aprova&ccedil;&atilde;o ou
          reprova&ccedil;&atilde;o com seus motivos poder&atilde;o ser
          encontradas na &aacute;rea &ldquo;Minhas Compras&rdquo; que s&oacute;
          o Cliente ter&aacute; acesso e poder&aacute; consultar o status de
          aprova&ccedil;&atilde;o e seu hist&oacute;rico sempre que desejar.
          Sendo assim, sempre haver&aacute;:
          <br />
          <br />
          Valida&ccedil;&atilde;o da Foto e campos de preenchimento da Nota
          Fiscal que ser&aacute; realizada em at&eacute; 10 (dez) dias.
          <br />
          Aparecer&aacute; em menu espec&iacute;fico de "Minhas Compras" as
          seguintes infos: Data (submiss&atilde;o - autom&aacute;tica).
          <br />
          Status (Em An&aacute;lise / Aprovada / Reprovada). Em caso de
          reprova&ccedil;&atilde;o, ter&aacute; texto informando o motivo.
          <br />
          <br />
          Os benef&iacute;cios ser&atilde;o categorizados em 5 n&iacute;veis;
          cada produto Bravecto, de qualquer valor, na Nota fiscal cadastrada,
          ir&atilde;o gerar 1 (uma) estrela e a cada estrela o Cliente Clube de
          Vantagens Bravecto ter&aacute; acesso &agrave; um n&iacute;vel de
          Benef&iacute;cios.
          <br />
          <br />
          O n&iacute;vel m&aacute;ximo a ser atingido por cada usu&aacute;rio
          ser&aacute; o n&iacute;vel de 5 estrelas. As estrelas
          corresponder&atilde;o ao tipo e abrang&ecirc;ncia do benef&iacute;cio
          a ser acessado pelo Cliente Clube de Vantagens Bravecto perante os
          parceiros do Clube de Vantagens Bravecto.
          <br />
          <br />
          Exemplo: o cliente acessou o Site e simplesmente efetuou seu cadastro
          completo, com isso ele j&aacute; atingiu o primeiro n&iacute;vel do
          Clube de Vantagens Bravecto, tendo seu n&iacute;vel com 1 (uma)
          estrela, j&aacute; abrindo o benef&iacute;cio perante 2 (dois)
          parceiros &ndash; ou mais &ndash; deste n&iacute;vel.
          <br />
          <br />
          Na mesma navega&ccedil;&atilde;o, o cliente cadastrou os dados de 1
          (uma) nota fiscal que continha a aquisi&ccedil;&atilde;o de 1 (um)
          produto Bravecto, de qualquer valor, e, automaticamente, passou do
          n&iacute;vel 1 para o n&iacute;vel 2, abrindo a possibilidade de
          usufruir outros benef&iacute;cios perante outros parceiros, agora do
          n&iacute;vel 2, e continuando acessar os parceiros do n&iacute;vel 1
          (um).
          <br />
          <br />
          As regras e mec&acirc;nicas de progress&atilde;o e regress&atilde;o
          dos n&iacute;veis aludidos e praticados no Clube est&atilde;o
          explicadas no item 5.2 deste Regulamento.
          <br />
          <br />
          <br />O Cliente Clube de Vantagens Bravecto dever&aacute; ativar o(s)
          benef&iacute;cio(s) escolhido(s) no Site e resgat&aacute;-lo(s)
          perante o Parceiro, conforme as instru&ccedil;&otilde;es{" "}
          <span id="strongUnderlined">descritas no Site</span> de cada
          benef&iacute;cio(s).
          <br />
          <br />
          A ativa&ccedil;&atilde;o acontece clicando no bot&atilde;o
          &ldquo;Resgatar&rdquo; que aparece no cat&aacute;logo de
          benef&iacute;cios que &eacute; apresentado no Site. Se o Cliente Clube
          de Vantagens Bravecto n&atilde;o fizer o resgate, n&atilde;o
          poder&aacute; usar o benef&iacute;cio, que s&oacute; &eacute; ativado
          pelo processo de resgate. Uma vez solicitado o resgate n&atilde;o
          h&aacute; possibilidade de altera&ccedil;&atilde;o ou cancelamento do
          benef&iacute;cio.
          <br />
          <br />
          No processo de resgate, ser&aacute; gerado um c&oacute;digo ou um link
          de redirecionamento ou outro tipo de c&oacute;digo, que dever&aacute;
          ser apresentado pelo Cliente Clube de Vantagens Bravecto ao Parceiro
          para usufruto do benef&iacute;cio(s).
          <br />
          <br />
          Em alguns casos, o uso do benef&iacute;cio(s) pelo Cliente Clube
          Bravecto ou cr&eacute;dito de Parceiro ser&aacute; realizada
          utilizando-se de dados cadastrais como endere&ccedil;o, e-mail,
          telefone e CPF. Cada Parceiro tem suas pr&oacute;prias regras de
          obten&ccedil;&atilde;o de benef&iacute;cios, que dever&atilde;o ser
          consultadas antes do Cliente Clube de Vantagens Bravecto optar por
          este.
          <br />
          <br />
          &Eacute; de responsabilidade exclusiva do Parceiro a oferta dada e o
          benef&iacute;cio ofertado. O Clube de Vantagens Bravecto trata-se
          t&atilde;o somente de um facilitador de vantagens entre o Cliente e o
          Parceiro, a partir de uma rela&ccedil;&atilde;o j&aacute; estabelecida
          com a Administradora a partir da compra de seus produtos.
          <br />
          <br />O prazo ou validade de cada benef&iacute;cio estar&aacute;
          descrito no Site, juntamente com a oferta do Parceiro do Clube de
          Vantagens Bravecto, portanto,{" "}
          <strong>
            ANTES DE RESGATAR SEU BENEF&Iacute;CIO, LEIA ATENTAMENTE AS REGRAS
            DA OFERTA. O CLUBE DE VANTAGENS BRAVECTO N&Atilde;O &Eacute;
            RESPONS&Aacute;VEL PELO O N&Atilde;O ATENDIMENTO DAS REGRAS PELO
            CLIENTE CLUBE DE VANTAGENS BRAVECTO PERANTE O PARCEIRO QUE
            IMPOSSIBILITE SEU USUFRUTO.
          </strong>
          <br />
          <br />
          <br />
          A mec&acirc;nica e regras de progress&atilde;o (&ldquo;Upgrade&rdquo;)
          ou regress&atilde;o (&ldquo;Downgrade&rdquo;) de n&iacute;vel dentro
          do Clube de Vantagens Bravecto se dar&aacute; da seguinte forma:
          <br />
          <br />
          Upgrade: Todos os n&iacute;veis (&ldquo;Estrelas&rdquo;)
          valer&atilde;o por 365 (trezentos e sessenta e cinco) dias a partir da
          &uacute;ltima data de aprova&ccedil;&atilde;o da respectiva Nota
          Fiscal cadastrada, independente da data da compra, considerando apenas
          o que diz o item 5.1, &ldquo;c&rdquo;, &ldquo;i&rdquo; deste
          Regulamento.&nbsp;
          <br />
          Downgrade: As Estrelas expiram, consequente fazendo o Cliente regredir
          de n&iacute;vel, se n&atilde;o houver nenhum novo cadastro de Nota
          Fiscal, de acordo com o j&aacute; exposto neste documento, submetida
          (n&atilde;o necessariamente aprovada, ou seja, o prazo de
          aprova&ccedil;&atilde;o da nova nota fiscal n&atilde;o contar&aacute;
          para o disposto aqui) em 365 (trezentos e sessenta e cinco) dias da
          &uacute;ltima data de aprova&ccedil;&atilde;o da &uacute;ltima Nota
          Fiscal cadastrada pelo Cliente.
          <br />
          Ap&oacute;s o primeiiro Downgrade, o prazo de expira&ccedil;&atilde;o
          de estrelas passa a ser de 30 (trinta) dias.
          <br />
          <br />
          Exemplo 1:
          <br />
          Cliente N&iacute;vel 2 (2 Estrelas): Ap&oacute;s 365 dias, volta para
          o n&iacute;vel 1 (1 Estrela) por prazo indeterminado at&eacute; que
          efetue novo cadastro de nota fiscal de acordo com as regras aqui
          estabelecidas.
          <br />
          <br />
          Exemplo 2:
          <br />
          &nbsp;Cliente N&iacute;vel 3 (3 Estrelas): Ap&oacute;s 365 dias, volta
          para o N&iacute;vel 2 (2 Estrelas) por 30 dias, caso n&atilde;o efetue
          novo cadastro de nota fiscal, ap&oacute;s esses 30 dias, volta para o
          n&iacute;vel 1 (1 estrela), sendo assim, o prazo para o Cliente 3
          estrelas ir para 1 Estrela, nesse exemplo, ser&aacute; de 395 dias.
          <br />
          <br />
          Exemplo 3:
          <br />
          Cliente N&iacute;vel 5&nbsp;(5 Estrelas): Ap&oacute;s 365 dias, volta
          para N&iacute;vel 4 por 30 dias, caso n&atilde;o efetue novo cadastro
          de nota fiscal, ap&oacute;s esses 30 dias volta para N&iacute;vel 3
          por 30 dias, caso n&atilde;o efetue novo cadastro de nota fiscal volta
          para N&iacute;vel 2 por 30 dias, caso n&atilde;o efetue novo cadastro
          de nota fiscal volta para N&iacute;vel 1. Ent&atilde;o, nesse exemplo,
          o prazo para o Cliente 5 estrelas ir para 1 estrela ser&aacute; de 455
          dias.
          <br />
          <br />
          <br />
          A Administradora reserva-se o direito de tornar determinados
          benef&iacute;cios indispon&iacute;veis a qualquer tempo.
          <br />
          <br />
          <br />
          Benef&iacute;cios de Parceiros: S&atilde;o produtos ou servi&ccedil;os
          ofertados por parceiros do Clube de Vantagens Bravecto. O
          benef&iacute;cio dever&aacute; ser resgatado no Site, sendo gerado um
          um c&oacute;digo ou um link de redirecionamento ou outro tipo de
          c&oacute;digo, que dever&aacute; ser apresentado no Parceiro para
          usufruto do Pr&ecirc;mio de Parceiro. Os Parceiros ser&atilde;o
          identificados pelo Site.
          <br />
          O Cliente Clube de Vantagens Bravecto dever&aacute; observar as regras
          dos Parceiros para usufruto dos Pr&ecirc;mios de Parceiro. Para alguns
          casos, a entrega do Pr&ecirc;mio de Parceiro ser&aacute; realizada
          utilizando-se de dados cadastrais como endere&ccedil;o, e-mail
          telefone e CPF.
          <br />
          A quantidade de uso de cada benef&iacute;cio n&atilde;o &eacute;
          cumulativa, ou seja, o Cliente Clube de Vantagens Bravecto pode
          utilizar de tantas vezes desejar, enquanto estiver vigente a oferta
          daquele benef&iacute;cio pelo parceiro do clube no Site.
          <br />
          <br />
          <br />
          O Cliente Clube de Vantagens Bravecto ter&aacute; um controle virtual
          no Site onde poder&aacute; verificar em qual n&iacute;vel se encontra
          e quais benef&iacute;cios est&atilde;o sendo ofertados no Site, bem
          como, suas condi&ccedil;&otilde;es. Este controle virtual ser&aacute;
          a &aacute;rea do Site &ldquo;Meus Benef&iacute;cios.
          <br />
          <br />
          A Administradora reserva-se o direito de ofertar vantagens em
          descontos de produtos Bravecto de forma ocasional para os Clientes
          (&ldquo;Ofertas de Oportunidade&rdquo;). Sempre que isto ocorrer tais
          ofertas estar&atilde;o dispostas ao Cliente por tempo determinado e
          ter&aacute; suas regras de uso descritos na pr&oacute;pria oferta, no
          Site ou por comunica&ccedil;&atilde;o via SMS ou mobile, ou ainda por
          e-mail, de acordo com as possibilidades vislumbradas pela
          Administradora e formas de comunica&ccedil;&atilde;o aceitas pelo
          Cliente.
          <br />
          <br />
          <br />
          <strong>DEVOLU&Ccedil;&Otilde;ES E CANCELAMENTO DE COMPRAS</strong>
          <br />
          <br />
          No caso de troca por outros produtos, cancelamento de compra ou uso do
          direito de arrependimento em aquisi&ccedil;&otilde;es feitas via
          internet e/ou fora do estabelecimento comercial do vendedor,
          impossibilitar&atilde;o o cadastro da Nota Fiscal do Site do Clube de
          Vantagens Bravecto e, caso esta j&aacute; tendo sido computada,
          ser&aacute; imediatamente cancelada pela Administradora.
          <br />
          <br />
          <strong>FRAUDE</strong>
          <br />
          <br />
          <div id="strongUnderlined">
            Caso a Administradora detecte ind&iacute;cios de uso fraudulento dos
            Benef&iacute;cos, ou subterf&uacute;gios do Cadastro das Notas
            Fiscais de aquisi&ccedil;&atilde;o dos produtos Bravecto ou de
            alguma forma de identifica&ccedil;&atilde;o no &acirc;mbito do
            programa do Clube de Vantanges Bravecto, bem como seu uso em
            desacordo com as disposi&ccedil;&otilde;es do presente Regulamento,
            estar&aacute; habilitada a apurar eventual fraude ou uso indevido,
            nos termos que entender relevantes, para confirmar o uso fraudulento
            da conta, inclusive acionando &oacute;rg&atilde;os administrativos,
            policiais e judiciais necess&aacute;rios para empreender defesa aos
            interesses do Clube, do Site, da Administradora e dos demais
            Clientes.
            <br />
            <br />
            Caso seja apurado o uso fraudulento ou o uso em desacordo com as
            disposi&ccedil;&otilde;es do presente Regulamento, a Administradora
            poder&aacute; bloquear o n&iacute;vel do Cliente Clube de Vantagens
            Bravecto ou at&eacute; mesmo suspender seu acesso ou
            progress&atilde;o de n&iacute;vel, bloquear o resgate de
            benef&iacute;cios ou excluir os Clientes do Clube de Vantagens
            Bravecto, que n&atilde;o poder&atilde;o fazer parte do programa/se
            recadastrar pelos 12 (doze) meses seguintes.
          </div>
          <br />
          <br />
          <strong>DISPOSI&Ccedil;&Otilde;ES GERAIS</strong>
          <br />
          <br />
          <br />
          Haver&aacute; pr&ecirc;mios e benef&iacute;cios que demandar&atilde;o
          a fixa&ccedil;&atilde;o de regras espec&iacute;ficas, que ser&atilde;o
          estabelecidas pela Administradora, seus fornecedores e/ou parceiros em
          regulamentos &agrave; parte e ser&atilde;o divulgados oportunamente,
          podendo tais pr&ecirc;mios e benef&iacute;cios serem promocionais e
          por tempo determinado.
          <br />
          <br />
          &Eacute; condi&ccedil;&atilde;o para o resgate de pr&ecirc;mios
          eventuais e benef&iacute;cios que o Cliente Clube de Vantagens
          Bravecto tenha seu cadastro atualizado. A atualiza&ccedil;&atilde;o
          cadastral &eacute; de exclusiva responsabilidade do Cliente.
          <br />
          <br />
          A Administradora poder&aacute;, a qualquer momento, alterar as formas
          de resgate de benef&iacute;cios e sempre que isto ocorrer
          avisar&aacute; aos Clientes pelos meios necess&aacute;rios, sendo
          sempre fixados no pr&oacute;prio Site de forma ostensiva e por tempo
          determinado mas suficiente para conhecimento do Cliente.
          <br />
          <br />
          A Administradora poder&aacute;, a seu exclusivo crit&eacute;rio,
          encerrar a conta de Clientes Clube de Vantagens Bravecto que, de
          qualquer forma, negociem, transfiram ou fraudem benef&iacute;cios de
          maneira n&atilde;o autorizada por este Regulamento.
          <br />
          <br />
          Se qualquer parte deste Regulamento for considerada inv&aacute;lida ou
          inexequ&iacute;vel, tal trecho deve ser interpretado de forma
          consistente com a lei aplic&aacute;vel, para refletir, na medida do
          poss&iacute;vel, a inten&ccedil;&atilde;o original das partes, e as
          demais disposi&ccedil;&otilde;es permanecer&atilde;o em pleno vigor e
          efeito.
          <br />
          <br />
          Qualquer toler&acirc;ncia das partes com rela&ccedil;&atilde;o ao
          descumprimento de alguma previs&atilde;o deste Regulamento n&atilde;o
          implicar&aacute; nova&ccedil;&atilde;o ou ren&uacute;ncia de direitos,
          de modo que a parte tolerante poder&aacute;, a qualquer momento,
          exigir da outra o fiel cumprimento das disposi&ccedil;&otilde;es deste
          documento.
          <br />
          <br />
          As partes elegem o foro do domic&iacute;lio do Cliente Clube de
          Vantagens Bravecto como competente para conhecer e dirimir quaisquer
          quest&otilde;es oriundas deste Regulamento.
          <br />
          <br />
          <strong>S&atilde;o Paulo, 18 de dezembro de 2019.</strong>
        </div>
      </div>
    </div>
  );
};

export default Regulamento;
