import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import { FaRegCheckCircle } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { MdArrowDropUp } from "react-icons/md";

import Connect from "../../../store/config/connect";

import Input from "../../Utils/Input/Input";
import LoaderForButton from "../../Utils/LoaderForButtons/LoaderForButtons";

import { postChangePassword } from "../../../services/services";

import "./ModalChangePassword.css";

const ModalChangePassword = (props) => {
  let history = useHistory();

  const [hasFormErrors, setHasFormErrors] = useState(false);

  const [tooltipPassword, setTooltipPassword] = useState(false);

  const [passwordMask, setPasswordMask] = useState(false);

  const [inputActualPasswordValue, setInputActualPasswordValue] = useState("");
  const [inputActualPassword] = useState({
    type: "password",
    name: "password",
    replaceValueOnChange: true,
    onChange: function (pass) {
      // const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
      // setInputActualPasswordValue(re.test(pass.toString()) ? pass : false);
      setInputActualPasswordValue(pass);
      return pass;
    },
  });

  const [inputPasswordValue, setInputPasswordValue] = useState("");
  const [inputPassword] = useState({
    type: "password",
    name: "password",
    replaceValueOnChange: true,
    onChange: function (pass) {
      const testAllMasks =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/g;
      const testNumber = /(\d)/g;
      const testLowerCase = /([a-z])/g;
      const testUpperCase = /([A-Z])/g;
      const testSymbols = /([@$!%*?&#])/g;
      setPasswordMask({
        uppercase: testUpperCase.test(pass.toString()) ? true : false,
        lowercase: testLowerCase.test(pass.toString()) ? true : false,
        numbers: testNumber.test(pass.toString()) ? true : false,
        symbols: testSymbols.test(pass.toString()) ? true : false,
        length: pass.length > 8 ? true : 8 - pass.length,
      });

      if (testAllMasks.test(pass.toString())) {
        setPasswordMask(false);
      }

      setInputPasswordValue(pass);
      return pass;
    },
    onBlur: function () {
      setTooltipPassword(false);
    },
    onFocus: function () {
      setTooltipPassword(true);
    },
  });

  const [inputConfirmPasswordValue, setInputConfirmPasswordValue] =
    useState("");
  const [inputConfirmPassword] = useState({
    type: "password",
    name: "confirmPassword",
    replaceValueOnChange: true,
    onChange: function (confirmPass) {
      setInputConfirmPasswordValue(confirmPass);
      return confirmPass;
    },
  });

  function handleClose() {
    props.setModalClosed(true);
  }

  const [isSubmitting, setSubmitting] = useState(false);

  function formValidation() {
    let formErrors = {};
    const fields = {
      id: props.userId,
      currentPassword: inputActualPasswordValue,
      password: !passwordMask
        ? inputPasswordValue === inputConfirmPasswordValue
          ? inputPasswordValue
          : false
        : false,
      confirmPassword: !passwordMask
        ? inputConfirmPasswordValue === inputPasswordValue
          ? inputConfirmPasswordValue
          : false
        : false,
    };

    Object.keys(fields).map((i) => {
      if (!fields[i]) {
        formErrors[i] = true;
      }
      return formErrors[i];
    });

    console.log(formErrors);

    if (Object.keys(formErrors).length > 0) {
      setHasFormErrors(formErrors);
      setSubmitting(false);
      return false;
    } else {
      return fields;
    }
  }

  const [hasRequestErrors, setHasRequestErrors] = useState(false);
  const [succesSubmit, setSuccessSubmit] = useState(false);
  function handleSubmit(e) {
    setSubmitting(true);
    setHasFormErrors(false);
    e.preventDefault();
    const payload = formValidation();

    postChangePassword(payload, props.token)
      .then((res) => {
        if (res && res.status === 200) {
          setSuccessSubmit(true);
          setSubmitting(false);
        }
      })
      .catch((e) => {
        setSuccessSubmit(false);
        setSubmitting(false);
        setHasRequestErrors(
          e.response.data.errors.map((i) => {
            return i.message + "  ";
          })
        );
      });
  }

  return (
    <div
      id="modal--changePassword"
      style={{ display: !props.modalClosed ? "flex" : "none" }}
    >
      <CSSTransition
        in={!props.modalClosed}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div id="modal--changePassword__container">
          <div id="modal--changePassword__container--close">
            <span>
              <MdClose
                onClick={() => {
                  handleClose();
                }}
              />
            </span>
          </div>
          {succesSubmit === true ? (
            <div id="passwordChanged">
              <div id="passwordChanged__container">
                <FaRegCheckCircle />
                <div id="passwordChanged__text">
                  <span id="passwordChanged__text--little">
                    Senha alterada <br /> <b>com sucesso</b>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div id="modal--changePassword__container--title">
                <span>Alterar senha</span>
              </div>
              <form onSubmit={handleSubmit}>
                <div id="row">
                  <div id="content">
                    <span>Senha atual:</span>
                  </div>
                  <Input {...inputActualPassword} />
                </div>
                <div id="row">
                  <div id="content">
                    <span>Nova senha:</span>
                  </div>
                  <Input {...inputPassword} />
                  <div
                    id="error__field"
                    style={{ display: passwordMask ? "flex" : "none" }}
                  >
                    <span>
                      ainda faltam{" "}
                      {!passwordMask.uppercase && `letras maiúsculas /`}{" "}
                      {!passwordMask.lowercase && `letras minúsculas / `}{" "}
                      {!passwordMask.numbers && `números / `}{" "}
                      {!passwordMask.symbols && `símbolos / `}{" "}
                      {passwordMask.length !== true &&
                        `${passwordMask.length} caracteres`}
                    </span>
                  </div>
                  <div
                    id="arrowUpTooltip"
                    style={{ display: tooltipPassword ? "flex" : "none" }}
                  >
                    <MdArrowDropUp />
                  </div>
                  <div
                    id="tooltipPassword"
                    style={{ display: tooltipPassword ? "flex" : "none" }}
                  >
                    <div id="tooltipContent">
                      <span>
                        A senha deve conter no mínimo:
                        <br />8 caracteres, sendo: <br /> - ao menos 1 letra
                        maiúscula <br /> - ao menos 1 letra minúscula <br /> -
                        ao menos 1 número <br /> - ao menos 1 símbolo (ex @ & ?
                        $)
                      </span>
                    </div>
                  </div>
                </div>
                <div id="row">
                  <div id="content">
                    <span>Confirmar senha:</span>
                  </div>
                  <Input {...inputConfirmPassword} />
                  <div
                    id="error__field"
                    style={{
                      display: hasFormErrors.confirmPassword ? "flex" : "none",
                    }}
                  >
                    <span>Senhas não conferem</span>
                  </div>
                </div>
                <div id="modal--changePassword__container--save">
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <LoaderForButton />
                    ) : (
                      <span>Alterar minha senha</span>
                    )}
                  </button>
                </div>
                {hasRequestErrors && (
                  <div id="changePassword__requestError">
                    <span>{hasRequestErrors}</span>
                  </div>
                )}
              </form>
            </React.Fragment>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = ({ auth, helper }, props) => {
  return {
    ...auth,
    ...helper,
    ...props,
  };
};

export default Connect(mapStateToProps)(ModalChangePassword);
