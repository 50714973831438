import React from "react";

import MetaTags from "../../MetaTags/MetaTags";

import "./PoliticaPrivacidade.css";

const PoliticaPrivacidade = (props) => {
  return (
    <div id="PoliticaPrivacidade">
      <MetaTags
        title="Confira a Política de Privacidade | Clube de Vantagens Bravecto"
        description="Conheça a nossa Política de Privacidade, entenda os Termos de Uso do site Clube de Vantagens Bravecto e navegue sem problemas! "
      />
      <div id="PoliticaPrivacidade__container">
        <h3>1. Nossa Política de Privacidade</h3>

        <p>
          Este SITE é mantido pela empresa <strong>MSD SAÚDE ANIMAL</strong>,
          pessoa jurídica de direito privado, inscrita no CNPJ sob o n° CNPJ
          07.954.091/0001-43 proprietária da marca Bravecto®, doravante
          denominada apenas como <strong>MSD SAÚDE ANIMAL</strong>
          ou MANTENEDORA deste site.
        </p>

        <p>
          Este documento refere-se exclusivamente ao SITE
          “http://www.clubebravecto.com.br/” e descreve as informações que são
          coletadas pela MSD SAÚDE ANIMAL e como elas são usadas e protegidas.
          Para outras informações sobre Politica de Privacidade da MSD saúde
          Animal, acesse:{" "}
          <a href="https://www.msdprivacy.com/br/pt/index.html" target="_blank">
            MSD Privacy
          </a>
        </p>

        <p>
          Permitindo que o SITE rode em seu programa de navegação, você indica
          que concorda com os usos de dados pessoais que estão descritos aqui.
          Assim, se você não concordar com algum termo, por favor, não acesso o
          SITE.
        </p>

        <h3>2. Coleta de informações pessoais</h3>

        <p>
          Para finalidade deste documento, as informações pessoais as quais nos
          referimos, são aquelas que possam identificar as pessoas a quem
          pertencem, como por exemplo, nome, endereço, número de telefone,
          endereço de e-mail, dentre outras e que não estão disponíveis ao
          público, mas que em algum momento podem ser requeridas para cadastros
          ou acessos aos conteúdos restritos ou exclusivos.
        </p>

        <p>
          A <strong>MSD SAÚDE ANIMAL</strong> somente coletará quaisquer
          informações pessoais dos USUÁRIOS se forem fornecidas voluntariamente,
          considerando nisto o disposto nos Termos de Uso do SITE e também nesta
          Política de Privacidade. Portanto, quando você nos permite acessar
          suas informações pessoais, está nos autorizando a usá-las de acordo
          com os termos desta política de privacidade.
        </p>

        <h3>3. Como usamos as suas informações</h3>
        <p>
          Quando você permitir o acesso às informações de navegação, elas serão
          usadas para o fim específico para o qual foram coletadas. Elas também
          podem ser usadas para que possamos enviar-lhe informações e/ou ofertas
          sobre nossos produtos e/ou serviços que acreditamos ser de seu
          interesse.
        </p>

        <p>
          Poderemos também compartilhá-las ou repassá-las para outras empresas
          de propriedade ou participação, marcas ou projetos da{" "}
          <strong>MSD SAÚDE ANIMAL</strong>, cuidadosamente selecionadas por
          esta, que possam desejar encaminhar-lhe informações e/ou ofertas sobre
          seus produtos/serviços. A qualquer momento, entretanto, você poderá
          pedir a exclusão desse contato a qualquer momento, bastando para
          tanto, nos solicitar isto.
        </p>

        <p>
          Também informamos que eventualmente, poderemos contratar empresas
          terceirizadas para processar ou analisar os dados que coletamos em
          nosso SITE.
        </p>

        <p>
          Esses terceiros não estão autorizados por nós a usar as suas
          informações de qualquer outra maneira.
        </p>

        <p>
          Observe, ainda, que liberaremos as suas informações pessoais, se assim
          for exigido por lei, por mandado de busca, intimação ou ordem judicial
          para exercer nossos direitos legais ou empreender defesa contra ações
          judiciais.
        </p>

        <h3>4. Outras informações que poderemos coletar</h3>

        <p>
          Acessando este SITE, informamos que poderão ser usados "cookies" -
          blocos de informações que são armazenados pelo navegador no disco
          rígido de seu computador - para coletar outras informações durante sua
          visita ao nosso SITE, como as áreas que visitou e os serviços que
          utilizou. Sendo assim, poderão ser coletadas informações para melhor
          adequar produtos/serviços aos seus interesses e necessidades.{" "}
        </p>

        <p>
          Informamos que poderemos medir a eficácia de nossa presença na web,
          através de ferramentas de medição, que poderão ser usadas tanto para
          personalizar a sua experiência no SITE como para avaliar - de forma
          anônima e conjunta - estatísticas sobre seu uso. Informações sobre o
          seu computador, como endereço de IP (um número designado ao seu
          computador sempre que você navega na Internet), sobre o tipo de
          navegador e o sistema operacional também podem ser coletadas e
          vinculadas aos seus dados pessoais. Isso é para assegurar o
          aprimoramento contínuo do conteúdo deste SITE.
        </p>

        <p>
          Além disso podemos coletar informações por meio de "internet tags"
          (também conhecidas como single-pixel gifs e clear gifs). Elas indicam,
          por exemplo, quantas vezes uma página é aberta e qual informação é
          consultada. Essas informações podem ser compartilhadas com terceiros
          que apoiem de forma direta as atividades promocionais e de marketing
          digital da MSD SAÚDE ANIMAL e o desenvolvimento de sites, como as
          nossas agências de publicidade, com o objetivo de melhor direcionar os
          anúncios nos "banners" em sites da MSD SAÚDE ANIMAL.
        </p>

        <p>
          As informações coletadas por "internet tags" não são pessoalmente
          identificáveis, embora possam ser vinculadas às suas informações
          pessoais.
        </p>

        <h3>5. Segurança das informações</h3>

        <p>
          Para uma melhor experiência em nosso SITE, afirmamos que dentro
          daquilo que nos cabe, tomamos as precauções possíveis para manter as
          suas informações pessoais protegidas. Todas as informações
          pessoalmente identificáveis estão sujeitas a medidas de segurança para
          impedir acesso, uso ou divulgação não-autorizados.
        </p>

        <p>
          Informamos também que na qualidade de MSD SAÚDE ANIMAL não nos
          responsabilizamos por quebras e/ou violações nas informações dos
          usuários do SITE, por conta de intenções de má-fé de terceiros e/ou
          falha, inobservância ou imperícia do USUÁRIO.{" "}
        </p>

        <h3>6. Privacidade de menores de idade </h3>

        <p>
          Este Site não está estruturado para atrair menores de idade, nos
          termos da legislação vigente, mesmo que emancipados, e não oferece
          quaisquer serviços para estes. Por isso, não temos a intenção de
          coletar informações pessoais de menores de idade, mesmo que
          emancipados. Por este motivo, qualquer cadastro realizado que venha a
          ser comprovadamente de pessoas com idade inferior a 18 (dezoito) anos
          de idade serão automaticamente desabilitados.
        </p>

        <h3>7. Considerações Finais</h3>
        <p>
          A MSD SAÚDE ANIMAL reserva-se o direito de alterar ou remover esta
          política privacidade a seu critério. Recomendamos que visite esta área
          periodicamente para se manter informado sobre quaisquer alterações,
          que serão publicadas aqui sem prévio aviso.
        </p>

        <p>
          Se você quiser contatar-nos para fazer comentários ou esclarecer
          dúvidas sobre este documento ou sobre o SITE, bem como, suas
          informações, por favor, entre em contato conosco através através do
          e-mail neste <a href="mailto:clubebravecto@merck.com">link</a>.
        </p>

        <h3>8. Lembre-se </h3>

        <p>
          Este SITE está vinculado a marca MSD SAÚDE ANIMAL, portanto, para
          estar aqui você deve ter idade igual ou superior a 18 (dezoito) anos.
        </p>

        <p>
          O não aceite integral deste documento implicará na impossibilidade de
          finalização do cadastro perante o site, bem como, o não acesso ao
          conteúdo ] e serviços lá disponíveis.
        </p>

        <p>
          Não sendo finalizado o cadastro, conforme parágrafo anterior, qualquer
          dado já enviado até o momento será terminantemente descartado dos
          nossos servidores e banco de dados.
        </p>
      </div>
    </div>
  );
};

export default PoliticaPrivacidade;
