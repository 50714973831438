import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { slugify } from "../../../utils/helpers/general";

import "./MobileProducts.css";

const MobileProducts = (props) => {
  const [products, setProducts] = useState([]);

  // const api = "https://clubebravectoapi.shiftinc.com.br/api/v1/";
  var url = window.location.href;
  const api = //url.includes('localhost') ||
     url.includes("clubebravecto") && !url.includes("shiftinc")
       ? "https://api.clubebravecto.com.br/api/v1/"
       : "https://clubebravectoapi.shiftinc.com.br/api/v1/";

  useEffect(() => {
    setProducts(props.products);
  }, [props.products]);

  return (
    <div id="product-list-mobile">
      {products.map((i) => {
        return (
          <div id="product" key={`mobile-${i.id}`}>
            <div id="product__container">
              <div id="product__container--logo">
                <span
                  id="image__path"
                  style={{
                    backgroundImage: `url("${api}image/getImage/${i.id}/Product")`,
                  }}
                ></span>
                <div id="logo--stars">
                  <div id="logo--stars__container">
                    <div id="star">
                      <span>{i.rate}</span>
                    </div>
                    <div id="star-ghosting-middle"></div>
                    <div id="star-ghosting-last"></div>
                  </div>
                </div>
              </div>
              <div id="product__container--content">
                <span
                  id="logo"
                  style={{
                    backgroundImage: `url("${api}image/getImage/${i.partnerId}/Partner")`,
                  }}
                ></span>
                <span id="desc">{i.percentage}</span>
                {i.isAvailable ? (
                  <Link
                    to={`beneficios/${slugify(i.productName)}/${i.rate}/${
                      i.id
                    }`}
                  >
                    Resgatar »
                  </Link>
                ) : (
                  <span type="button" id="btn-disabled">
                    Resgatar »
                  </span>
                )}
                {/* <a href={i.discountLink} target="_blank" rel="noopener noreferrer">Resgatar »</a> */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MobileProducts;
