import React, { useState, useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { IoIosSearch, IoIosCloseCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";
import moment from "moment";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Connect from "../../store/config/connect";

import { getUserCoupons } from "../../services/services";

import Loader from "../Loader/Loader";

import "./MinhasCompras.css";

const MinhasCompras = (props) => {
  document.title = "Clube Bravecto | Minhas Compras";

  const [purchases, setPurchases] = useState([]);
  const [purchasesLoaded, setPurchasesLoaded] = useState(false);
  const [purchaseBoxStatus, setPurchaseBoxStatus] = useState(null);
  const [firstEntry, setFirstEntry] = useState(true);
  useEffect(() => {
    if (props.user.id && purchases.length === 0 && firstEntry) {
      getUserCoupons(props.user.id, props.user.token)
        .then((res) => {
          if (res && res.status === 200) {
            let statusBox = {};
            const purchasesResponse = JSON.parse(res.request.response);
            purchasesResponse.map((i) => {
              i.purchaseDate = moment(i.purchaseDate).format("DD/MM/YYYY");
              i.insertionDate = moment(i.insertionDate).format("DD/MM/YYYY");
              i.approvalTargetDate = moment(i.approvalTargetDate).format(
                "DD/MM/YYYY"
              );
              return (statusBox[i.couponId] = false);
            });
            setPurchaseBoxStatus(statusBox);
            setPurchases(purchasesResponse);
            setPurchasesLoaded(true);
          }
        })
        .catch((e) => {
          console.log("error", e.response);
        });
    }

    return () => {
      setFirstEntry(false);
    };
  }, [props, purchases, firstEntry]);

  const [cupomOpen, setCupomOpen] = useState(null);
  useEffect(() => {
    if (cupomOpen) {
      let status = purchaseBoxStatus;
      setPurchaseBoxStatus({ ...status, [cupomOpen]: !status[cupomOpen] });
    }
    return () => {
      setCupomOpen(null);
    };
  }, [cupomOpen, purchaseBoxStatus]);

  return (
    <div id="MinhasCompras">
      <div id="skip">
        <a href="#MinhasCompras__container">
          Pular para o conteúdo principal da página
        </a>
      </div>
      <h1 className="sr-only">Minhas Compras - Clube de Vantagens Bravecto</h1>
      <Header />
      <div id="MinhasCompras__container">
        <div id="MinhasCompras--title">
          <span>Minhas Compras</span>
        </div>
        <div id="purchases">
          <div id="purchases__title">
            <div id="purchases__title--date">
              <span>Data</span>
            </div>
            <div id="purchases__title--status">
              <span>Status</span>
            </div>
          </div>
          <div id="purchases__container">
            {!purchasesLoaded ? (
              <div id="MinhasCompras__preLoader">
                <Loader />
              </div>
            ) : (
              purchases.map((i) => {
                return (
                  <div id="purchase" key={i.couponId}>
                    <div id="purchase__header">
                      <div id="purchase__header--date">
                        <span>{i.insertionDate}</span>
                      </div>
                      <div id="purchase__header--group">
                        <div id="purchase__header--status">
                          <div id="status__container">
                            <div id="status__container--img">
                              {i.idNfStatus === 1 && (
                                <IoIosSearch className="analysis" />
                              )}
                              {i.idNfStatus === 2 && (
                                <FaRegCheckCircle className="success" />
                              )}
                              {i.idNfStatus === 3 && (
                                <IoIosCloseCircleOutline className="reproved" />
                              )}
                            </div>
                            <div id="status__container--text">
                              <span>{i.nfStatusDescription}</span>
                            </div>
                          </div>
                        </div>
                        <div id="purchase__header--arrow">
                          {!purchaseBoxStatus[i.couponId] ? (
                            <MdKeyboardArrowDown
                              onClick={() => {
                                setCupomOpen(i.couponId);
                              }}
                            />
                          ) : (
                            <MdKeyboardArrowUp
                              onClick={() => {
                                setCupomOpen(i.couponId);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        !purchaseBoxStatus[i.couponId] ? "purchase--closed" : ""
                      }
                      id="purchaseBody"
                    >
                      <div id="purchaseBody--topContent">
                        <span>Data da compra: {i.purchaseDate}</span>
                        <br />
                        <span>CNPJ da loja: {i.cnpj}</span>
                      </div>
                      <div id="purchaseBody--middleContent">
                        {i.bravectoPurchase.map((j) => {
                          return (
                            <React.Fragment key={j.id}>
                              <span>
                                {j.bravectoProductId} - {j.productQuantity}{" "}
                                unidade(s)
                              </span>
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </div>
                      <div id="purchaseBody--bottomContent">
                        <span>Nota incluída em {i.insertionDate}</span>
                        <br />
                        {i.idNfStatus === 1 ? (
                          <span>
                            Previsão de análise para {i.approvalTargetDate}
                          </span>
                        ) : (
                          <span></span>
                        )}
                        <br />
                        <span>
                          {i.idNfStatus === 3
                            ? `Reprovado por: ${i.disapprovalReasonDescription}`
                            : null}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div id="newInvoice">
          <Link to="/cadastrar-nota">
            <button type="button">Cadastrar nova nota</button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = ({ auth }, props) => {
  return {
    ...auth,
    ...props,
  };
};

export default Connect(mapStateToProps)(MinhasCompras);
