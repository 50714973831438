import React, { useEffect } from "react";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { TiSocialInstagram } from "react-icons/ti";
import { Link } from "react-router-dom";
// import Connect from '../../store/config/connect';
import FooterAccessibilityLink from "./FooterAccessibilityLink";
import FooterAccessibilityButton from "./FooterAccessibilityButton";
import "./Footer.css";

const Footer = (props) => {
  // Fix for the cookie warning
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        OneTrust.initializeCookiePolicyHtml();
      }, 1000);
    }
  }, []);

  const currentYear = new Date().getFullYear()

  return (
    <div id="footer">
      <div id="container">
        <div id="quemSomos">
          <div id="container__links">
            <ul>
              <li>
                <span id="text">
                  <span id="text__context">
                    {/*<Link to="/faq">FAQ</Link> | <Link to="/quem-somos">Quem Somos</Link> |*/}
                    <Link to="/regras/regulamento">Regulamento</Link>
                  </span>
                </span>
              </li>
              {/*
                <li>
                    <span id='text'>
                        <span id="text__context"> 
                            <Link to="/faq">FAQ</Link> | <Link to="/quem-somos">Quem Somos</Link> | 
                            <Link to="/regras/termos">
                                Termos de Uso
                            </Link>
                        </span>
                    </span>
                </li>
                */}
            </ul>
          </div>
          <div id="icons">
            <a href="https://www.facebook.com/msdfamiliapet" target="_blank">
              <div className="facebook">
                <FaFacebookF />
              </div>
            </a>
            <a href="https://www.instagram.com/msdfamiliapet/" target="_blank">
              <div className="instagram">
                <TiSocialInstagram />
              </div>
            </a>
            <a
              href="https://www.youtube.com/channel/UCKhNAL7sfgGCeJGSNUrEnIw"
              target="_blank"
            >
              <div className="youtube">
                <FaYoutube />
              </div>
            </a>
          </div>
        </div>
        <div id="info-msd">
          <div id="msd-wave">
            <span></span>
          </div>
          <div id="msd-logo">
            <a
              href="https://www.msd-saude-animal.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span></span>
            </a>
          </div>
          <div id="msd-content">
            <div id="about">
              <div id="about__container">
                <h4>
                  <a
                    target="_blank"
                    href="https://www.msd-saude-animal.com.br/"
                    rel="noopener noreferrer"
                    title="Sobre a Empresa"
                  >
                    Empresa
                  </a>
                </h4>
                <span id="hideMobile">|</span>
                <h4 id="splitted-text">
                  <a
                    target="_blank"
                    href="https://www.msd-saude-animal.com.br/aviso-legal"
                    rel="noopener noreferrer"
                    title="Exoneração de Responsabilidade"
                  >
                    Exoneração de
                    <br /> responsabilidade
                  </a>
                </h4>
                <span id="hideMobile">|</span>
                <h4 id="splitted-text">
                  <Link
                    to="/regras/politica-de-privacidade"
                    title="Política de Privacidade"
                  >
                    Política de
                    <br /> Privacidade
                  </Link>
                </h4>
                <span id="hideMobile">|</span>
                <h4>
                  <Link to="/regras/termos">Termos de Uso</Link>
                </h4>
                <span id="hideMobile">|</span>
                <h4>
                  <a
                    target="_top"
                    href="#"
                    className="ot-sdk-show-settings"
                    rel="noopener noreferrer"
                  >
                    Preferências
                    <br /> de cookies
                  </a>
                </h4>
                <span id="hideMobile">|</span>
                <h4>
                  <Link to="/regras/contato" title="Contato">
                    Contato
                  </Link>
                </h4>
                <span id="hideMobile">|</span>
                <h4>
                  <FooterAccessibilityLink />
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="copy footer-copy">
          {`Copyright ${currentYear} © Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.`}
          <FooterAccessibilityButton />
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = ({ auth }, props) => {
//     return {
//       ...auth,
//       ...props
//     };
// };

export default Footer;
