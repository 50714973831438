import React from "react";

const FooterAccessibilityButton = () => {
	return (
		<a href="https://www.levelaccess.com:443/a/msd-pt">
			<img src="https://assets.msd-animal-health.com/brands/accessibility/img/accessibility.png" alt="Botão de Acessibilidade" />
		</a>
	);
};

export default FooterAccessibilityButton;
