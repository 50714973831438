import React, { useEffect, useState } from "react";

import { postStoresByType } from "../../../services/services";

import Loader from "../../Loader/Loader";

import { urlImages } from "../../../utils/helpers/general";

import "./ComprarOnline.css";

const ComprarOnline = (props) => {
  const [onlineStores, setOnlineStores] = useState([]);

  useEffect(() => {
    postStoresByType(1).then((res) => {
      if (res && res.status === 200) {
        try {
          setOnlineStores(JSON.parse(res.request.response));
        } catch (e) {
          setOnlineStores([]);
        }
      }
    });
  }, []);

  function getStoreImageUrl(store) {
    return store.imageLogoPath
      ? urlImages() + store.imageLogoPath
      : require("../../../images/point.png");
  }

  return (
    <div id="ComprarOnline">
      {onlineStores.length ? (
        <div id="OnlineStores">
          <span id="OnlineStoresTitle">Escolher loja parceira:</span>
          {onlineStores.map((store, i) => {
            return (
              <div id="OnlineStore" key={i}>
                <figure id="OnlineStoreFigure">
                  <img
                    id="OnlineStoreImage"
                    src={getStoreImageUrl(store)}
                    alt={store.storeName}
                    title={store.storeName}
                  />
                </figure>
                <a
                  id="OnlineStoreUrl"
                  href={store.linkStore}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Lojas Online"
                >
                  Comprar
                </a>
              </div>
            );
          })}
          <a
            id="ComprarOnlineBack"
            onClick={() => {
              props.handleHideOptions(false);
            }}
          >
            Voltar
          </a>
        </div>
      ) : (
        <div id="Loader">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default ComprarOnline;
