import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { CSSTransition } from "react-transition-group";
import { MdClose } from "react-icons/md";

import Connect from "../../../store/config/connect";

import "./ModalPhoto.css";

const ModalPhoto = (props) => {
  const [zoom, setZoom] = useState(1);

  const [editor, setEditor] = useState(null);
  function setEditorRef(editor) {
    if (editor) {
      setEditor(editor);
    }
  }

  function handleEditedPhoto(data) {
    const img = editor.getImageScaledToCanvas().toDataURL();
    props.setEditedImage(img);
    props.setModalClose(false);
  }

  function handleScale(e) {
    const scale = parseFloat(e.target.value);
    setZoom(scale);
  }

  return (
    <div
      id="modal--photo"
      style={{ display: props.modalClose ? "flex" : "none" }}
    >
      <CSSTransition
        in={props.modalClose}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div id="modal--photo__container">
          <div id="modal--photo__container--close">
            <span>
              <MdClose
                onClick={() => {
                  props.setModalClose(false);
                }}
              />
            </span>
          </div>
          <div id="modal--photo__container--desc">
            <span>
              Cadastre os seus animais para melhor <br /> administração da
              aplicação de seus produtos.
            </span>
          </div>
          <div id="modal--photo__container--moveDirection">
            <span></span>
            <span>Arraste para reposicionar</span>
          </div>
          <div id="modal--photo__container--avatarEditor">
            <AvatarEditor
              ref={setEditorRef}
              image={props.image}
              width={150}
              height={150}
              color={[255, 255, 255, 0.5]} // RGBA
              scale={zoom}
              rotate={0}
              borderRadius={10}
            />
          </div>
          <div id="modal--photo__container--zoom">
            <span>Ampliar e reduzir imagem</span>
            <div id="input--zoom">
              <span></span>
              <input
                name="scale"
                type="range"
                onChange={handleScale}
                min="1"
                max="3"
                step="0.01"
                defaultValue="1"
              />
              <span></span>
            </div>
          </div>
          <div id="modal--photo__container--save">
            <button
              type="button"
              onClick={() => {
                handleEditedPhoto();
              }}
            >
              Salvar
            </button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Connect()(ModalPhoto);
